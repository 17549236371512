<template>
  <div
    class="loader"
    :class="{'loader_mini': mini}"
  >
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
</template>

<script>
export default {
  props: {
    mini: Boolean
  }
}
</script>

<style lang="stylus" scoped>
  .loader
    position relative
    width 80px
    height 80px
    display inline-block
    will-change opacity

    div
      transform-origin 40px 40px
      animation spinner 1.4s linear infinite

      &:after
        content " "
        position absolute
        top 3px
        left 37px
        display block
        width 6px
        height 18px
        border-radius 50%
        background #5b40ff

      &:nth-child(1)
        transform rotate(0deg)
        animation-delay -1.4s

      &:nth-child(2)
        transform rotate(24deg)
        animation-delay -1.3s

      &:nth-child(3)
        transform rotate(48deg)
        animation-delay -1.2s

      &:nth-child(4)
        transform rotate(72deg)
        animation-delay -1.1s

      &:nth-child(5)
        transform rotate(96deg)
        animation-delay -1s

      &:nth-child(6)
        transform rotate(120deg)
        animation-delay -0.9s

      &:nth-child(7)
        transform rotate(144deg)
        animation-delay -0.8s

      &:nth-child(8)
        transform rotate(168deg)
        animation-delay -0.7s

      &:nth-child(9)
        transform rotate(192deg)
        animation-delay -0.6s

      &:nth-child(10)
        transform rotate(216deg)
        animation-delay -0.5s

      &:nth-child(11)
        transform rotate(240deg)
        animation-delay -0.4s

      &:nth-child(12)
        transform rotate(264deg)
        animation-delay -0.3s

      &:nth-child(13)
        transform rotate(288deg)
        animation-delay -0.2s

      &:nth-child(14)
        transform rotate(312deg)
        animation-delay -0.1s

      &:nth-child(15)
        transform rotate(336deg)
        animation-delay 0s

    &_mini
      width 24px
      height 24px

      div
        transform-origin 13px 13px

        &:after
          top 5px
          left 13px
          width 1px
          height 4px
          background #9c9ca6

@keyframes spinner
  0%
    opacity 1

  100%
    opacity 0
</style>
