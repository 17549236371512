import { defaultHttpClient } from '@/api/default/defaultHttpClient'
import ApiRoutes from '@/api/default/routes'

const {
  GetAll
} = ApiRoutes.Exports

export const getExports = (params) => defaultHttpClient.post(GetAll, { ...params })

export const getExportFile = (url) => defaultHttpClient.get(url, { responseType: 'blob' })
