<template>
  <validation-observer
    ref="vObserver"
    v-slot="{ handleSubmit, invalid }"
  >
    <the-form
      class="form_content_connect-marketplace"
      @submit="handleSubmit(onSubmit)"
    >
      <fieldset class="form-fields-group">
        <legend class="form-fields-group__title">
          <span class="hidden-on-mobile">Добавление магазина на</span> Яндекс.Маркет
        </legend>

        <ul class="form-fields-list">
          <li class="form-fields-list__item form-fields-list__item_content_desc">
            Чтобы добавить ваш магазин на Яндекс.Маркет, заполните поля формы и нажмите на кнопку “Подключить магазин”
            <x-divider class="mt-2 mb-3" />
            <div class="d-flex">
              <x-link
                primary
                href="https://rutube.ru/video/aeef948c11265233f8c289466a3321a7/"
                @click="onVideoInstruction"
              >
                Видеоинструкция
              </x-link>
              <x-link
                primary
                href="https://brassy-countess-52e.notion.site/3b685620d9af4abab1412720a30f7a2d"
                class="ml-auto"
                @click="onTextInstruction"
              >
                Текстовая инструкция
              </x-link>
            </div>
            <x-divider class="mt-3" />
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="validationData"
              rules="required"
              name="Наименование магазина"
              label="Введите наименование магазина"
            >
              <x-input
                v-model="form.params.name"
                v-bind="validationData"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="{ classes, ...validationData }"
              rules="required|beruClientId"
              name="Номер кампании в Яндекс.Маркет"
              vid="beru_client_id"
              label="Введите номер кампании в Яндекс.Маркет"
            >
              <x-input
                v-model.trim="form.params.beru_client_id"
                v-bind="validationData"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="{ classes, ...validationData }"
              rules="required|beruAppId"
              name="ID приложения в Яндекс.Маркет"
              vid="beru_app_id"
              label="Введите ID приложения в Яндекс.Маркет"
            >
              <x-input
                v-model.trim="form.params.beru_app_id"
                v-bind="validationData"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="{ classes, ...validationData }"
              rules="required|beruToken"
              name="Токен приложения в Яндекс.Маркет"
              vid="beru_token"
              label="Введите токен приложения в Яндекс.Маркет"
            >
              <x-input
                v-model.trim="form.params.beru_token"
                v-bind="validationData"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="{ classes, ...validationData }"
              label="Введите Авторизационный токен на Яндекс.Маркет"
              name="Авторизационный токен на Яндекс.Маркет"
              vid="yandex_key"
              rules="required|yandexKey"
            >
              <x-input
                id="yandex-key"
                v-model.trim="form.params.yandex_key"
                v-bind="validationData"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <client-select
              rules="required"
              :value="selectedClient"
              :options="clientList"
              :clearable="false"
              @input="onClientSelect"
            />
          </li>

          <li class="form-fields-list__item form-fields-list__item_content_submit">
            <x-btn
              type="submit"
              block
              :loading="isLoading"
              :disabled="invalid"
            >
              Подключить магазин
            </x-btn>
          </li>
        </ul>
      </fieldset>
    </the-form>
  </validation-observer>
</template>

<script>
import marketplaceCode from '@/constants/marketplace-codes'
import TheForm from '@/components/Interface/Form.vue'
import ClientSelect from '@/components/Settings/Permissions/ClientSelect.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('settings/permissions')

export default {
  components: {
    TheForm,
    ClientSelect
  },

  props: {
    source: String,
    isLoading: Boolean,
    invalidMessage: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data () {
    return {
      form: {
        params: {
          name: '',
          client_id: null,
          beru_token: '',
          beru_client_id: '',
          beru_app_id: '',
          yandex_key: ''
        },
        marketplace: marketplaceCode.BERU
      }
    }
  },

  watch: {
    'invalidMessage': {
      handler (err) {
        if (!err) return
        // eslint-disable-next-line
        err.keys.forEach( fieldKey => {
          this.$refs.vObserver.setErrors({
            [fieldKey]: err.message || 'Указан неверный токен'
          })
        })
      }
    }
  },
  computed: {
    ...mapState(['clientList']),

    selectedClient () {
      return this.clientList.find(c => c.id === this.form.params.client_id) || null
    },

    addShopMetrics () {
      return this.source === 'promo_onboarding'
        ? {
          video: 'mmp_onboarding_intro_addshop_video',
          text: 'mmp_onboarding_intro_addshop_text'
        }
        : (this.$route.meta?.metricsEvents?.addShop || {})
    }
  },

  methods: {
    onClientSelect (client) {
      this.form.params.client_id = +client.id
    },

    onSubmit () {
      this.$emit('submit', this.form)
    },
    onVideoInstruction () {
      this.$metrics.hit(this.addShopMetrics.video, { marketplace_code: this.form.marketplace })
    },
    onTextInstruction () {
      this.$metrics.hit(this.addShopMetrics.text, { marketplace_code: this.form.marketplace })
    }
  }
}
</script>
