<template>
  <tile>
    <template #heading>
      <div class="d-flex justify-between">
        <div class="d-flex align-center">
          <span class="header-areas semibold">
            {{ GraficsName[heading] }}
          </span>
          <tooltip>
            <svg-icon
              class="info__icon ml-1 mt-1"
              name="info-fill"
            />
            <template #popover>
              <span v-if="heading == 'orders_money'">Сумма заказов в рублях: Wildberries с учетом комиссии маркетплейса, Ozon - без учета комиссии</span>
              <span v-else>Данные со страницы "Еженедельная динамика и анализ продаж"</span>
            </template>
          </tooltip>
          <span class="last-date">
            {{ lastDay }}
          </span>
        </div>
      </div>
    </template>
    <div
      v-if="loadind"
      class="dashboard__loader"
    >
      <loader />
    </div>
    <chart
      v-else
      :options="chartOptions"
    />
  </tile>
</template>

<script>
import { Chart } from 'highcharts-vue'
import Tile from '@/components/Common/Tile.vue'
import Loader from '@/components/Common/Loader.vue'
import Tooltip from '@/components/Common/Tooltip.vue'
import Measures from '@/constants/measure-name'
import GraficsName from '@/constants/dashboards_grafics_name'
import formatter from '@/utils/formatterTooltip'
import formatterLegend from '@/utils/formatterLegend'
import formatterLabel from '@/utils/formatterLabel'
import creater from '@/utils/creater-data'
import '@/styles/grafics.styl'

export default {

  components: {
    Chart,
    Tile,
    Loader,
    Tooltip
  },

  props: {
    heading: {
      type: String,
      default: ''
    },
    measure: {
      type: String,
      default: ''
    },
    points: {
      type: Object,
      required: true
    },
    showDay: {
      type: Boolean,
      default: false
    },
    showWeek: {
      type: Boolean,
      default: false
    },
    loadind: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      Measures,
      GraficsName,
      lastDay: '',
      chartOptions: {
        credits: {
          enabled: false
        },
        chart: {
          type: 'area',
          style: {
            fontFamily: 'proxima_nova',
            fontWeight: 'normal'
          },
          backgroundColor: 'transparent'
        },
        title: {
          text: null
        },
        xAxis: {
          categories: [],
          lineColor: '#E5E5E9',
          title: {
            useHTML: true
          },
          labels: {
            formatter () {
              return formatterLabel.simpleLabel(this.isFirst, this.isLast, this.value)
            },
            reserveSpace: true,
            style: {
              color: '#7b7b85',
              whiteSpace: 'nowrap',
              textOverflow: 'none'
            },
            rotation: 0
          }
        },
        yAxis: {
          title: {
            text: null
          },
          min: 0,
          labels: {
            format: this.measure === Measures.PERCENT ? '{value}%' : '',
            style: {
              color: '#7b7b85',
              fontSize: 10
            }
          }
        },
        plotOptions: {
          area: {
            stacking: 'normal',
            lineColor: '#ffffff',
            lineWidth: 1,
            marker: {
              symbol: 'circle',
              lineWidth: 1,
              lineColor: '#ffffff'
            }
          },
          series: {
            marker: {
              enabledThreshold: 100
            }
          }
        },
        tooltip: {
          enabled: true,
          crosshairs: true,
          shared: true,
          shadow: false,
          borderWidth: 0,
          backgroundColor: 'transparent',
          useHTML: true,
          headerFormat: formatter.formatterHeader(this.showDay, this.showWeek),
          pointFormatter: formatter.formatterMultipleTooltip,
          footerFormat: formatter.formatterFooter(this.measure, this.points)
        },
        colors: ['#1EABF1', '#F2453D', '#8DC252', '#F7A738', '#A926FB'],
        legend: {
          enabled: true,
          layout: 'vertical',
          align: 'left',
          verticalAlign: 'top',
          width: '312px',
          maxHeight: '',
          useHTML: true,
          margin: 28,
          symbolPadding: 0,
          symbolWidth: 0,
          symbolHeight: 0,
          squareSymbol: false,
          labelFormatter: formatterLegend.formatLegend
        },
        series: []
      }
    }
  },

  watch: {
    showDay (value) {
      const showBy = {
        show_day: value,
        show_week: this.showWeek
      }
      this.chartOptions.series = this.points.values.map(point => creater.createStoresStatisticSeries(showBy, point, this.measure, this.heading))
      this.chartOptions.tooltip.headerFormat = formatter.formatterHeader(this.showDay, this.showWeek)
      this.chartOptions.tooltip.footerFormat = formatter.formatterFooter(this.measure, this.points)
    },
    showWeek (value) {
      const showBy = {
        show_day: this.showDay,
        show_week: value
      }
      this.chartOptions.series = this.points.values.map(point => creater.createStoresStatisticSeries(showBy, point, this.measure, this.heading))
      this.chartOptions.tooltip.headerFormat = formatter.formatterHeader(this.showDay, this.showWeek)
      this.chartOptions.tooltip.footerFormat = formatter.formatterFooter(this.measure, this.points)
    },
    points: {
      handler (value) {
        this.zeroData = false
        this.updateGrafic()
        const arr = value.values ? value.values : []
        const result = new Set()
        arr.forEach(_ => {
          const lastNotNull = _.data.findLastIndex(data => data !== null)
          if (lastNotNull !== -1) {
            result.add(value.dates[lastNotNull])
          }
        })
        if (!result.size) {
          this.lastDay = ''
        } else if (result.size > 1 && result.has(value.dates[value.dates.length - 1])) {
          this.lastDay = `за ${value.dates[value.dates.length - 1]}`
        } else {
          this.lastDay = `за ${Array.from(result)[0]}`
        }
        this.chartOptions.xAxis.categories = value.dates ? value.dates : []
        this.chartOptions.series = value.values ? value.values.map(point => {
          point.data.forEach(_ => {
            if (_ !== 0 && _ !== null) {
              this.zeroData = true
            }
          })
          return creater.createStoresStatisticSeries({
            show_day: this.showDay,
            show_week: this.showWeek
          }, point, this.measure, this.heading)
        }) : []
        this.chartOptions.yAxis.max = this.zeroData ? undefined : 2
        this.chartOptions.yAxis.min = this.zeroData ? undefined : 0
      },
      deep: true
    }
  },

  mounted () {
    this.updateGrafic()
  },

  created () {
    window.addEventListener('resize', this.updateGrafic)
  },

  methods: {
    updateGrafic () {
      const widthWindow = window.innerWidth
      if (widthWindow <= 1200) {
        this.chartOptions.legend.align = 'center'
        this.chartOptions.legend.verticalAlign = 'top'
        this.chartOptions.legend.width = '312px'
        this.chartOptions.chart.height = this.points.values && this.points.values.length > 1 ? this.points.values.length * 150 : 150
      } else {
        this.chartOptions.legend.align = 'left'
        this.chartOptions.legend.verticalAlign = 'top'
        this.chartOptions.legend.width = '312px'
        this.chartOptions.chart.height = this.points.values && this.points.values.length > 1 ? this.points.values.length * 110 : 150
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .header-areas
    margin-right 4px

  .info__icon
    opacity 0.5

  .last-date
    display inline-block
    background-color #FFF8C7
    border-radius 14px
    padding 0 6px
    font-size 12px
    font-weight normal
    margin-left 4px
    white-space nowrap

  .semibold
    font-weight 600
</style>
