export const defaultFilterOption = {
  id: 'ALL',
  label: 'Все'
}

export const defaultSelfPurchase = {
  id: 'ALL',
  label: 'Все заказы'
}

export const showByOptions = [{
  id: 'day',
  label: 'День'
}, {
  id: 'month',
  label: 'Месяц'
}]

export const marketplaceOptions = [{
  id: 'ALL',
  label: 'Все'
}, {
  id: 'ozon',
  label: 'Ozon',
  code: 'ozon'
}, {
  id: 'ali',
  label: 'AliExpress',
  code: 'ali'
}, {
  id: 'beru',
  label: 'Яндекс.Маркет',
  code: 'beru'
}, {
  id: 'wildberries',
  label: 'Wildberries',
  code: 'wildberries'
}, {
  id: 'goods',
  label: 'СберМегаМаркет',
  code: 'goods'
}]
