import * as Types from './mutation-types'
import * as DashboardApi from '@/api/default/modules/dashboards'

export default {
  async getDashboards ({ commit }, payload) {
    const { data: { isSuccess, data } } = await DashboardApi.getAll(payload)
    commit(Types.SET_DASHBOARDS_DATA, data)
    commit(Types.SET_DASHBOARDS_LOADED, isSuccess)
  },

  async getShopsActivity ({ commit }, payload) {
    const { data } = await DashboardApi.getShopsActivity(payload)
    return {
      isSuccess: data.is_success,
      data: data.data,
      error: data.error
    }
  },

  async getOrdersCount ({ commit }, payload) {
    const { data } = await DashboardApi.getOrdersCount(payload)
    return {
      isSuccess: data.is_success,
      data: data.data,
      error: data.error
    }
  },

  async getDrrHistory ({ commit }, payload) {
    const { data } = await DashboardApi.getDrrHistory(payload)
    return {
      isSuccess: data.is_success,
      data: data.data,
      error: data.error
    }
  },

  async getExpenseHistory ({ commit }, payload) {
    const { data } = await DashboardApi.getExpenseHistory(payload)
    return {
      isSuccess: data.is_success,
      data: data.data,
      error: data.error
    }
  },

  async getExpiredFeedbacksCount ({ commit }, payload) {
    const { data } = await DashboardApi.getExpiredFeedbacksCount(payload)
    return {
      isSuccess: data.is_success,
      data: data.data,
      error: data.error
    }
  },

  async getProductHistory ({ commit }, payload) {
    const { data } = await DashboardApi.getProductHistory(payload)
    return {
      isSuccess: data.is_success,
      data: data.data,
      error: data.error
    }
  },

  async getOrderFull ({ commit }, payload) {
    const { data } = await DashboardApi.getOrderFull(payload)
    return {
      isSuccess: data.is_success,
      data: data.data,
      error: data.error
    }
  },

  async getMetricsList ({ commit }, payload) {
    const { data } = await DashboardApi.getMetricsList(payload)
    return {
      isSuccess: data.is_success,
      data: data.data,
      error: data.error
    }
  },

  async getShopActivity ({ commit }, payload) {
    const { data } = await DashboardApi.getShopActivity(payload)
    return {
      isSuccess: data.is_success,
      data: data.data,
      error: data.error
    }
  },

  async getShopOrderFull ({ commit }, payload) {
    const { data } = await DashboardApi.getShopOrderFull(payload)
    return {
      isSuccess: data.is_success,
      data: data.data,
      error: data.error
    }
  },

  async getMetricsStatistic ({ commit }, payload) {
    const { data } = await DashboardApi.getMetricsStatistic(payload)
    return {
      isSuccess: data.is_success,
      data: data.data,
      error: data.error
    }
  },

  async getTop5Product ({ commit }, payload) {
    const { data } = await DashboardApi.getTop5Product(payload)
    return {
      isSuccess: data.is_success,
      data: data.data,
      error: data.error
    }
  }
}
