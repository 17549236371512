<template>
  <modal
    :name="modalName"
    :max-width="540"
    :min-width="280"
    width="90%"
    height="auto"
    :adaptive="true"
    :click-to-close="false"
  >
    <div class="modal-container">
      <x-loader
        v-if="isLoading"
        class="modal-container__loader"
      />

      <validation-observer
        v-else
        v-slot="{ handleSubmit }"
      >
        <default-form
          @submit="handleSubmit(onSubmit)"
        >
          <fieldset class="form-fields-group">
            <legend class="form-fields-group__title">
              <slot name="heading">
                Для продолжения пользования сервисом, пожалуйста укажите Ваши данные:
              </slot>
            </legend>

            <ul class="form-fields-list">
              <li class="form-fields-list__item">
                <x-form-item
                  v-slot="validationData"
                  rules="required"
                  name="Наименование компании"
                  label="Наименование компании"
                >
                  <x-input
                    v-model="form.client_name"
                    v-bind="validationData"
                    placeholder="Наименование компании"
                  />
                </x-form-item>
              </li>

              <li class="form-fields-list__item">
                <x-form-item
                  v-slot="validationData"
                  rules="required"
                  name="Имя сотрудника для связи"
                  label="Имя сотрудника для связи"
                >
                  <x-input
                    v-model="form.user_name"
                    v-bind="validationData"
                    placeholder="Имя сотрудника для связи"
                  />
                </x-form-item>
              </li>

              <li class="form-fields-list__item">
                <x-form-item
                  v-slot="validationData"
                  rules="required"
                  name="Контактный телефон"
                  label="Контактный телефон"
                >
                  <x-input
                    v-model="form.user_phone"
                    v-mask="'+7 (###) ###-##-##'"
                    v-bind="validationData"
                    placeholder="Контактный телефон"
                  />
                </x-form-item>

                <x-btn
                  type="button"
                  :color="isPhoneFilled ? 'primary' : 'gray'"
                  :disabled="!form.user_phone || resendCodeTimer < baseTimerDuration"
                  class="modal-container__action_send"
                  @click="onSendCode"
                >
                  Отправить код
                </x-btn>
                <p class="modal-container__text">
                  {{ resendText }}
                </p>
              </li>

              <li
                v-if="phoneVerifyProcessed || isCodeSending"
                class="form-fields-list__item"
              >
                <x-loader class="modal-container__loader" />
              </li>

              <li
                v-else-if="codeSent"
                class="form-fields-list__item"
              >
                <x-form-item
                  v-slot="{ errors, ...validationData }"
                  rules="required"
                  name="Код подтверждения"
                  label="Код подтверждения"
                >
                  <x-input
                    v-model="form.security_code"
                    v-mask="'######'"
                    v-bind="validationData"
                    :disabled="verifyTimer < baseTimerDuration"
                    placeholder="Код подтверждения"
                  />
                  <span
                    v-if="wrongCodeMsg && !errors.length"
                    class="x-form-item__errors"
                  >
                    {{ errorMessage }}
                  </span>
                </x-form-item>
              </li>
              <p class="modal-container__text">
                {{ verifyText }}
              </p>
            </ul>
          </fieldset>
        </default-form>
      </validation-observer>

      <x-btn
        ghost
        prepend="log-out"
        class="modal-container__logout-btn"
        @click="onLogoutClick"
      >
        Выйти из кабинета
      </x-btn>
    </div>
  </modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import DefaultForm from '@/components/Interface/Form.vue'
import responseCodes from '@/constants/response-codes'

const { mapActions } = createNamespacedHelpers('profile')

const baseTimerDuration = 60
const securityCodeLength = 6
const phoneLength = 18

export default {
  components: {
    DefaultForm
  },

  data () {
    return {
      modalName: 'manager-info-modal',
      form: {
        client_name: '',
        user_name: '',
        user_phone: '+7',
        security_code: null
      },
      wrongCodeMsg: '',
      resendCodeTimerId: null,
      verifyTimerId: null,
      baseTimerDuration,
      resendCodeTimer: baseTimerDuration,
      verifyTimer: baseTimerDuration,
      isLoading: false,
      codeSent: false,
      isCodeSending: false,
      phoneVerifyProcessed: false
    }
  },

  watch: {
    'form.security_code' (code) {
      if (code.length !== securityCodeLength) return
      this.onVerifyCode(code)
    }
  },

  computed: {
    resendText () {
      return this.resendCodeTimer < baseTimerDuration ? `Получить код повторно можно через: ${this.resendCodeTimer} сек.` : ''
    },

    verifyText () {
      return this.verifyTimer < baseTimerDuration ? `Отправить код повторно можно через: ${this.verifyTimer} сек.` : ''
    },

    computedNumber () {
      return this.form.user_phone.replace(/[()-]/g, '')
    },

    isPhoneFilled () {
      return this.form.user_phone.length === phoneLength
    }
  },

  methods: {
    ...mapActions([
      'createClient',
      'sendPhoneVerificationCode',
      'verifyPhone'
    ]),

    async onSendCode () {
      try {
        this.isCodeSending = true
        await this.sendPhoneVerificationCode(this.computedNumber)
        this.codeSent = true
      } catch (e) {
        const { status } = e.response
        if (status === responseCodes.throttled) {
          this.startResendTimer('resendCodeTimerId', 'resendCodeTimer')
        }
      } finally {
        this.isCodeSending = false
      }
    },

    async onVerifyCode (code) {
      try {
        this.phoneVerifyProcessed = true
        const { is_valid: isValid } = await this.verifyPhone({
          phone_number: this.computedNumber,
          security_code: code
        })
        if (!isValid) {
          this.wrongCodeMsg = 'Неверный код подтверждения'
          this.form.security_code = null
          return
        }
        await this.onSubmit()
      } catch (e) {
        const { status } = e.response
        if (status === responseCodes.throttled) {
          this.startResendTimer('verifyTimerId', 'verifyTimer')
        }
      } finally {
        this.phoneVerifyProcessed = false
      }
    },

    async onSubmit () {
      try {
        this.isLoading = true

        await this.createClient(this.form)

        await this.$user.getProfile()

        this.$modal.hide(this.modalName)
      } finally {
        this.isLoading = false
      }
    },

    startResendTimer (timerId, timer) {
      this[timerId] = setInterval(() => {
        this[timer]--
        if (this[timer] <= 0) {
          clearInterval(this[timerId])
          this[timer] = baseTimerDuration
        }
      }, 1000)
    },

    onLogoutClick () {
      this.$user.logout()
      this.$modal.hide(this.modalName)
      this.$router.push({ name: 'sign.in' })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-container
    max-width 380px
    margin 0 auto
    padding 22px 0 45px

    &__actions
      display flex
      justify-content space-between

      >>>.button
        flex-basis 48%

    &__loader
      display flex
      margin 0 auto

    &__action
      &_send
        margin 5px 0

    &__text
      font-size 14px

    &__logout-btn
      display flex
      margin-left auto
</style>
