import * as ReportApi from '@/api/default/modules/report'

export const reports = {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    async getReportList (commit) {
      const { data } = await ReportApi.getReportList()
      return data ? data.data : []
    },

    async createReport (commit, params) {
      const { data } = await ReportApi.postReport(params)
      return data ?? null
    },

    async restartReport (commit, params) {
      await ReportApi.restartReport(params)
    },

    async stopReport (commit, params) {
      await ReportApi.stopReport(params)
    }
  }
}
