import * as Types from './mutation-types'

export default {
  [Types.SET_USER_BALANCE] (state, payload) {
    state.balanceInfo = payload
  },
  [Types.SET_TRANSACTIONS] (state, payload) {
    state.transactions = payload
  },
  [Types.SET_TRANSACTION_TYPES] (state, payload) {
    state.transactionTypes = payload
  },
  [Types.SET_TRANSACTION_STATUSES] (state, payload) {
    state.transactionStatuses = payload
  },
  [Types.SET_PAYMENT_ACCOUNTS] (state, payload) {
    state.paymentAccounts = payload
  },
  [Types.SET_TARIFFS_LIST] (state, payload) {
    state.tariffsList = payload
  },
  [Types.SET_TARIFFS_PRICE_LIST] (state, payload) {
    state.tariffPriceList = payload
  }
}
