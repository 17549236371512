import * as Types from './mutation-types'
import * as ExportsApi from '@/api/default/modules/exports'
import downloadFile from '@/utils/download-file'
import { XLSX } from '@/constants/fileTypes'
import toastNotification from '@/utils/toast-notification'

export default {
  async getExports ({ commit }, payload) {
    const { data } = await ExportsApi.getExports(payload)
    commit(Types.SET_EXPORTS_LIST, data.data)

    return data
  },

  async getExportFile ({ commit }, { name, url }) {
    try {
      const res = await ExportsApi.getExportFile(url)

      downloadFile({
        url: res.data,
        fileName: name,
        type: XLSX
      })
    } catch (e) {
      toastNotification.error('Не удалось скачать файл')
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }
}
