import { sendEvents } from '@/api/default/modules/tracking'
import store from '@/store'

export const tracking = {
  namespaced: true,
  state: {
    events: []
  },
  mutations: {
    SET_EVENT: (state, event) => {
      state.events.push(event)
    },
    CLEAR_EVENTS: (state) => {
      state.events = []
    }
  },
  actions: {
    /* deprecated. use @xway-team/metrics */
    setEvent ({ commit, dispatch }, hit) {
      const today = new Date()
      const datetime = today.toISOString()
      const event = {
        event_name: hit.name,
        product: hit.product,
        screen_id: hit.screen,
        time: datetime,
        partner_id: store.state.profile.user.partner_id,
        user_id: store.state.profile.user.id,
        client_id: store.state.profile.user?.client?.id || ''
      }
      if (hit.period) event.period = hit.period
      if (hit.marketplace) event.marketplace = hit.marketplace
      if (hit.shop_id) event.shop_id = hit.shop_id
      if (hit.frame) event.frame = hit.frame
      if (hit.marketplace_code) event.marketplace_code = hit.marketplace_code
      if (hit.max_price_increase) event.max_price_increase = hit.max_price_increase
      if (hit.max_price_decrease) event.max_price_decrease = hit.max_price_decrease
      if (hit.status) event.status = hit.status
      if (hit.creation_date) event.creation_date = hit.creation_date
      if (hit.payment_date) event.payment_date = hit.payment_date
      if (hit.shipment_date) event.shipment_date = hit.shipment_date
      if (hit.arguement_date) event.arguement_date = hit.arguement_date
      if (hit.report_id) event.report_id = hit.report_id
      if (hit.order_id) event.order_id = hit.order_id
      if (hit.promo_id) event.promo_id = hit.promo_id
      if (hit.promo_category) event.promo_category = hit.promo_category
      commit('SET_EVENT', event)
      setTimeout(() => dispatch('sendEvents'), 2000)
    },
    async sendEvents ({ state, commit }) {
      if (state.events.length) {
        const events = state.events
        commit('CLEAR_EVENTS')
        try {
          await sendEvents(events)
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err)
        }
      }
    }
  }
}
