<template>
  <modal
    :name="modalName"
    :max-width="540"
    :min-width="280"
    width="90%"
    height="auto"
    :adaptive="true"
    @before-open="onBeforeOpen"
    @closed="onClosed"
  >
    <div class="modal-container">
      <component
        :is="componentName"
        v-if="!isFormSended && !isSomethingWrong"
        :source="source"
        :is-loading="isLoading"
        :invalid-message="invalidMessage"
        @submit="onSubmit"
      />

      <success-connection
        v-if="isFormSended && !isSomethingWrong"
        :text="successText"
        @click="onSuccessClick"
      />

      <something-get-wrong
        v-if="isSomethingWrong"
      >
        <template
          v-if="errorMessage"
          #title
        >
          {{ errorMessage }}
        </template>
      </something-get-wrong>

      <close-button
        @click="$modal.hide(modalName)"
      />
    </div>
  </modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import marketplaceCodes from '@/constants/marketplace-codes'
import marketplaceNames from '@/constants/marketplace-name'
import CloseButton from '@/components/Interface/CloseButton.vue'
import ConnectFormAli from '@/components/Marketplaces/ConnectForms/ConnectFormAliexpress.vue'
import ConnectFormOzon from '@/components/Marketplaces/ConnectForms/ConnectFormOzon.vue'
import ConnectFormWildberries from '@/components/Marketplaces/ConnectForms/ConnectFormWildberries.vue'
import ConnectFormBeru from '@/components/Marketplaces/ConnectForms/ConnectFormBeru.vue'
import ConnectFormGoods from '@/components/Marketplaces/ConnectForms/ConnectFormGoods.vue'
import SuccessConnection from '@/components/Marketplaces/ConnectForms/SuccessConnection.vue'
import SomethingGetWrong from '@/components/Common/SomethingGetWrong.vue'
import ROUTES from '@/router/ROUTES'

const { mapActions } = createNamespacedHelpers('marketplaces')

export default {
  components: {
    CloseButton,
    ConnectFormAli,
    ConnectFormOzon,
    ConnectFormWildberries,
    ConnectFormBeru,
    ConnectFormGoods,
    SuccessConnection,
    SomethingGetWrong
  },

  data () {
    return {
      modalName: 'connect-modal',
      source: '',
      componentName: null,
      isFormSended: false,
      isSomethingWrong: false,
      isLoading: false,
      successText: '',
      currentMarketplace: null,
      errorMessage: '',
      invalidMessage: {}
    }
  },

  computed: {
    addShopEventName () {
      return this.source === 'promo_onboarding' ? 'mmp_onboarding_intro_addshop_access' : this.$route.meta?.metricsEvents?.addShop.access
    }
  },

  methods: {
    ...mapActions(['connectMarketplaceV2']),

    onBeforeOpen (e) {
      this.source = e.params.source
      const { formName, success } = e.params
      if (formName) this.componentName = formName
      this.isFormSended = success ?? false

      if (success) {
        this.currentMarketplace = marketplaceCodes.ALI

        this.successText = `Магазин ${localStorage.getItem('seller_ali_shop_name') || ''} на AliExpress подключен!`
      }
    },

    onClosed () {
      this.invalidMessage = {}
      this.isFormSended = false
      this.isSomethingWrong = false
    },

    async onSubmit (data) {
      if (this.source) data.source = this.source
      try {
        this.isLoading = true

        const response = await this.connectMarketplaceV2(data)
        const { name } = data.params

        this.currentMarketplace = data.marketplace
        this.$metrics.hit(this.addShopEventName, { marketplace_code: this.currentMarketplace })

        if (typeof response === 'string') {
          window.location = response
          localStorage.setItem('seller_ali_shop_name', name)
          return
        }

        this.successText = `Магазин ${name || ''} на ${marketplaceNames[data.marketplace]} подключен!`
        this.isFormSended = true
      } catch (e) {
        if (!e.response?.data?.message?.message && e.response?.data?.message) {
          this.errorMessage = e.response.data.message
          this.isSomethingWrong = true
        } else {
          this.invalidMessage = e.response?.data.message
        }
        this.isFormSended = false
      } finally {
        this.isLoading = false
      }
    },

    async onSuccessClick () {
      this.$modal.hide(this.modalName)

      if (this.currentMarketplace === marketplaceCodes.ALI) return

      if (this.$route.name !== ROUTES.MARKETPLACES.name) {
        this.$router.push({ name: ROUTES.MARKETPLACES.name })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-container
    max-width 390px
    margin 0 auto
    padding 45px 15px 80px
    box-sizing border-box

    @media (min-width 768px)
      padding 30px 0 80px
</style>
