export default ({ url, fileName, type }) => {
  const link = document.createElement('a')
  link.download = fileName

  const blob = new Blob([url], { type })

  link.href = URL.createObjectURL(blob)

  link.click()

  URL.revokeObjectURL(link.blob)
  // link.remove()
}
