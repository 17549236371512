import toastNotification from '@/utils/toast-notification'
import * as Types from './mutation-types'
import * as MarketplacesApi from '@/api/default/modules/marketplaces'
import Vue from 'vue'

export default {
  async getShopList (ctx, params) {
    const { data } = await MarketplacesApi.getShopList(params)
    const user = ctx.rootState.profile.user

    ctx.commit(Types.SET_SHOPS, data.results)
    ctx.commit('profile/SET_USER', { ...user, shop_count: data.count }, { root: true })
  },

  async getShopListV2 (ctx, params) {
    const { data } = await MarketplacesApi.getShopListV2(params)
    const user = ctx.rootState.profile.user

    ctx.commit(Types.SET_SHOPS, data.data)
    ctx.commit('profile/SET_USER', { ...user, shop_count: data.pagination.count }, { root: true })
    return data
  },

  async getShopListCombo (ctx, params) {
    const { data } = await MarketplacesApi.getShopListCombo(params)
    const user = ctx.rootState.profile.user

    ctx.commit(Types.SET_SHOPS, data.results)
    ctx.commit('profile/SET_USER', { ...user, shop_count: data.count }, { root: true })
  },

  async getReportTypes ({ commit }) {
    const { data } = await MarketplacesApi.getReportTypes()
    return data
  },

  clearShopList ({ commit }) {
    commit(Types.SET_SHOPS, [])
  },

  async updateShop ({ commit }, shopData) {
    const { data } = await MarketplacesApi.updateShop(shopData)
    commit(Types.SET_SHOP, data.data)
    return data
  },

  async updateShopV2 ({ commit }, shopData) {
    const { data } = await MarketplacesApi.updateShopV2(shopData)
    commit(Types.SET_SHOP, data)
    return data
  },

  async loginShop ({ commit }, payload) {
    const { data: { data, isSuccess, message } } = await MarketplacesApi.loginShop(payload)
    if (data) {
      commit(Types.UPDATE_SHOP, { id: payload.id, data })
    }

    return { shop: data, isSuccess, message }
  },

  async createUpdateOzonCredentials ({ commit }, payload) {
    try {
      const { data: { data, isSuccess } } = await MarketplacesApi.createUpdateOzonCredentials(payload)

      if (data) {
        commit(Types.UPDATE_SHOP, { id: payload.id, data })
      }

      return isSuccess
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  },

  async request2FACode (context, payload) {
    const { data: { data } } = await MarketplacesApi.request2FACode(payload)
    return data?.requestId
  },

  async send2FACode ({ commit }, payload) {
    const { data: { data, ...status } } = await MarketplacesApi.send2FACode(payload)
    if (status?.isSuccess) {
      commit(Types.UPDATE_SHOP, { id: payload.id, data })
    }
    return status
  },

  async connectMarketplace ({ commit, dispatch }, payload) {
    const { data: { data } } = await MarketplacesApi.connectMarketplace(payload)
    commit(Types.SET_SHOP, data)
    dispatch('getShopList')
    return data
  },

  async connectMarketplaceV2 ({ commit, dispatch }, payload) {
    const { data: { data } } = await MarketplacesApi.connectMarketplaceV2(payload)
    commit(Types.SET_SHOP, data)
    dispatch('getShopListV2')
    return data
  },

  async logoutMarketplace ({ commit }, payload) {
    const { data: { data } } = await MarketplacesApi.logoutMarketplace(payload)
    commit(Types.SET_SHOP, data)
  },

  async requestConsultation ({ commit }, payload) {
    await MarketplacesApi.requestConsultation(payload)
  },

  async getAliTokenUpdateUrl ({ commit }, payload) {
    const { data: { data } } = await MarketplacesApi.getAliTokenUpdateUrl(payload)
    return data
  },

  async requestWildberries2FACode ({ commit }, payload) {
    try {
      const { data: { alreadyBound, detail, result } } = await MarketplacesApi.requestWildberries2FACode(payload)
      let code = alreadyBound ? 3 : 2
      if (detail === 'Need captcha.') {
        code = 5
      } else if (detail === 'waiting resend') {
        return [6, null]
      } else {
      }
      return [code, result.captcha, result.session_id]
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      return [1, null]
    }
  },

  async sendWildberriesAuthByTfaCode ({ commit }, payload) {
    try {
      const { data: { result } } = await MarketplacesApi.sendWildberriesAuthByTfaCode(payload)
      if (result.success) {
        return [3, result.session_id]
      }
      return result.message
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      return [1, null]
    }
  },

  async sendWildberries2FACode ({ commit }, payload) {
    try {
      const { data: { result } } = await MarketplacesApi.sendWildberries2FACode(payload)
      if (result.success) {
        return [3, payload.session_id]
      } else if (result.tfa) {
        return [4, result.session_id]
      } else {
        return [1, payload.session_id]
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      return [1, payload.session_id]
    }
  },

  async getWildberriesShopList ({ commit }, payload) {
    try {
      const { data: { suppliers } } = await MarketplacesApi.getWildberriesShopList(payload)
      return suppliers
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  },

  async attachWildberriesShop ({ commit }, payload) {
    try {
      const { data: { data, isSuccess } } = await MarketplacesApi.attachWildberriesShop(payload)
      if (data) {
        commit(Types.UPDATE_SHOP, { id: payload.id, data })
      }

      return isSuccess
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  },

  async detachWildberriesShop ({ commit }, payload) {
    try {
      const { data: { isSuccess, data } } = await MarketplacesApi.detachWildberriesShop(payload)
      if (data) {
        commit(Types.UPDATE_SHOP, { id: payload, data })
      }

      if (isSuccess) toastNotification.success('Магазин отвязан')
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  },

  async requestOzon2FACode ({ commit }, payload) {
    try {
      // eslint-disable-next-line camelcase
      const { data: { alreadyBound, data: { session_id, message } } } = await MarketplacesApi.requestOzon2FACode(payload)
      const code = alreadyBound ? 3 : 2
      if (code === 2) { Vue.$toast.error(message) }
      // eslint-disable-next-line camelcase
      return [code, session_id, message]
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      return [1, null, null]
      // return 1
    }
  },

  async sendOzon2FACode ({ commit }, payload) {
    try {
      const { data: { data } } = await MarketplacesApi.sendOzon2FACode(payload)
      const code = data != null ? 4 : 3
      const sessionId = data != null ? data.session_id : null
      const message = data != null ? data.message : null

      if (code === 4) { Vue.$toast.error(message) }

      return [code, sessionId, message]
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      return [1, null, null]
    }
  },

  async getOzonShopList ({ commit }, payload) {
    try {
      const { data: { data: { companies } } } = await MarketplacesApi.getOzonShopList(payload)
      return companies
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  },

  async attachOzonShop ({ commit }, payload) {
    try {
      const { data: { data, isSuccess } } = await MarketplacesApi.attachOzonShop(payload)
      if (data) {
        commit(Types.UPDATE_SHOP, { id: payload.id, data })
      }

      return isSuccess
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  },

  async detachOzonShop ({ commit }, payload) {
    try {
      const { data: { isSuccess, data } } = await MarketplacesApi.detachOzonShop(payload)
      if (data) {
        commit(Types.UPDATE_SHOP, { id: payload, data })
      }

      if (isSuccess) toastNotification.success('Магазин отвязан')
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  }
}
