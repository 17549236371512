import * as Types from './mutation-types'
import * as ProductsApi from '@/api/default/modules/products'
import setDefaultPaginationParams from '@/utils/setDefaultPaginationParams'
import marketplaceNameFormatter from '@/utils/marketplace-formatter'
import convertToFormData from '@/utils/form-data'

export default {
  async getMarketplacesList ({ commit }) {
    const { data: { data } } = await ProductsApi.getMarketplacesList()
    const tabs = data.marketplaces.map(m => ({
      ...m,
      title: marketplaceNameFormatter(m.name),
      path: m.id,
      counter: m.products
    }))
    commit(Types.SET_PRODUCTS_BY_MARKETPLACE, tabs)
  },

  async getProducts ({ commit }, payload) {
    const { data: { data, pagination } } = await ProductsApi.getProducts(setDefaultPaginationParams(payload))
    commit(Types.SET_PRODUCTS, data)
    return pagination
  },

  updateProductsList (ctx, shopId) {
    ProductsApi.updateProductsList({ shop: shopId })
  },

  async getUpdatableProducts ({ commit }, payload) {
    const { data: { data, pagination } } = await ProductsApi.getUpdatableProducts(payload)
    commit(Types.SET_UPDATABLE_PRODUCTS_ERRORS_COUNT, data.error_count)
    commit(Types.SET_UPDATABLE_PRODUCTS, data.items)
    return pagination.count
  },

  async downloadProducts (context, payload) {
    const { data: { isSuccess } } = await ProductsApi.downloadProducts(payload)
    return isSuccess
  },

  async downloadErrorProducts (context, payload) {
    const { data: { isSuccess } } = await ProductsApi.downloadErrorProducts(payload)
    return isSuccess
  },

  async downloadTurnoverReport (context, payload) {
    const { data: { isSuccess } } = await ProductsApi.downloadTurnoverReport(payload)
    return isSuccess
  },

  async uploadProducts (context, payload) {
    const { file, maxPriceIncrease, maxPriceDecrease } = payload
    payload = convertToFormData({
      file,
      max_price_increase: maxPriceIncrease || null,
      max_price_decrease: maxPriceDecrease || null
    })

    const { data: { data } } = await ProductsApi.uploadProducts(payload)
    return data.offer_update_list_id
  },

  async updateProducts (content, payload) {
    const { data: { isSuccess } } = await ProductsApi.updateProducts(payload)
    return isSuccess
  },

  async getProduct ({ commit }, id) {
    const { data: { data } } = await ProductsApi.getProduct(id)
    commit(Types.SET_CURRENT_PRODUCT, data)
  },

  async getProductOffers ({ commit }, payload) {
    const { data: { data } } = await ProductsApi.getProductOffers(payload)
    commit(Types.SET_PRODUCT_OFFERS, data)
  },

  async getProductBrands ({ commit }, payload) {
    const { data: { data } } = await ProductsApi.getProductBrands(payload)
    commit(Types.SET_PRODUCT_BRANDS, data)
  },

  async getProductCategories ({ commit }, payload) {
    const { data: { data } } = await ProductsApi.getProductCategories(payload)
    commit(Types.SET_PRODUCT_CATEGORIES, data)
  },

  async getProductStatuses ({ commit }, payload) {
    const { data: { data } } = await ProductsApi.getProductStatuses(payload)
    commit(Types.SET_PRODUCT_STATUSES, data)
  },

  clearProductStatuses ({ commit }) {
    commit(Types.SET_PRODUCT_STATUSES, [])
  },

  async syncPriceAndStocks (context, { target, ids, shop }) {
    const payload = { item_offer_ids: ids, shop }
    if (target === 'from') {
      const { data: { isSuccess } } = await ProductsApi.updatePriceAndStocksFromMarketplace(payload)
      return isSuccess
    }
    const { data: { isSuccess } } = await ProductsApi.updatePriceAndStocksOnMarketplace(payload)

    return isSuccess
  }
}
