const Base = '/seller_cabinet/v1'
const BaseOrders = `${Base}/orders`

export default {
  GetOrders: BaseOrders,
  GetOrder: id => `${BaseOrders}/${id}`,
  GetMarketplaces: `${BaseOrders}/marketplaces`,
  GetXwayOrderStatuses: `${BaseOrders}/xway-statuses`,
  DownloadOrderList: `${BaseOrders}/export`,
  SettingMarkSelfPurchase: `${BaseOrders}/self_buyout/mark`,
  RemovingMarkSelfPurchase: `${BaseOrders}/self_buyout/unmark`,
  SettingMarkSelfPurchaseByFileOzon: `${BaseOrders}/self_buyout_import/ozon`
}
