import { DEFAULT_PAGINATION_LIMIT } from '@/constants/pagination'

export default payload => {
  if (!payload) {
    return {
      params: {
        limit: DEFAULT_PAGINATION_LIMIT
      }
    }
  }

  const { params = {}, ...data } = payload

  if (!params.limit) {
    params.limit = DEFAULT_PAGINATION_LIMIT
  }

  return {
    ...data,
    params
  }
}
