const devices = {
  desktop: '1200px',
  tablet: '768px',
  mobile: '320px'
}

const currentDevice = () => {
  const [device] = Object.entries(devices).find(([name, width]) => {
    return window.matchMedia(`(min-width: ${width})`).matches
  })
  return device
}

const isMobile = currentDevice() === 'mobile'

export {
  isMobile
}
