import { createShop, fetchOzon2AliTaskList, fetchShops } from '@/api/default/modules/ozon2ali'
import marketplaceCodes from '@/constants/marketplace-codes'
import task from './task'

export const ozon2ali = {
  namespaced: true,
  state: {
    shops: [],
    isShopsLoading: false,
    isTasksLoading: false,
    taskList: []
  },
  mutations: {
    SET_SHOPS: (state, shops) => {
      state.shops = shops
    },
    SET_TASK_LIST: (state, taskList) => {
      state.taskList = taskList
    },
    SET_SHOPS_LOADING: (state, value) => {
      state.isShopsLoading = value
    },
    SET_TASKS_LOADING: (state, value) => {
      state.isTasksLoading = value
    }
  },
  getters: {
    aliShops: (state) => state.shops.filter(shop => shop.marketplace_code === marketplaceCodes.ALI),
    ozonShops: (state) => state.shops.filter(shop => shop.marketplace_code === marketplaceCodes.OZON),
    wbShops: (state) => state.shops.filter(shop => shop.marketplace_code === marketplaceCodes.WILDBERRIES)
  },
  actions: {
    async fetchShops ({ commit }) {
      try {
        commit('SET_SHOPS_LOADING', true)
        const response = await fetchShops()
        commit('SET_SHOPS', response.data.results)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
      } finally {
        commit('SET_SHOPS_LOADING', false)
      }
    },

    async fetchTasks ({ commit }, params) {
      try {
        commit('SET_TASKS_LOADING', true)
        const response = await fetchOzon2AliTaskList(params)
        commit('SET_TASK_LIST', response.data.results)
        return response.data.count
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
      } finally {
        commit('SET_TASKS_LOADING', false)
      }
    },

    async createShop ({ commit }, data) {
      return createShop(data.marketplace, data.params)
    }
  },
  modules: {
    task
  }
}
