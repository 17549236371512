import '@/styles/dashboards.styl'
import Measures from '@/constants/measure-name'
import formatPrice from '@/utils/price-formatter'
import MerticsName from '@/constants/dashboard_mertics'

const isNegativData = (name) => {
  if (name === 'drr' ||
    name === 'expired_feedbacks_count' ||
    name === 'products_with_errors' ||
    name === 'expired_fbs_orders' ||
    name === 'wb_avg_delivery_time' ||
    name === 'wb_commission' ||
    name === 'products_with_bad_description' ||
    name === 'products_in_quarantine' ||
    name === 'negative_index' ||
    name === 'questions_unanswered' ||
    name === 'products_0_stock_fbo' ||
    name === 'products_0_stock_fbs' ||
    name === 'products_1_stock_fbo' ||
    name === 'products_1_stock_fbs' ||
    name === 'products_2_5_stock_fbo' ||
    name === 'products_2_5_stock_fbs' ||
    name === 'disputed_orders' ||
    name === 'fbs_delivering_today' ||
    name === 'cancelled_orders_by_seller' ||
    name === 'cancelled_orders_rating' ||
    name === 'shipment_delay_rating' ||
    name === 'products_cancelled' ||
    name === 'products_returned' ||
    name === 'cancelled_orders_by_seller_rating' ||
    name === 'products_with_zero_stock_fbo' ||
    name === 'products_with_zero_stock_fbs') {
    return true
  }
  return false
}

const isNetralData = (name) => {
  const normalMetrics = name === 'paid_views_count' ||
    name === 'ozon_expense_template_adv_money' ||
    name === 'ozon_expense_search_adv_money' ||
    name === 'ozon_expense_brand_adv_money' ||
    name === 'advert_orders_share' ||
    name === 'wb_expense_search_adv_money' ||
    name === 'wb_expense_product_adv_money' ||
    name === 'wb_expense_catalog_adv_money' ||
    name === 'expense_adv_money'
  return normalMetrics
}

export default {
  formatLegend () {
    const firstTdWidth = this.legendItem.textWidth === '340px' ? '186px' : '158px'
    const lastValue = this.yData[this.yData.length - 1] ?? this.yData[this.yData.length - 2]
    const preLastValue = this.yData[this.yData.length - 1] ? this.yData[this.yData.length - 2] : this.yData[this.yData.length - 3]

    const userData = this.yData[this.yData.length - 1] ? this.userOptions.data[this.userOptions.data.length - 1] : this.userOptions.data[this.userOptions.data.length - 2]

    const data = JSON.parse(userData.description)
    const dd = data.dd ? data.dd[data.dd.length - 1] : null
    const nn = data.nn ? data.nn[data.nn.length - 1] : null
    const printLastValue = lastValue ? formatPrice(lastValue, { maximumFractionDigits: 0 }) : '-'
    const lastValueMeasure = userData.measure === Measures.RUBLES ? printLastValue + ' ₽'
      : userData.measure === Measures.PERCENT ? printLastValue + '%' : printLastValue + ' шт'
    if (dd && nn) {
      const ddMeasure = userData.measure === Measures.RUBLES ? formatPrice(dd.value, { maximumFractionDigits: 0 })
        : userData.measure === Measures.PERCENT ? dd.value : formatPrice(dd.value, { maximumFractionDigits: 0 })
      const nnMeasure = nn && userData.measure === Measures.RUBLES ? formatPrice(nn.value, { maximumFractionDigits: 0 })
        : nn && userData.measure === Measures.PERCENT ? nn.value
          : nn ? formatPrice(nn.value, { maximumFractionDigits: 0 }) : ''
      const ddPrint = dd ? dd.value > 0 ? `+${ddMeasure} (+${dd.percent}%)` : `${ddMeasure} (${dd.percent}%)` : ''
      const nnPrint = nn ? nn.value > 0 ? `+${nnMeasure} (+${nn.percent}%)` : `${nnMeasure} (${nn.percent}%)` : ''

      const fullDDPrint = dd && userData.show_day
        ? `<span>Д/Д </span><span style="color: ${dd.value === '0.00' || isNetralData(userData.name) ? '#7B7B85' : isNegativData(userData.name) ? dd.value > 0 ? '#FC1F49' : '#09C89F' : dd.value > 0 ? '#09C89F' : '#FC1F49'}">${ddPrint}</span>` : ''
      const fullNNPrint = nn && userData.show_week
        ? `<span>Н/Н </span><span style="color: ${nn.value === '0.00' || isNetralData(userData.name) ? '#7B7B85' : isNegativData(userData.name) ? nn.value > 0 ? '#FC1F49' : '#09C89F' : nn.value > 0 ? '#09C89F' : '#FC1F49'}">${nnPrint}</span>` : ''

      const arrowStyle = isNetralData(userData.name) ? '#7B7B85' : isNegativData(userData.name) ? preLastValue < lastValue ? '#FC1F49' : '#09C89F' : preLastValue > lastValue ? '#FC1F49' : '#09C89F'
      const arrowTop = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/></svg>'
      const arrowDown = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/></svg>'
      const arrowIcon = preLastValue > lastValue ? arrowDown : arrowTop

      return `<table style="width: ${this.legendItem.textWidth}; border-bottom: 1px solid #E5E5E9; margin-bottom: 8px; padding-bottom: 8px">
                <tr>
                    <td rowspan="3" style="width: ${firstTdWidth}; vertical-align: top">
                        <div style="width: ${firstTdWidth}; text-align: left">
                            <span style="color: ${this.color}; font-size: 14px; font-weight: bold">&#11044; </span>
                            <span style="white-space: pre-wrap; font-size: 14px">${this.name}</span>
                        </div>
                    </td>
                    <td style="width: 150px; text-align: right; font-size: 18px">
                      <div style="min-width: 150px">
                        <span>${lastValueMeasure}</span>
                        <span style="color: ${arrowStyle}">${arrowIcon}</span>
                      </div>
                    </td>
                </tr>
                <tr>
                    <td style="width: 150px; text-align: right">
                        <div style="${userData.show_day ? 'min-width: 150px' : ''}; color: #7B7B85">${fullDDPrint}</div>
                    </td>
                </tr>
                <tr>
                    <td style="width: 150px; text-align: right">
                      <div style="${userData.show_week ? 'min-width: 150px' : ''}; color: #7B7B85">${fullNNPrint}</div>
                    </td>
                </tr>
            </table>`
    } else {
      return `<table style="width: ${this.legendItem.textWidth}; border-bottom: 1px solid #E5E5E9; margin-bottom: 8px; padding-bottom: 8px">
                <tr>
                    <td rowspan="3" style="width: ${firstTdWidth}; vertical-align: top">
                        <div style="width: ${firstTdWidth}; text-align: left">
                            <span style="color: ${this.color}; font-size: 14px; font-weight: bold">&#11044; </span>
                            <span style="white-space: pre-wrap; font-size: 14px">-</span>
                        </div>
                    </td>
                    <td style="width: 150px; text-align: right; font-size: 18px">
                      <div style="min-width: 150px">
                        <span>${lastValueMeasure}</span>
                      </div>
                    </td>
                </tr>
            </table>`
    }
  },

  simpleFormatLegend () {
    const chart = this.chart
    chart.series.forEach(series => {
      const userOptions = series.userOptions
      const hasColor = !!userOptions.color
      series.color = userOptions.color
      series.yAxis.options.labels.style.color = userOptions.color
      series.colorIndex = userOptions.colorIndex
      series.userOptions._colorIndex = userOptions.colorIndex
      if (!hasColor) {
        series.visible = hasColor
      } else {
      }
    })
    return `<span style="color: ${this.color}">${MerticsName[this.name.toUpperCase()]}</span>`
  },

  simpleLegend () {
    return MerticsName[this.name.toUpperCase()] ? `<span style="color: ${this.color}">&#11044; </span>
            <span>${MerticsName[this.name.toUpperCase()]}</span>` : ''
  }
}
