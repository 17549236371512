import Measures from '@/constants/measure-name'
import formatPrice from '@/utils/price-formatter'

const isNegativData = (name) => {
  if (name === 'drr' ||
    name === 'expired_feedbacks_count' ||
    name === 'products_with_errors' ||
    name === 'expired_fbs_orders' ||
    name === 'wb_avg_delivery_time' ||
    name === 'wb_commission' ||
    name === 'products_with_bad_description' ||
    name === 'products_in_quarantine' ||
    name === 'negative_index' ||
    name === 'questions_unanswered' ||
    name === 'products_0_stock_fbo' ||
    name === 'products_0_stock_fbs' ||
    name === 'products_1_stock_fbo' ||
    name === 'products_1_stock_fbs' ||
    name === 'products_2_5_stock_fbo' ||
    name === 'products_2_5_stock_fbs' ||
    name === 'disputed_orders' ||
    name === 'fbs_delivering_today' ||
    name === 'cancelled_orders_by_seller' ||
    name === 'cancelled_orders_rating' ||
    name === 'shipment_delay_rating' ||
    name === 'products_cancelled' ||
    name === 'products_returned' ||
    name === 'cancelled_orders_by_seller_rating' ||
    name === 'products_with_zero_stock_fbo' ||
    name === 'products_with_zero_stock_fbs') {
    return true
  }
  return false
}

const isNetralData = (name) => {
  const normalMetrics = name === 'paid_views_count' ||
    name === 'ozon_expense_template_adv_money' ||
    name === 'ozon_expense_search_adv_money' ||
    name === 'ozon_expense_brand_adv_money' ||
    name === 'advert_orders_share' ||
    name === 'wb_expense_search_adv_money' ||
    name === 'wb_expense_product_adv_money' ||
    name === 'wb_expense_catalog_adv_money' ||
    name === 'expense_adv_money'
  return normalMetrics
}

const rusMeasurement = (name) => {
  if (name === 'wb_avg_delivery_time') {
    return ' ч'
  } else if (name === 'wb_merchant_rating' ||
    name === 'wb_commission' ||
    name === 'shipment_delay_rating' ||
    name === 'advert_orders_share' ||
    name === 'cancelled_orders_by_seller_rating' ||
    name === 'cancelled_orders_rating' ||
    name === 'ctr' ||
    name === 'drr') {
    return ' %'
  } else if (name === 'orders_money' ||
    name === 'ozon_expense_template_adv_money' ||
    name === 'ozon_expense_search_adv_money' ||
    name === 'ozon_expense_brand_adv_money' ||
    name === 'adv_orders_money' ||
    name === 'expense_adv_money' ||
    name === 'wb_expense_product_adv_money' ||
    name === 'wb_expense_search_adv_money' ||
    name === 'wb_expense_catalog_adv_money') {
    return ' ₽'
  } else if (name === 'positive_index' ||
    name === 'negative_index' ||
    name === 'neutral_index') {
    return ''
  } else {
    return ' шт'
  }
}

export default {
  formatterScattersTooltip () {
    let active = ''
    if (this.point.marker.symbol === 'url(https://cdn.xway.ru/mmp/icon%20check.svg)') {
      active = 'Доступен'
    } else if (this.point.marker.symbol === 'url(https://cdn.xway.ru/mmp/icon%20x.svg)') {
      active = 'Недоступен'
    } else if (this.point.marker.symbol === 'url(https://cdn.xway.ru/mmp/icon%20minus.svg)') {
      active = 'Нет данных'
    }
    return `<span style="color: #ffffff">${active}</span>`
  },

  formatterFooter (measure) {
    return '</table>' + '<hr style="margin: 8px; background-color: #54545E; border: none; height: 2px"/>' +
      '<div style="background-color: #151522"><b style="color: #A3A3AC; padding-left: 12px">Σ</b>' +
      `<b style="color: #ffffff; font-weight: normal; padding-left: 7px">
        {point.total}
        ${measure === Measures.RUBLES ? ' ₽'
        : measure === Measures.PERCENT ? ' %' : ' шт'}
      </b></div></div>`
  },

  formatterSuperMultipleTooltip () {
    const data = JSON.parse(this.description)
    const dd = {
      value: data[this.category].dd_value,
      percent: data[this.category].dd_percent
    }
    const nn = {
      value: data[this.category].nn_value,
      percent: data[this.category].nn_percent
    }
    const ddMeasure = this.measure === Measures.RUBLES ? dd.value + ' ₽'
      : this.measure === Measures.PERCENT ? dd.value + '%' : dd.value
    const nnMeasure = nn && this.measure === Measures.RUBLES ? nn.value + ' ₽'
      : nn && this.measure === Measures.PERCENT ? nn.value + '%'
        : nn ? nn.value : ''
    const ddPrint = dd ? dd.value > 0 ? `+${ddMeasure} (+${dd.percent}%)` : `${ddMeasure} (${dd.percent}%)` : ''
    const nnPrint = nn ? nn.value > 0 ? `+${nnMeasure} (+${nn.percent}%)` : `${nnMeasure} (${nn.percent}%)` : ''
    const fullDDPrint = dd && this.show_day
      ? `<span style="color: ${dd.value === '0' || isNetralData(data.name) ? '#7B7B85' : isNegativData(data.name) ? dd.value > 0 ? '#FC1F49' : '#09C89F' : dd.value > 0 ? '#09C89F' : '#FC1F49'}">${ddPrint}</span>` : ''
    const fullNNPrint = nn && this.show_week
      ? `<span style="color: ${nn.value === '0' || isNetralData(data.name) ? '#7B7B85' : isNegativData(data.name) ? nn.value > 0 ? '#FC1F49' : '#09C89F' : nn.value > 0 ? '#09C89F' : '#FC1F49'}">${nnPrint}</span>` : ''
    return `<tr style="color: #FFFFFF">
                <td>
                    <div style="color: ${this.color}">
                        &#11044; &nbsp;
                        <span style="color: #FFFFFF">
                            ${this.measure === Measures.RUBLES ? formatPrice(this.y, { maximumFractionDigits: 0 }) + rusMeasurement(data.name)
      : this.measure === Measures.PERCENT ? this.y + rusMeasurement(data.name) : formatPrice(this.y, { maximumFractionDigits: 0 }) + rusMeasurement(data.name)}
                        </span>
                    </div>
                </td>
                <td>${fullDDPrint}</td>
                <td>${fullNNPrint}</td>
            </tr>`
  },

  formatterMultipleTooltip () {
    const data = JSON.parse(this.description)
    const dd = data.dd ? data.dd[this.index] : null
    const nn = data.nn ? data.nn[this.index] : null
    const ddMeasure = this.measure === Measures.RUBLES ? formatPrice(dd.value, { maximumFractionDigits: 0 }) + ' ₽'
      : this.measure === Measures.PERCENT ? dd.value + '%' : formatPrice(dd.value, { maximumFractionDigits: 0 })
    const nnMeasure = nn && this.measure === Measures.RUBLES ? formatPrice(nn.value, { maximumFractionDigits: 0 }) + ' ₽'
      : nn && this.measure === Measures.PERCENT ? nn.value + '%'
        : nn ? formatPrice(nn.value, { maximumFractionDigits: 0 }) : ''
    const ddPrint = dd ? dd.value > 0 ? `+${ddMeasure} (+${dd.percent}%)` : `${ddMeasure} (${dd.percent}%)` : ''
    const nnPrint = nn ? nn.value > 0 ? `+${nnMeasure} (+${nn.percent}%)` : `${nnMeasure} (${nn.percent}%)` : ''
    const fullDDPrint = dd && this.show_day
      ? `<span style="color: ${dd.value === '0' || isNetralData(this.name) ? '#7B7B85' : isNegativData(this.name) ? dd.value > 0 ? '#FC1F49' : '#09C89F' : dd.value > 0 ? '#09C89F' : '#FC1F49'}">${ddPrint}</span>` : ''
    const fullNNPrint = nn && this.show_week
      ? `<span style="color: ${dd.value === '0' || isNetralData(this.name) ? '#7B7B85' : isNegativData(this.name) ? nn.value > 0 ? '#FC1F49' : '#09C89F' : nn.value > 0 ? '#09C89F' : '#FC1F49'}">${nnPrint}</span>` : ''
    return `<tr style="color: #FFFFFF">
                <td>
                    <div style="color: ${this.color}; white-space: nowrap">
                        &#11044; &nbsp;
                        <span style="color: #FFFFFF">
                            ${this.measure === Measures.RUBLES ? formatPrice(this.y, { maximumFractionDigits: 0 }) + ' ₽'
      : this.measure === Measures.PERCENT ? formatPrice(this.y, { maximumFractionDigits: 2 }) + ' %' : formatPrice(this.y, { maximumFractionDigits: 0 }) + ' шт'}
                        </span>
                    </div>
                </td>
                <td style="white-space: nowrap">${fullDDPrint}</td>
                <td style="white-space: nowrap">${fullNNPrint}</td>
            </tr>`
  },

  formatterHeader (showDay, showWeek) {
    return `<div style="background-color: #151522; padding: 10px 0; border-radius: 4px">
              <table style="border-spacing: 10px 0">
                <tr style="color: #FFFFFF; font-weight: 100">
                  <td style="white-space: nowrap">{point.x}</td>
                  <td>${showDay ? 'Д/Д' : ''}</td>
                  <td>${showWeek ? 'Н/Н' : ''}</td>
                </tr>`
  }
}
