const Base = '/seller_cabinet/v1/promo/'

export default {
  GetAll: Base,
  GetDetails: id => `${Base}${id}/details/`,
  EnableAutopromotion: `${Base}auto_promo/`,
  GetPromotion: id => `${Base}${id}/`,
  StartPromoRegistration: id => `${Base}${id}/registration/`,
  GetProductTemplate: id => `${Base}${id}/available_products/download/`,
  UploadProductTemplate: id => `${Base}${id}/products/upload/`,
  GetAvailableProducts: id => `${Base}${id}/available_products/`,
  UpdateAvailableProducts: id => `${Base}${id}/available_products/update/`,
  GetPromotionProducts: id => `${Base}${id}/products/`,
  DeletePromotionProducts: id => `${Base}${id}/products/`,
  GetPromotionProductsErrors: id => `${Base}${id}/products/errors/`,
  SavePromotionProduct: ({ promoId, id }) => `${Base}${promoId}/products/${id}`,
  SavePromotionOffer: ({ promoId, id }) => `${Base}${promoId}/offers/${id}`,
  ProductsRegistration: id => `${Base}${id}/products/registration/`
}
