import Vue from 'vue'

const currentTheme = Vue.observable({
  name: ''
})

export function setCurrentThemeName (themeName = '') {
  currentTheme.name = themeName
}

export default currentTheme
