<template>
  <x-card class="tile-indicator">
    <div
      v-if="loadind"
      class="tile-indicator__loader"
    >
      <loader />
    </div>
    <dl
      v-else
      class="tile-indicator__indication"
    >
      <dt class="tile-indicator__header">
        <svg-icon
          name="boxes"
          width="40"
          height="40"
        />
      </dt>
      <dt class="tile-indicator__title">
        Заказано товара на сумму
      </dt>

      <dd class="tile-indicator__content">
        {{ formattedTotalMoney }}
      </dd>
      <dd class="tile-indicator__content-count">
        {{ formattedTotalCount }}
      </dd>
      <div class="tile-indicator__scale">
        <div
          v-for="(shop, index) in shopsList"
          :key="shop.id"
          :style="{width: `${getWidth(shop.orders_money)}%`}"
          class="tile-indicator__scale-shop"
          :class="getColor(index)"
        />
      </div>
      <ul class="tile-indicator__list">
        <li
          v-for="(shop, index) in shopsList"
          :key="shop.id"
          class="tile-indicator__list-shops"
          @click="routing(shop)"
        >
          <table class="tile-indicator__list-shops__table">
            <tr>
              <td class="tile-indicator__list-shops__table-marker">
                <div
                  class="tile-indicator__list-shops__table-marker-shop"
                  :class="getColor(index)"
                />
              </td>
              <td class="tile-indicator__list-shops__table-logo">
                <x-icon
                  :name="getMarketplaceName(shop.shop.marketplace_id)"
                  size="s"
                  class="mr-2"
                />
              </td>
              <td>
                {{ shop.shop.name }}
              </td>
              <td class="tile-indicator__list-shops__table-total-money">
                {{ formatPrice(shop.orders_money, { maximumFractionDigits: 2 }) }} ₽
              </td>
              <td
                rowspan="2"
                class="tile-indicator__list-shops__table-chevron"
              >
                <x-icon
                  name="chevron-right"
                  size="xl"
                />
              </td>
            </tr>
            <tr>
              <td />
              <td />
              <td>
                <span class="tile-indicator__list-shops__table-anchor-value">
                  к сводке
                </span>
              </td>
              <td class="tile-indicator__list-shops__table-total-count">
                {{ formatPrice(shop.orders_count, { maximumFractionDigits: 0 }) }} шт
              </td>
            </tr>
          </table>
        </li>
      </ul>
      <dd class="tile-indicator__button">
        <x-btn
          prepend="plus"
          outline
          class="clients__action_add x-btn x-btn--size-s x-btn--primary x-btn--outline x-btn--pressed-state"
          @click="onAddShopClick"
        >
          Подключить еще
        </x-btn>
      </dd>
    </dl>
    <add-marketplace-modal />
    <connect-modal @success="onShopConnected" />
  </x-card>
</template>

<script>
import AddMarketplaceModal from '@/components/Modals/AddMarketplaceModal'
import ConnectModal from '@/components/Modals/ConnectModal'
import Loader from '@/components/Common/Loader.vue'
import formatPrice from '@/utils/price-formatter'
import { getMarketplaceIcon } from '@/utils/getMarketplaceIcon'

export default {
  components: {
    AddMarketplaceModal,
    ConnectModal,
    Loader
  },
  props: {
    shops: {
      type: Array,
      default: () => []
    },
    loadind: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      shopsList: []
    }
  },

  watch: {
    shops (value) {
      this.shopsList = value
    }
  },

  computed: {
    formattedTotalMoney () {
      const total = this.shops.reduce((money, shop) => money + shop.orders_money, 0)
      const result = (this.shops.length ? formatPrice(total, 2) : '-') + ' ₽'

      return result
    },

    formattedTotalCount () {
      const total = this.shops.reduce((count, shop) => count + shop.orders_count, 0)
      const result = (this.shops.length ? formatPrice(total, -1) : '-') + ' шт'

      return result
    }
  },

  mounted () {
    this.shopsList = this.shops
  },

  methods: {
    formatPrice,

    routing (shop) {
      if (shop.shop.marketplace_id === 9) {
        this.$router.push(`/new_dashboards/wildberries_new/${shop.shop.id}`)
      } else if (shop.shop.marketplace_id === 2) {
        this.$router.push(`/new_dashboards/ozon_new/${shop.shop.id}`)
      } else {
        this.$router.push(`/new_dashboards/${this.getMarketplaceName(shop.shop.marketplace_id)}/${shop.shop.id}`)
      }
    },

    getMarketplaceName (id) {
      let result = ''
      switch (id) {
        case 1:
          result = 'ali'
          break
        case 2:
          result = 'ozon'
          break
        case 4:
          result = 'yandex-market'
          break
        case 9:
          result = 'wildberries'
          break
        default:
          result = ''
      }
      getMarketplaceIcon(result)
      return result
    },

    getColor (index) {
      let result = ''
      switch (index) {
        case 0:
          result = 'blue'
          break
        case 1:
          result = 'red'
          break
        case 2:
          result = 'green'
          break
        case 3:
          result = 'orange'
          break
        case 4:
          result = 'purple'
          break
        case 5:
          result = 'brown'
          break
        case 6:
          result = 'pink'
          break
        default:
          result = ''
      }
      return result
    },

    getWidth (current) {
      const total = this.shops.reduce((money, shop) => money + shop.orders_money, 0)
      return current / total * 100
    },

    onAddShopClick () {
      this.$router.push('/marketplaces')
    },

    onShopConnected () {
      this.$modal.hide('connect-modal')
      this.$modal.hide('add-marketplace-modal')
    }
  }
}
</script>

<style lang="stylus" scoped>

  .tile-indicator
    height 100%
    padding 14px 0px 0px 0px

    @media (max-width 768px)
      padding-right 15px
      padding-left 15px

    &__loader
      text-align center

    &__indication
      display flex
      flex-direction column
      height 100%

    &__title
      margin-bottom 2px
      font-size 16px
      font-weight 600
      color #3C3C47
      text-align center

    &__scale
      padding 0 24px
      margin-bottom 30px
      &-shop
        display inline-block
        height 12px

    &__content
      font-weight $font-weights.bold
      font-size 32px
      color #3C3C47
      text-align center

    &__content-count
      font-weight $font-weights.semibold
      font-size 18px
      color #3C3C47
      opacity: 0.5
      margin-bottom 24px
      text-align center

    &__header
      display flex
      justify-content center
      align-items center
      margin-bottom 4px

    &__header-badge
      color #A3A3AC
      border 1px solid #A3A3AC
      border-radius 90px
      font-size 12px
      padding 2px 8px

    &__list
      padding 0

    &__list-shops
      border-top 1px solid #E5E5E9
      padding 12px 16px 12px 24px
      font-family 'proxima_nova'
      font-size 14px
      list-style-type none
      &:hover
        cursor pointer
      &:last-child
        border-bottom 1px solid #E5E5E9
        margin-bottom 15px
      &__table
        width 100%
        &-marker
          width 15px
          vertical-align top
          padding-top 6px
          &-shop
            width 8px
            height 8px
            border-radius 50%
        &-logo
          width 25px
          text-align right
          vertical-align top
        &-chevron
          width 20px
          opacity 0.5
        &-total
          color #3C3C47
          &-money
            text-align right
            font-size 16px
            font-weight 600
            white-space nowrap
          &-count
            text-align right
            font-size 12px
            font-weight 600
            opacity 0.5
        &-anchor-value
          font-family 'proxima_nova'
          color #5B40FF
          text-decoration none
          font-size 12px

    &__list-anchor
      display flex
      justify-content space-between
      border-bottom 1px solid #A3A3AC
      padding 8px 0

      &:last-child
        border none

    &__list-content
      align-items center
      display flex
      padding 8px 0

      &::before
        content ''
        border-bottom 1px dashed #A3A3AC
        flex-grow 1
        align-self flex-end
        order 2
        margin 0 2px

    &__list-value
      order 3
      display flex
      align-items center
      font-weight bold

    &__list-anchor-value
      order 3
      display flex
      align-items center

    &__list-shop
      display flex
      align-items center

    &__button
      align-self center
      margin-bottom 8px
</style>
