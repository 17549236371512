<template>
  <div class="dashboard pt-5">
    <div class="dashboard__heading headline-2 mb-5">
      Сводка
    </div>
    <div
      class="dashboard__filters mb-10"
      :class="{'not-permit-by-tariff': !isFiltersPermitByTariff}"
    >
      <new-dashboards-filters
        :period="period"
        :shop="selectedShops"
        @change:period="onPeriodChange"
        @change:shop="onShopChange"
        @change:show-by="onShowBy"
      />
    </div>
    <template>
      <div
        v-if="dataCollection"
        class="dashboard__row-warning"
      >
        <img src="https://cdn.xway.ru/mmp/icon%20x.svg">
        <span>
          Перейдите в «Мои магазины» и включите сбор данных для аналитической отчётности в настройках магазина.
          <x-link
            primary
            @click="routing"
          >
            Перейти в «Мои магазины»
          </x-link>
        </span>
        <button @click="dataCollection = false">
          <svg-icon
            class="svg-icon"
            name="close"
          />
        </button>
      </div>
      <div class="content-columns content-columns_content_a1-b2">
        <div class="column column_layout_a1">
          <new-tile-for-multi
            v-if="isSummaryPermitByTariff"
            :shops="fullShopsInfo"
            :loadind="isLoading.includes('tile')"
          />
        </div>
        <div class="column column_layout_b2">
          <div class="dashboard__row">
            <multiple-scatters
              heading="is_shop_active"
              :points="is_shop_active"
              :loadind="isLoading.includes('is_shop_active')"
            />
          </div>
          <div class="dashboard__row">
            <multiple-areas
              heading="orders_count"
              :points="orders_count"
              :measure="Measures.QUANTITY"
              :show-day="show_day"
              :show-week="show_week"
              :loadind="isLoading.includes('orders_count')"
            />
          </div>
          <div class="dashboard__row">
            <multiple-areas
              heading="orders_money"
              :points="orders_money"
              :measure="Measures.RUBLES"
              :show-day="show_day"
              :show-week="show_week"
              :loadind="isLoading.includes('orders_money')"
            />
          </div>
        </div>
      </div>
      <div class="dashboard__row content-columns content-columns_content_a-b-c">
        <div class="column column_layout_a">
          <multiple-lines
            heading="products_in_selling"
            :points="products_in_selling"
            :measure="Measures.QUANTITY"
            :show-day="show_day"
            :show-week="show_week"
            :loadind="isLoading.includes('products_in_selling')"
          />
        </div>

        <div class="column column_layout_b">
          <multiple-lines
            heading="products_with_zero_stock"
            :points="products_with_zero_stock"
            :measure="Measures.QUANTITY"
            :show-day="show_day"
            :show-week="show_week"
            :loadind="isLoading.includes('products_with_zero_stock')"
          />
        </div>

        <div class="column column_layout_c">
          <multiple-lines
            heading="products_with_errors"
            :points="products_with_errors"
            :measure="Measures.QUANTITY"
            :show-day="show_day"
            :show-week="show_week"
            :loadind="isLoading.includes('products_with_errors')"
          />
        </div>

        <div class="column column_layout_a">
          <multiple-lines
            heading="expired_feedbacks_count"
            :points="expired_feedbacks_count"
            :measure="Measures.QUANTITY"
            :show-day="show_day"
            :show-week="show_week"
            :loadind="isLoading.includes('expired_feedbacks_count')"
          />
        </div>

        <div class="column column_layout_b">
          <multiple-columns
            heading="expense_adv_money"
            :points="expense_adv_money"
            :measure="Measures.RUBLES"
            :show-day="show_day"
            :show-week="show_week"
            :loadind="isLoading.includes('expense_adv_money')"
          />
        </div>

        <div class="column column_layout_c">
          <multiple-columns
            heading="drr"
            :points="drr"
            :measure="Measures.PERCENT"
            :show-day="show_day"
            :show-week="show_week"
            :loadind="isLoading.includes('drr')"
          />
        </div>
      </div>
    </template>

    <connect-shops-error-modal />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import moment from 'moment'
import formatDate from '@/utils/date-formatter'
import formatterDataDashboards from '@/utils/formatterDataDashboards'
import toastNotification from '@/utils/toast-notification'
import NewDashboardsFilters from '@/components/Dashboard/NewDashboardsFilters.vue'
import NewTileForMulti from '@/components/Dashboard/NewTileForMulti.vue'
import MultipleLines from '@/components/Dashboard/Graphs/MultipleLines.vue'
import MultipleColumns from '@/components/Dashboard/Graphs/MultipleColumns.vue'
import MultipleScatters from '@/components/Dashboard/Graphs/MultipleScatters.vue'
import MultipleAreas from '@/components/Dashboard/Graphs/MultipleAreas.vue'
import ConnectShopsErrorModal from '@/components/Dashboard/ConnectShopsErrorModal.vue'
import Measures from '@/constants/measure-name'
import Tooltip from '@/components/Common/Tooltip.vue'
import '@/styles/dashboards.styl'

const { mapActions } = createNamespacedHelpers('dashboards')
const { mapState } = createNamespacedHelpers('marketplaces')
const { mapState: mapProfileState } = createNamespacedHelpers('profile')

export default {
  name: 'HomeWithDashboards',

  components: {
    NewDashboardsFilters,
    NewTileForMulti,
    MultipleLines,
    MultipleColumns,
    MultipleAreas,
    MultipleScatters,
    ConnectShopsErrorModal,
    Tooltip
  },

  data () {
    return {
      Measures,
      // TODO: isLoading: false and remove isFiltersPermitByTariff, isSummaryPermitByTariff
      isLoading: [],
      isFiltersPermitByTariff: true,
      isSummaryPermitByTariff: true,
      expense_adv_money: {},
      expired_feedbacks_count: {},
      products_with_zero_stock: {},
      is_shop_active: {},
      drr: {},
      products_in_selling: {},
      orders_count: {},
      orders_money: {},
      products_with_errors: {},
      fullShopsInfo: [],
      selectedShops: [],
      dataCollection: false,
      show_day: true,
      show_week: true,
      period: {
        start: moment(new Date()).subtract(8, 'days').toDate(),
        end: moment(new Date()).subtract(1, 'days').toDate()
      }
    }
  },

  computed: {
    ...mapProfileState(['user']),
    ...mapState(['shops'])
  },

  mounted () {
    this.isLoading = ['is_shop_active', 'orders_count', 'orders_money', 'drr', 'expense_adv_money', 'expired_feedbacks_count', 'products_in_selling', 'products_with_errors', 'products_with_zero_stock', 'tile']
    const storage = localStorage.getItem('period')
    if (storage) {
      this.period = JSON.parse(storage)
    }
    const selectedShops = localStorage.getItem(`${this.user.id}_shops`)
    if (selectedShops) {
      this.selectedShops = JSON.parse(selectedShops)
    }
  },

  methods: {
    ...mapActions([
      'getShopsActivity',
      'getOrdersCount',
      'getDrrHistory',
      'getExpenseHistory',
      'getExpiredFeedbacksCount',
      'getProductHistory',
      'getOrderFull'
    ]),

    routing () {
      this.$router.push('/marketplaces')
    },

    getAllData () {
      const payload = {
        shop_ids: this.selectedShops.map(shop => shop.id),
        date_from: formatDate(this.period.start, 'YYYY-MM-DD'),
        date_to: formatDate(this.period.end, 'YYYY-MM-DD')
      }
      this.getOrder(payload)
      this.getShopsActivityList(payload)
      this.getOrdersCountList(payload)
      this.getDrrHistoryList(payload)
      this.getExpenseHistoryList(payload)
      this.getExpiredFeedbacksCountList(payload)
      this.getProductHistoryList(payload)
    },

    async getShopsActivityList (payload) {
      try {
        this.isLoading.push('is_shop_active')
        const start = new Date(payload.date_from)
        const end = new Date(payload.date_to)
        const daysLag = Math.ceil(Math.abs(end.getTime() - start.getTime()) / (1000 * 3600 * 24))
        const newPayload = { ...payload }
        if (daysLag > 30) {
          const newStart = moment(end).subtract(30, 'days').toDate()
          newPayload.date_from = formatDate(newStart, 'YYYY-MM-DD')
        }
        this.is_shop_active = {}
        const { isSuccess, data, error } = await this.getShopsActivity(newPayload)
        if (!isSuccess) {
          toastNotification.error(error.msg)
        }
        formatterDataDashboards.formatData(this, data, 'is_shop_active')
      } finally {
        this.isLoading = this.isLoading.filter(loader => loader !== 'is_shop_active')
      }
    },

    async getOrdersCountList (payload) {
      try {
        this.isLoading.push('orders_count')
        this.isLoading.push('orders_money')
        this.orders_count = {}
        this.orders_money = {}
        const { isSuccess, data, error } = await this.getOrdersCount(payload)
        if (!isSuccess) {
          toastNotification.error(error.msg)
        }
        formatterDataDashboards.formatData(this, data, 'orders_count')
        formatterDataDashboards.formatData(this, data, 'orders_money')
      } finally {
        this.isLoading = this.isLoading.filter(loader => loader !== 'orders_count' && loader !== 'orders_money')
      }
    },

    async getDrrHistoryList (payload) {
      try {
        this.isLoading.push('drr')
        this.drr = {}
        const { isSuccess, data, error } = await this.getDrrHistory(payload)
        if (!isSuccess) {
          toastNotification.error(error.msg)
        }
        formatterDataDashboards.formatData(this, data, 'drr')
      } finally {
        this.isLoading = this.isLoading.filter(loader => loader !== 'drr')
      }
    },

    async getExpenseHistoryList (payload) {
      try {
        this.isLoading.push('expense_adv_money')
        this.expense_adv_money = {}
        const { isSuccess, data, error } = await this.getExpenseHistory(payload)
        if (!isSuccess) {
          toastNotification.error(error.msg)
        }
        formatterDataDashboards.formatData(this, data, 'expense_adv_money')
      } finally {
        this.isLoading = this.isLoading.filter(loader => loader !== 'expense_adv_money')
      }
    },

    async getExpiredFeedbacksCountList (payload) {
      try {
        this.isLoading.push('expired_feedbacks_count')
        this.expired_feedbacks_count = {}
        const { isSuccess, data, error } = await this.getExpiredFeedbacksCount(payload)
        if (!isSuccess) {
          toastNotification.error(error.msg)
        }
        formatterDataDashboards.formatData(this, data, 'expired_feedbacks_count')
      } finally {
        this.isLoading = this.isLoading.filter(loader => loader !== 'expired_feedbacks_count')
      }
    },

    async getProductHistoryList (payload) {
      try {
        this.isLoading.push('products_in_selling')
        this.isLoading.push('products_with_errors')
        this.isLoading.push('products_with_zero_stock')
        this.products_in_selling = {}
        this.products_with_errors = {}
        this.products_with_zero_stock = {}
        const { isSuccess, data, error } = await this.getProductHistory(payload)
        if (!isSuccess) {
          toastNotification.error(error.msg)
        }
        formatterDataDashboards.formatData(this, data, 'products_in_selling')
        formatterDataDashboards.formatData(this, data, 'products_with_errors')
        formatterDataDashboards.formatData(this, data, 'products_with_zero_stock')
      } finally {
        this.isLoading = this.isLoading.filter(loader => loader !== 'products_in_selling' && loader !== 'products_with_errors' && loader !== 'products_with_zero_stock')
      }
    },

    async getOrder (payload) {
      try {
        this.isLoading.push('tile')
        this.fullShopsInfo = []
        const { isSuccess, data, error } = await this.getOrderFull(payload)
        if (isSuccess) {
          this.fullShopsInfo = data
        } else {
          toastNotification.error(error.msg)
        }
      } finally {
        this.isLoading = this.isLoading.filter(loader => loader !== 'tile')
      }
    },

    onShowBy (param, value) {
      this[param] = value
    },

    onPeriodChange (period) {
      this.period = period
      localStorage.setItem('period', JSON.stringify(period))
      this.getAllData()
    },

    onShopChange (shops) {
      const storage = localStorage.getItem('period')
      if (storage) {
        this.period = JSON.parse(storage)
      }
      let isChanging = this.selectedShops.length === shops.length
      if (isChanging) {
        shops.forEach((shop, index) => {
          if (shop.id !== this.selectedShops[index].id) {
            isChanging = false
          }
        })
      }
      if (Array.isArray(shops) && !isChanging) {
        this.selectedShops = shops
        localStorage.setItem(`${this.user.id}_shops`, JSON.stringify(shops))
      }
      if (!this.shops.length) {
        this.$modal.show('connect-shops-error-modal')
      } else {
        this.shops.forEach(shop => {
          if (!shop.is_active) {
            this.dataCollection = true
          }
        })
      }
      if (shops.length) {
        this.getAllData()
      } else {
        this.isLoading = []
        this.fullShopsInfo = []
        formatterDataDashboards.formatData(this, null, 'products_in_selling')
        formatterDataDashboards.formatData(this, null, 'products_with_errors')
        formatterDataDashboards.formatData(this, null, 'products_with_zero_stock')
        formatterDataDashboards.formatData(this, null, 'expired_feedbacks_count')
        formatterDataDashboards.formatData(this, null, 'expense_adv_money')
        formatterDataDashboards.formatData(this, null, 'drr')
        formatterDataDashboards.formatData(this, null, 'orders_count')
        formatterDataDashboards.formatData(this, null, 'orders_money')
        formatterDataDashboards.formatData(this, null, 'is_shop_active')
      }
    }
  }
}
</script>
