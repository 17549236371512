import * as Types from './mutation-types'
import * as ProfileApi from '@/api/default/modules/profile'
import { removeApiToken, redirectOnPassport } from '@/utils/system'
import {
  getThemeNameByCabinetType,
  applyTheme,
  clearTheme,
  getCompanyDetails,
  getDefaultCompanyDetails,
  getDefaultThemeName
} from '@xway-team/theme-detector'
import { setCompanyDetails } from '@/plugins/companyDetails'
import { setCurrentThemeName } from '@/plugins/currentTheme'

export default {
  logout ({ commit }) {
    removeApiToken()
    commit(Types.SET_USER, null)
    redirectOnPassport()
  },

  async getProfile ({ commit }) {
    commit(Types.TOGGLE_PROFILE_LOADING)
    const { data: { data } } = await ProfileApi.getProfile()
    commit(Types.TOGGLE_PROFILE_LOADING)
    commit(Types.SET_USER, data)

    if (data.is_demo_account) return

    const themeName = getThemeNameByCabinetType(data.cabinet_type)
    if (themeName) {
      applyTheme(themeName)
      setCompanyDetails(getCompanyDetails(themeName))
      setCurrentThemeName(themeName)
    } else {
      clearTheme()
      setCompanyDetails(getDefaultCompanyDetails())
      setCurrentThemeName(getDefaultThemeName())
    }
  },

  async createClient ({ commit }, payload) {
    await ProfileApi.createClient(payload)
  },

  sendPhoneVerificationCode ({ commit }, phone) {
    return ProfileApi.sendPhoneVerificationCode({ phone_number: phone })
  },

  async verifyPhone ({ commit }, payload) {
    const { data: { data } } = await ProfileApi.verifyPhone(payload)
    return data
  },

  async sendServiceForm ({ commit }, payload) {
    const { data: { data } } = await ProfileApi.sendServiceForm(payload)
    return data
  },

  setUserReferral ({ commit }, payload) {
    return ProfileApi.setUserReferral(payload)
  }
}
