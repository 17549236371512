import { getApiToken } from '@/utils/system'
import {
  WS_STATE_DISCONNECTED,
  WS_STATE_ABORTED,
  WS_STATE_CONNECTED,
  WS_STATE_ERROR,
  WS_EVENT_NAME_ERROR
} from '@/plugins/websocket/const'
import store from '../../store'

class ListenOnlyEventBus {
  #_listeners = {};

  on (eventName, listener) {
    if (this.#_listeners[eventName]) {
      this.#_listeners[eventName].push(listener)
    } else {
      this.#_listeners[eventName] = [listener]
    }
  }

  off (eventName, listener) {
    const listeners = this.#_listeners[eventName]
    if (listeners) {
      this.#_listeners[eventName] = this.#_listeners[eventName].filter(item => item !== listener)
    }
  }

  // Это protected метод. Не трогать вне дочерних классов!
  _emit (eventName, data) {
    const listeners = this.#_listeners[eventName]

    if (listeners) {
      listeners.forEach(item => item(data))
    }
  }
}

class WsEventBus extends ListenOnlyEventBus {
  onMessage (event) {
    try {
      const data = JSON.parse(event.data)
      const eventName = data.event
      const eventData = data?.data

      this._emit(eventName, eventData)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err.message)
    }
  }

  onError (err) {
    this._emit(WS_EVENT_NAME_ERROR, err)
  }
}

const wsEventBus = new WsEventBus()

let wsInstance = null

const getWsUrl = ({ userGuid, host, token }) => {
  return `${host}/api/websocket/users/${userGuid}/notifications/ws?jwt_token=${token}`
}

const wsOnOpen = () => {
  store.dispatch('wsState/setState', WS_STATE_CONNECTED)
}

const wsOnClose = event => {
  if (event.wasClean) {
    store.dispatch('wsState/setState', WS_STATE_DISCONNECTED)
  } else {
    store.dispatch('wsState/setState', WS_STATE_ABORTED)
  }
}

const wsOnError = (err) => {
  store.dispatch('wsState/setState', WS_STATE_ERROR)
  wsEventBus.onError(err)
}

const wsOnMessage = (event) => wsEventBus.onMessage(event)

export const wsConnect = (userGuid) => {
  const url = getWsUrl({
    host: process.env.VUE_APP_WS_URL,
    userGuid,
    token: getApiToken()
  })

  wsInstance = new WebSocket(url)

  wsInstance.onopen = wsOnOpen
  wsInstance.onclose = wsOnClose
  wsInstance.onmessage = wsOnMessage
  wsInstance.onerror = wsOnError
}

export const webSocketPlugin = {
  install (Vue) {
    Vue.prototype.$ws = wsEventBus
  }
}

export { wsEventBus }
