<template>
  <svg
    :class="currentClass"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title v-if="title">
      {{ title }}
    </title>

    <use
      :xlink:href="iconPath"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    />
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
      readonly: true
    },
    title: {
      type: String,
      default: null
    },
    className: {
      type: String,
      default: ''
    }
  },

  computed: {
    iconPath () {
      let icon = require(`@/assets/icons/${this.name}.svg`)
      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default
      }

      return `#${this.name}`
    },

    currentClass () {
      return `svg-icon svg-icon--${this.name} ${this.className}`
    }
  }
}
</script>

<style lang="stylus">
  .svg-icon
    fill currentColor

    &--error-triangle
      fill #fc96Ae
</style>
