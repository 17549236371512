import * as Types from './mutation-types'

export default {
  [Types.SET_DASHBOARDS_DATA] (state, payload) {
    state.dashboards = payload
  },
  [Types.SET_DASHBOARDS_LOADED] (state, payload) {
    state.isDashboardLoaded = payload
  }
}
