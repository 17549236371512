export const permissionTabs = Object.freeze([{
  title: 'Клиенты',
  id: 'clients'
}, {
  title: 'Пользователи',
  id: 'users'
}, {
  title: 'Роли',
  id: 'roles'
}])

export const clientEntityTypes = Object.freeze([{
  label: 'ООО',
  value: 'OOO'
}, {
  label: 'ИП',
  value: 'IP'
}, {
  label: 'Физическое лицо',
  value: 'individual'
}])

export const referralKey = 'referrer_id'

export const financesTabs = Object.freeze([{
  id: 'Main',
  title: 'Основное'
}, {
  id: 'history',
  title: 'История'
}/*, {
  id: 'payment-account',
  title: 'Управление счетами'
} */])

export const transactionHistoryColumns = Object.freeze([{
  name: 'Тип',
  key: 'type',
  type: 'type'
}, {
  name: 'Создано',
  key: 'created',
  type: 'created'
}, {
  name: 'Статус',
  key: 'status',
  type: 'status'
}, {
  name: 'Дополнительная информация',
  key: 'data',
  type: 'data'
}, {
  name: 'Сумма',
  key: 'amount',
  type: 'amount',
  styleType: 'numbers'
}])

export const paymentAccountColumns = Object.freeze([{
  name: 'Клиент',
  key: 'client_name'
}, {
  name: 'Название компании',
  key: 'organization_name'
}, {
  name: 'ИНН',
  key: 'inn'
}, {
  name: 'БИК',
  key: 'bik'
}])
