const Base = '/seller_cabinet/v1'
const NewDashboardHostBase = '/api/mmp/v1/dashboard/shop'

export default {
  GetAll: `${Base}/dashboards/`,

  getShopsActivity: `${NewDashboardHostBase}/shop_activity/history`,

  getShopActivity: (id) => `${NewDashboardHostBase}/${id}/shop_activity/history`,

  getShopOrderFull: (id) => `${NewDashboardHostBase}/${id}/order/full`,

  getOrdersCount: `${NewDashboardHostBase}/order/history`,

  getDrrHistory: `${NewDashboardHostBase}/adv_company/drr/history`,

  getExpenseHistory: `${NewDashboardHostBase}/adv_company/expense/history`,

  getExpiredFeedbacksCount: `${NewDashboardHostBase}/review/expired/history`,

  getProductHistory: `${NewDashboardHostBase}/product/history`,

  getOrderFull: `${NewDashboardHostBase}/order/full`,

  getMetricsList: (id) => `${NewDashboardHostBase}/${id}/metric/list`,

  getMetricsStatistic: (id) => `${NewDashboardHostBase}/${id}/metric/statistic`,

  getTop5Product: (id) => `${NewDashboardHostBase}/product/top`
}
