<template>
  <component
    :is="tag"
    class="form-error"
    v-html="text"
  />
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus" scoped>
  .form-error
    margin-top 4px
    font-size 12px
    color #f03738
</style>
