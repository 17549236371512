<template>
  <div>
    <button
      type="button"
      class="datepicker"
      :class="{ 'border': border, 'placeholder': placeholderClass }"
      @click="openDatePickerModal"
    >
      {{ formattedDate }}

      <svg-icon
        name="calendar"
        class="datepicker__icon"
      />
    </button>

    <datepicker-modal
      v-if="modalActive"
      :value="value"
      :has-reset-button="hasResetButton"
      :min-date="minDate"
      :max-date="maxDate"
      :default-period="defaultPeriod"
      @hide="modalActive = false"
      @change="onDateChange"
      @change:filter="value => currentFilter = value"
      @change-default-period="period => $emit('change-default-period', period)"
    />
  </div>
</template>

<script>
import CalendarPeriods from '@/constants/calendar-periods'
import formatDate from '@/utils/date-formatter'
import DatepickerModal from '@/components/Modals/DatepickerModal.vue'

const today = new Date()

export default {
  components: {
    DatepickerModal
  },

  props: {
    value: {
      type: Object,
      default: () => ({
        start: today,
        end: today
      })
    },
    maxDate: {
      type: Date,
      default: undefined
    },
    minDate: {
      type: Date,
      default: undefined
    },
    border: Boolean,
    hasResetButton: Boolean,
    defaultPeriod: {
      type: String,
      default: CalendarPeriods.TODAY
    }
  },

  data () {
    return {
      date: this.value,
      modalActive: false
    }
  },

  watch: {
    value (newValue) {
      this.date = newValue
    }
  },

  computed: {
    formattedDate () {
      const { start, end } = this.date

      if (!start && !end) return 'Выберите период'

      if (new Date(start).getTime() === new Date(end).getTime()) {
        return formatDate(start)
      }
      return `${formatDate(start)} - ${formatDate(end)}`
    },

    placeholderClass () {
      const { start, end } = this.date

      return !start && !end
    }
  },

  methods: {
    onDateChange (range) {
      const { start, end } = range
      this.date = range

      if (start && end) {
        this.$emit('change', {
          start: formatDate(start, 'YYYY-MM-DD'),
          end: formatDate(end, 'YYYY-MM-DD')
        })
      } else {
        this.$emit('change', range)
      }
    },

    openDatePickerModal () {
      this.modalActive = true
      this.$nextTick(() => this.$modal.show('datepicker-modal'))
    }
  }
}
</script>

<style lang="stylus" scoped>
  .datepicker
    position relative
    display block
    width 100%
    height 36px
    padding 0 40px 0 8px
    font-family $font-family
    font-size 14px
    line-height 36px
    text-align left
    white-space nowrap
    color rgba(#2c2c37, 90%)
    background #fff
    border-radius 5px
    box-sizing border-box
    transition border-color .3s

    &.placeholder
      color rgba(#2c2c37, 25%)

    &.border
      border 1px solid #cacad3
      border-radius 4px

      &:hover
        border-color #7b7b85

      &:focus
        border-color #5c40ff

      .datepicker__icon
        color #a3a3ac

    &__icon
      position absolute
      top 50%
      right 10px
      width 14px
      height 14px
      transform translateY(-50%)
      color #ad9fff
</style>
