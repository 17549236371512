import * as Types from './mutation-types'
import * as StocksApi from '@/api/default/modules/prices-stocks'
import downloadFile from '@/utils/download-file'
import { XLSX } from '@/constants/fileTypes'
import toastNotification from '@/utils/toast-notification'

export default {
  async getTasks ({ commit }, payload) {
    const { data: { data, pagination } } = await StocksApi.getTasks(payload)
    commit(Types.SET_TASKS, data)

    return pagination.count
  },

  async downloadStockXLSX (_, taskId) {
    try {
      const { data: blob } = await StocksApi.downloadStock(taskId)
      downloadFile({
        url: blob,
        fileName: `log_${taskId}`,
        type: XLSX
      })
    } catch (e) {
      toastNotification.error(`Не удалось скачать лог задачи № ${taskId}`)
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }
}
