export default {
  promotions: [],
  promotion: {},
  details: {},
  availableProducts: [],
  availableProductsCount: 0,
  products: [],
  productsCount: 0,
  productsWithErrors: [],
  productsWithErrorsCount: 0,
  productRegistrationErrors: [],
  errors: []
}
