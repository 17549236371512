import Vue from 'vue'
import {
  required,
  digits,
  email,
  regex,
  confirmed,
  integer,
  between,
  min_value as minValue,
  length
} from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'

// Ozon

extend('ozonClientId', {
  message: 'Непохоже на Ozon Client ID',
  validate: (value, args) => {
    const newValue = +(value.toString().replace(',', '.'))
    return typeof newValue === 'number' && isFinite(newValue) && !isNaN(newValue)
  }
})

extend('apiOzonKey', {
  message: 'Непохоже на API-ключ Ozon Администратора',
  validate (value, args) {
    const regExptTestingStrg = /\b[a-zA-Z0-9_-]{36}\b/
    return regExptTestingStrg.test(value)
  }
})

// Yandex Market (beru)

// Номер кампании в Яндекс.Маркет
extend('beruClientId', {
  message: 'Непохоже на номер кампании в Яндекс.Маркет',
  validate: (value, args) => {
    const newValue = +(value.toString().replace(',', '.'))
    return typeof newValue === 'number' && isFinite(newValue) && !isNaN(newValue)
  }
}) // done

// ID приложения в Яндекс.Маркет
extend('beruAppId', {
  message: 'Непохоже на ID приложения в Яндекс.Маркет',
  validate (value, args) {
    const regExptTestingStrg = /\b[A-Za-z0-9]{32,39}\b/
    return regExptTestingStrg.test(value)
  }
}) // done

// Токен приложения в Яндекс.Маркет
extend('beruToken', {
  message: 'Непохоже на токен приложения Яндекс.Маркет',
  validate (value, args) {
    const regExptTestingStrg = /\b[a-zA-Z0-9_-]{39}\b/ // /\b[A-Z0-9]{16}\b/
    return regExptTestingStrg.test(value)
  }
}) // done

// Авторизационный токен на Яндекс.Маркет
extend('yandexKey', {
  message: 'Непохоже на авторизационный токен на Яндекс.Маркет',
  validate (value, args) {
    const regExptTestingStrg = /\b[A-Z0-9]{1,16}\b/
    return regExptTestingStrg.test(value)
  }
}) // done

// Wildberries

extend('apiWbKeyAdv', {
  message: 'Непохоже на токен для работы с API ADV',
  validate (value, args) {
    // eslint-disable-next-line
    return true
  }
})

extend('apiWbKey64', {
  message: 'Непохоже на ключ для работы с API статистики x64',
  validate (value, args) {
    // eslint-disable-next-line
    const regExptTestingStrg = /\b[a-zA-Z0-9\-\.\_]{149}\b/
    // return regExptTestingStrg.test(value)
    return true
  }
})

extend('apiWbKey', {
  message: 'Непохоже на токен для работы с API',
  validate (value, args) {
    // eslint-disable-next-line
    const regExptTestingStrg = /\b[a-zA-Z0-9\-\.\_]{149}\b/
    // return regExptTestingStrg.test(value)
    return true
  }
})

// etc extends

extend('integer', {
  ...integer,
  message: 'Поле "{_field_}" должно быть целым числом'
})

extend('isNumber', {
  validate: (value) => {
    const newValue = +(value.toString().replace(',', '.'))
    return typeof newValue === 'number' && isFinite(newValue) && !isNaN(newValue)
  },
  message: 'Поле "{_field_}" должно быть числом'
})

extend('greaterThanZero', {
  message: field => `Поле "${field}" должно быть больше 0`,
  validate: (value) => parseInt(value) > 0
})

extend('discount_mob', {
  message: (field, ...args) => `Поле "${field}" должно быть или 0, или в диапозоне от ${parseInt(args[0][0])} до ${args[0][1]}`,
  validate: (value, ...args) => parseInt(value) === 0 || (parseInt(value) >= parseInt(args[0][0]) && parseInt(value) <= parseInt(args[0][1]))
})

extend('between', {
  ...between,
  message: 'Поле "{_field_}" должно быть в диапозоне от {min} до {max}'
})

extend('digits', {
  ...digits,
  message: '{_field_} должно состоять из {length} цифр.'
})

extend('digits-length', {
  validate: (value, ...args) => {
    const regex = /\d+/g
    return value.match(regex).join('')?.length === +args[0]
  },
  message: (field, ...args) => `Поле ${field} должно содержать ${args[0][0]} цифр.`
})

extend('required', {
  ...required,
  message: 'Поле "{_field_}" обязательно'
})

extend('regex', {
  ...regex,
  message: 'Поле "{_field_}" не валидно'
})

extend('email', {
  ...email,
  message: 'Непохоже на email'
})

extend('confirmed', {
  ...confirmed,
  message: 'Пароли не совпадают'
})

extend('min_value', {
  ...minValue,
  message: 'Поле "{_field_}" должно быть не меньше {min}'
})

extend('length', {
  ...length,
  message: 'Поле {_field_} должно состоять из {length} знаков'
})

extend('between-length', {
  validate: (value, ...args) => {
    const [minLength, maxLength] = args[0]
    return value.length >= +minLength && value.length <= +maxLength
  },
  message: (field, ...args) => `Поле ${field} должно содержать от ${args[0][0]} до ${args[0][1]} знаков.`
})

extend('inn', {
  validate: (value) => {
    if (Number.isNaN(+value)) {
      return false
    } else {
      return value.length === 10 || value.length === 12
    }
  },
  message: (field, ...args) => `Поле ${field} должно содержать 10 или 12 цифр.`
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
