<template>
  <div class="success-connection">
    <h2 class="success-connection__heading">
      {{ heading }}
    </h2>

    <p class="success-connection__text">
      {{ text }}
    </p>

    <x-btn
      type="button"
      block
      @click="$emit('click')"
    >
      Замечательно
    </x-btn>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      default: 'Магазин успешно добавлен!'
    },
    text: {
      type: String,
      default: 'Магазин успешно подключен!'
    }
  }
}
</script>

<style lang="stylus" scoped>
  .success-connection
    max-width 350px
    margin 0 auto
    padding-top 50px
    text-align center

    &__image
      display block
      max-width 88px
      margin 0 auto 10px

    &__heading
      margin-bottom 10px
      font-weight $font-weights.bold
      font-size 24px

    &__text
      margin-bottom 20px
      font-size 14px
      color #cacad3
</style>
