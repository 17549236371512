import * as Types from './mutation-types'

export default {
  [Types.SET_SHOPS] (state, payload) {
    state.shops = payload
  },

  [Types.SET_SHOP] (state, payload) {
    const shop = state.shops.find(s => s.id === payload.id)

    if (shop) {
      Object.assign(shop, payload)
    }
  },

  [Types.UPDATE_SHOP] (state, { id, data }) {
    const shop = state.shops.find(s => s.id === id)

    if (shop) {
      Object.assign(shop, { ...shop, ...data })
    }
  }
}
