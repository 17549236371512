import Measures from '@/constants/measure-name'
import formatPrice from '@/utils/price-formatter'

const isNegativData = (name) => {
  if (name === 'drr' ||
    name === 'expired_feedbacks_count' ||
    name === 'products_with_errors' ||
    name === 'expired_fbs_orders' ||
    name === 'wb_avg_delivery_time' ||
    name === 'wb_commission' ||
    name === 'products_with_bad_description' ||
    name === 'products_in_quarantine' ||
    name === 'negative_index' ||
    name === 'questions_unanswered' ||
    name === 'products_0_stock_fbo' ||
    name === 'products_0_stock_fbs' ||
    name === 'products_1_stock_fbo' ||
    name === 'products_1_stock_fbs' ||
    name === 'products_2_5_stock_fbo' ||
    name === 'products_2_5_stock_fbs' ||
    name === 'disputed_orders' ||
    name === 'fbs_delivering_today' ||
    name === 'cancelled_orders_by_seller' ||
    name === 'cancelled_orders_rating' ||
    name === 'shipment_delay_rating' ||
    name === 'products_cancelled' ||
    name === 'products_returned' ||
    name === 'cancelled_orders_by_seller_rating' ||
    name === 'cancellations_money' ||
    name === 'cancellations_count' ||
    name === 'products_with_zero_stock_fbo' ||
    name === 'products_with_zero_stock_fbs') {
    return true
  }
  return false
}

const isNetralData = (name) => {
  const normalMetrics = name === 'paid_views_count' ||
    name === 'ozon_expense_template_adv_money' ||
    name === 'ozon_expense_search_adv_money' ||
    name === 'ozon_expense_brand_adv_money' ||
    name === 'advert_orders_share' ||
    name === 'wb_expense_search_adv_money' ||
    name === 'wb_expense_product_adv_money' ||
    name === 'wb_expense_catalog_adv_money' ||
    name === 'expense_adv_money'
  return normalMetrics
}

export default {
  formatSimpleData (point) {
    const prevValue = parseFloat(point.series.data[point.series.data.length - 2].y)
    if (point.point.measure === Measures.RUBLES) {
      return `<span style="color: ${point.y === '0' || isNetralData(point.key) ? '#7B7B85' : isNegativData(point.key) ? point.y > 0 ? '#FC1F49' : '#09C89F' : point.y > 0 ? '#09C89F' : '#FC1F49'}">
               ${parseFloat(point.y) > prevValue ? ' ▴ ' : parseFloat(point.y) < prevValue ? ' ▾ ' : ''}
            </span> ${formatPrice(point.y, { maximumFractionDigits: 0 })} ₽`
    }
    if (point.point.measure === Measures.PERCENT) {
      return `<span style="color: ${point.y === '0' || isNetralData(point.key) ? '#7B7B85' : isNegativData(point.key) ? point.y > 0 ? '#FC1F49' : '#09C89F' : point.y > 0 ? '#09C89F' : '#FC1F49'}">
               ${parseFloat(point.y) > prevValue ? ' ▴ ' : parseFloat(point.y) < prevValue ? ' ▾ ' : ''}
            </span> ${formatPrice(point.y, { maximumFractionDigits: 2 })} %`
    }
    return `<span style="color: ${point.y === '0' || isNetralData(point.key) ? '#7B7B85' : isNegativData(point.key) ? point.y > 0 ? '#FC1F49' : '#09C89F' : point.y > 0 ? '#09C89F' : '#FC1F49'}">
               ${parseFloat(point.y) > prevValue ? ' ▴ ' : parseFloat(point.y) < prevValue ? ' ▾ ' : ''}
            </span>${formatPrice(point.y, { maximumFractionDigits: 0 })}`
  },

  formatSumData (point) {
    if (point.colorIndex !== 0) {
      return null
    }
    if (point.series.data[0].measure === Measures.RUBLES) {
      return 'Σ ' + formatPrice(point.total, { maximumFractionDigits: 0 }) + ' ₽'
    }
    if (point.series.data[0].measure === Measures.PERCENT) {
      return 'Σ ' + point.total + '%'
    }
    return 'Σ ' + formatPrice(point.total, { maximumFractionDigits: 0 })
  }
}
