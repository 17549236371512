import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '@/views/Summary/Home.vue'
import HomeWithDashboards from '@/views/HomeWithDashboards.vue'
import {
  authorized,
  promoEnabled
} from './guards'
import { setApiToken, removeApiToken } from '@/utils/system'
import ROUTES from '@/router/ROUTES.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/onboarding', redirect: { name: 'onboarding.products.all' }
  },
  {
    path: '/',
    name: 'new_dashboards',
    component: HomeWithDashboards,
    meta: {
      metrics: {
        screen: 'mmp_summary',
        name: 'mmp_summary_view'
      }
    }
  },
  // {
  //   path: '/onboarding',
  //   name: 'onboarding',
  //   component: () => import('../views/Onboarding/WelcomeView.vue'),
  //   meta: {
  //     metrics: {
  //       screen: 'mmp_onboarding'
  //     }
  //   }
  // },
  {
    path: '/onboarding/products',
    name: 'onboarding.products',
    component: () => import('../views/Onboarding/Products/Index.vue'),
    meta: {
      metrics: {
        screen: 'mmp_onboarding'
      }
    },
    children: [{
      path: '/onboarding/products/all',
      name: 'onboarding.products.all',
      redirect: { name: 'new_dashboards' },
      component: () => import('../views/Onboarding/Products/Products.vue'),
      meta: {
        title: 'Онбординг',
        metrics: {
          screen: 'mmp_onboarding',
          name: 'mmp_onboarding_products_view'
        },
        metricsEvents: {
          addShop: {
            add: 'mmp_onboarding_addshop',
            selectMarketplace: 'mmp_onboarding_addshop_marketplace',
            access: 'mmp_onboarding_addshop_access',
            video: 'mmp_onboarding_addshop_video',
            text: 'mmp_onboarding_addshop_text'
          }
        }
      }
    }, {
      path: '/onboarding/products/start',
      name: 'onboarding.products.start',
      component: () => import('../views/Onboarding/Products/Products.vue'),
      meta: {
        metrics: {
          screen: 'mmp_onboarding'
        }
      }
    }, {
      path: '/onboarding/products/increase',
      name: 'onboarding.products.increase',
      component: () => import('../views/Onboarding/Products/Products.vue'),
      meta: {
        metrics: {
          screen: 'mmp_onboarding'
        }
      }
    }, {
      path: '/onboarding/products/scaling',
      name: 'onboarding.products.scaling',
      component: () => import('../views/Onboarding/Products/Products.vue'),
      meta: {
        metrics: {
          screen: 'mmp_onboarding'
        }
      }
    }]
  }, {
    path: ROUTES.DASHBOARDS.path,
    name: ROUTES.DASHBOARDS.name,
    component: () => import('../views/HomeWithDashboards.vue'),
    meta: {
      metrics: {
        screen: 'mmp_dashboards',
        name: 'mmp_dashboards_view'
      }
    }
  }, {
    path: '/new_dashboards/:marketplace/products',
    name: 'new_dashboards.products',
    component: () => import('../views/ProductsList.vue'),
    meta: {
      metrics: {
        screen: 'mmp_dashboards'
      }
    }
  }, {
    path: '/new_dashboards/wildberries_new/:id',
    name: 'new_dashboards.shop',
    component: () => import('../views/DashboardsWildberries.vue'),
    meta: {
      metrics: {
        screen: 'mmp_dashboards'
      }
    }
  }, {
    path: '/new_dashboards/ozon_new/:id',
    name: 'new_dashboards.shop',
    component: () => import('../views/DashboardsOzon.vue'),
    meta: {
      metrics: {
        screen: 'mmp_dashboards'
      }
    }
  }, {
    path: '/new_dashboards/:marketplace/:id',
    name: 'new_dashboards.shop',
    component: () => import('../views/DashboardsShop.vue'),
    meta: {
      metrics: {
        screen: 'mmp_dashboards'
      }
    }
  }, {
    path: '/new_dashboards/:marketplace/product/:id',
    name: 'new_dashboards.product',
    component: () => import('../views/DashboardsProduct.vue'),
    meta: {
      metrics: {
        screen: 'mmp_dashboards'
      }
    }
  }, {
    path: ROUTES.MARKETPLACES.path,
    name: ROUTES.MARKETPLACES.name,
    component: () => import(/* webpackChunkName: "marketplaces" */'../views/Marketplaces.vue'),
    meta: {
      metrics: {
        screen: 'mmp_shops',
        name: 'mmp_shops_view'
      },
      metricsEvents: {
        addShop: {
          add: 'mmp_shops_addshop',
          selectMarketplace: 'mmp_shops_addshop_marketplace',
          access: 'mmp_shops_addshop_access',
          video: 'mmp_shops_addshop_video',
          text: 'mmp_shops_addshop_text'
        }
      }
    }
  }, {
    path: ROUTES.PRODUCT.path,
    name: ROUTES.PRODUCT.name,
    component: () => import(/* webpackChunkName: "product" */'../views/Product/Index.vue'),
    children: [{
      path: ROUTES.PRODUCT_LIST.path,
      name: ROUTES.PRODUCT_LIST.name,
      component: () => import(/* webpackChunkName: "product" */'../views/Product/ProductList.vue'),
      meta: {
        title: 'Товары',
        metrics: {
          screen: 'mmp_productlist',
          name: 'mmp_productlist_view'
        }
      }
    }, {
      path: '/product/stock-prices-management',
      name: 'product.stock.prices',
      component: () => import(/* webpackChunkName: "productStockPrices" */'../views/Product/StockPrices.vue'),
      meta: {
        title: 'Управление ценами и остатками',
        metrics: {
          screen: 'mmp_stockhistory',
          name: 'mmp_stockhistory_view'
        }
      }
    }]
  }, {
    path: '/orders',
    name: 'orders',
    component: () => import(/* webpackChunkName: "orders" */'../views/Orders/Index.vue'),
    meta: {
      metrics: {
        screen: 'mmp_orders',
        name: 'mmp_orders_view'
      },
      metricsEvents: {
        orders: {
          openOrder: 'mmp_orders_click_order'
        }
      }
    }
  }, {
    path: '/order/:id',
    name: 'order',
    props: true,
    component: () => import(/* webpackChunkName: "orders" */'../views/Orders/OrderPreview.vue'),
    meta: {
      metrics: {
        screen: 'mmp_order'
      },
      redirectTo: { name: 'orders' }
    }
  }, {
    path: '/promotion',
    name: 'promotion',
    component: () => import(/* webpackChunkName: "promotion" */'../views/Promotion/Index.vue'),
    beforeEnter: promoEnabled,
    meta: {
      metrics: {
        screen: 'mmp_promotion',
        name: 'mmp_promotion_view'
      }
    }
  }, {
    path: '/promotion/:id',
    name: 'promotion.signup',
    component: () => import(/* webpackChunkName: "promotion" */'../views/Promotion/SignUp.vue'),
    beforeEnter: promoEnabled,
    meta: {
      redirectTo: { name: 'promotion' }
    }
  }, {
    path: '/exports-list',
    name: 'exports',
    component: () => import(/* webpackChunkName: "exports" */'../views/ExportList.vue'),
    meta: {
      metrics: {
        screen: 'mmp_exportslist',
        name: 'mmp_exportslist_view'
      }
    }
  }, {
    path: '/services',
    name: 'services',
    redirect: { name: 'tariffs' },
    component: () => import(/* webpackChunkName: "services" */'../views/Services.vue'),
    meta: {
      metrics: {
        screen: 'mmp_services',
        name: 'mmp_services_view'
      }
    }
  }, {
    path: ROUTES.TARIFFS.path,
    name: ROUTES.TARIFFS.name,
    component: () => import(/* webpackChunkName: "tariffs" */'../views/Tariffs/Tariffs.vue'),
    props: (route) => ({ unpaid: route.query.unpaid === 'true', modal: route.query.modal }),
    meta: {
      metrics: {
        screen: 'mmp_tariffs',
        name: 'mmp_tariffs_view'
      }
    }
  }, {
    path: '/mp-to-mp',
    name: 'mp-to-mp',
    component: () => import(/* webpackChunkName: "ozon-2-ali" */'../views/Ozon2Ali/Index'),
    redirect: {
      name: 'mp-to-mp.tasks'
    },
    children: [
      {
        path: 'tasks',
        name: 'mp-to-mp.tasks',
        component: () => import(/* webpackChunkName: "ozon-2-ali.tasks" */'../views/Ozon2Ali/Ozon2AliTasks.vue'),
        meta: {
          metrics: {
            product: 'MP2MP',
            screen: 'mp2mp_tasks',
            name: 'mp2mp_view'
          },
          metricsEvents: {
            addShop: {
              add: 'mp2mp_addshop',
              access: 'mp2mp_addshop_access',
              video: 'mp2mp_addshop_video',
              text: 'mp2mp_addshop_text'
            }
          }
        }
      },
      {
        path: 'export/:taskId',
        name: 'mp-to-mp.export',
        component: () => import(/* webpackChunkName: "ozon-2-ali.export" */'../views/Ozon2Ali/Ozon2AliExport.vue'),
        props: true,
        meta: {
          metrics: {
            product: 'MP2MP',
            screen: 'mp2mp_export'
          },
          redirectTo: { name: 'mp-to-mp.tasks' }
        }
      }
    ]
  }, {
    path: '/mp-2-mp',
    redirect: {
      name: 'mp-to-mp.tasks'
    }
  }, {
    path: '/mp2mp',
    redirect: {
      name: 'mp-to-mp.tasks'
    }
  }, {
    path: '/ozon-to-ali',
    redirect: {
      name: 'mp-to-mp.tasks'
    }
  }, {
    path: ROUTES.REPORTS.path,
    name: ROUTES.REPORTS.name,
    component: () => import(/* webpackChunkName: "reports" */'../views/Reports/Reports.vue'),
    meta: {
      heading: 'Отчеты',
      metrics: {
        screen: 'mmp_reports',
        name: 'mmp_reports_view'
      }
    }
  }, {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */'../views/Settings/Index.vue'),
    children: [{
      name: 'settings.finances',
      path: 'finances',
      component: () => import(/* webpackChunkName: "settings.finances" */'../views/Settings/Finances.vue'),
      meta: {
        heading: 'Финансы',
        metrics: {
          screen: 'mmp_settings',
          name: 'mmp_settings_finances'
        }
      },
      props: (route) => ({ tab: route.query.tab })
    }, {
      name: 'settings.documents',
      path: 'documents',
      component: () => import(/* webpackChunkName: "settings.documents" */'../views/Settings/Documents.vue'),
      meta: {
        heading: 'Документы',
        metrics: {
          screen: 'mmp_settings',
          name: 'mmp_settings_documents'
        }
      }
    }, {
      name: 'settings.help',
      path: 'help',
      component: () => import(/* webpackChunkName: "settings.help" */'../views/Settings/Help.vue'),
      meta: {
        heading: 'Помощь',
        metrics: {
          screen: 'mmp_settings',
          name: 'mmp_settings_help'
        }
      }
    }, {
      name: 'settings.profile',
      path: 'profile',
      component: () => import(/* webpackChunkName: "settings.profile" */'../views/Settings/Profile.vue'),
      meta: {
        heading: 'Персональные данные',
        metrics: {
          screen: 'mmp_settings',
          name: 'mmp_settings_profile'
        }
      }
    }, {
      name: 'settings.partnership',
      path: 'partnership',
      component: () => import(/* webpackChunkName: "settings.partnership" */'../views/Settings/Partnership.vue'),
      meta: {
        heading: 'Партнёрская программа',
        metrics: {
          screen: 'mmp_settings',
          name: 'mmp_settings_partnership'
        }
      }
    }, {
      path: 'permissions',
      component: () => import(/* webpackChunkName: "settings.permissions" */'../views/Settings/Permissions/Index.vue'),
      children: [{
        name: 'settings.permissions',
        path: '',
        component: () => import(/* webpackChunkName: "settings.permissions.manage" */'../views/Settings/Permissions/Manage.vue'),
        meta: {
          heading: 'Управление правами доступа',
          metrics: {
            screen: 'mmp_settings',
            name: 'mmp_settings_users'
          }
        }
      }, {
        name: 'settings.permissions.client',
        path: 'client/:id?',
        component: () => import(/* webpackChunkName: "settings.permissions.client" */'../views/Settings/Permissions/Client.vue'),
        meta: Vue.observable({
          heading: 'Добавление Клиента',
          back: { name: 'settings.permissions' },
          redirectTo: { name: 'settings.permissions' }
        })
      }, {
        name: 'settings.permissions.user',
        path: 'user/:id?',
        component: () => import(/* webpackChunkName: "settings.permissions.user" */'../views/Settings/Permissions/User.vue'),
        props: true,
        meta: Vue.observable({
          heading: 'Добавление Пользователя',
          back: { name: 'settings.permissions' },
          redirectTo: { name: 'settings.permissions' }
        })
      }, {
        name: 'settings.permissions.role',
        path: 'role/:id?',
        component: () => import(/* webpackChunkName: "settings.permissions.role" */'../views/Settings/Permissions/Role.vue'),
        props: true,
        meta: Vue.observable({
          heading: 'Добавление Роли',
          back: { name: 'settings.permissions' },
          redirectTo: { name: 'settings.permissions' }
        })
      }]
    }]
  }, {
    name: 'beta',
    path: '/beta-test',
    component: () => import(/* webpackChunkName: "beta-test" */'../views/BetaTest.vue'),
    meta: {
      metrics: {
        screen: 'mmp_beta-test'
      },
      metricsEvents: {
        addShop: {
          add: 'mmp_beta-test_addshop',
          selectMarketplace: 'mmp_beta-test_addshop_marketplace',
          access: 'mmp_beta-test_addshop_access',
          video: 'mmp_beta-test_addshop_video',
          text: 'mmp_beta-test_addshop_text'
        }
      }
    }
  }, {
    path: '*',
    name: '404',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const { xw_token: token } = to.query

  if (token) {
    removeApiToken()
    setApiToken(token)
    authorized(to, from, () => next({
      ...to,
      query: {
        ...to.query,
        cabinet_type: undefined,
        xw_token: undefined
      }
    }))
    return
  }

  authorized(to, from, next)
})

export default router
