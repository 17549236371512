<template>
  <modal
    :name="modalName"
    :max-width="540"
    :min-width="280"
    width="90%"
    height="auto"
    :scrollable="$currentDevice.isMobile"
    :adaptive="true"
    @before-open="onBeforeOpen"
  >
    <div class="modal-container add-marketplace">
      <template v-if="source">
        <p class="add-marketplace__text add-marketplace__subtitle">
          Добавьте магазин сейчас и получите<br> <span class="primary--text">7 дней ММР бесплатно!</span>
        </p>
        <x-alert :icon="false">
          <div>
            <div class="font-weight-semibold">
              Привязав магазин, вы сможете:
            </div>
            – узнать упущенную выручку и правильно формировать поставки<br>
            – найти плохо продающиеся товары и лидеров продаж<br>
            – сравнить продажи в категории с показателями конкурентов
          </div>
        </x-alert>
      </template>
      <p class="add-marketplace__text">
        Выберите в каком маркетплейсе ваш магазин
      </p>
      <ul class="add-marketplace__list">
        <li
          v-for="marketplace in availableMarketplaces"
          :key="marketplace.title"
          class="add-marketplace__item is-big"
          :class="marketplace.classList"
        >
          <x-btn
            type="button"
            ghost
            block
            outline
            :disabled="marketplace.isDisabled"
            color="gray"
            class="add-marketplace__action"
            :class="{ 'add-marketplace__action_lowheight': source }"
            :metrics="metrics"
            :metrics-payload="{ marketplace_code: marketplace.title }"
            @click="selectMarketplace(marketplace)"
          >
            <x-badge
              v-if="marketplace.isDisabled"
              class="disabled-button-badge"
            >
              СКОРО
            </x-badge>
            <svg-icon
              :name="`${marketplace.title}--full`"
              class="add-marketplace__icon"
            />
          </x-btn>
        </li>
      </ul>

      <template v-if="showAliMarketplaces">
        <p class="add-marketplace__text">
          Или выберите один из нишевых маркетплейсов на платформе AliExpress
        </p>

        <ul class="add-marketplace__list">
          <li
            v-for="marketplace in aliMarketplaces"
            :key="marketplace"
            class="add-marketplace__item"
          >
            <x-btn
              type="button"
              ghost
              block
              outline
              :disabled="marketplace.isDisabled"
              class="add-marketplace__action"
              @click="$modal.show('connect-modal', { formName: 'connect-form-ali' })"
            >
              <x-badge
                v-if="marketplace.isDisabled"
                class="disabled-button-badge"
              >
                СКОРО
              </x-badge>

              <svg-icon
                :name="marketplace"
                class="add-marketplace__icon"
              />
            </x-btn>
          </li>
        </ul>
      </template>

      <footer class="add-marketplace__footer">
        <x-btn
          color="gray"
          block
          @click="$modal.hide(modalName)"
        >
          Отменить
        </x-btn>
      </footer>

      <close-button
        @click="$modal.hide(modalName)"
      />
    </div>
  </modal>
</template>

<script>
import CloseButton from '@/components/Interface/CloseButton.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapActions } = createNamespacedHelpers('settings/permissions')

export default {
  components: {
    CloseButton
  },

  data () {
    return {
      modalName: 'add-marketplace-modal',
      source: '',
      availableMarketplaces: [{
        title: 'ali'
      }, {
        title: 'ozon'
      }, {
        title: 'wildberries'
      }, {
        title: 'beru',
        classList: 'is-big'
      }, {
        title: 'goods',
        classList: 'is-big black-and-white',
        isDisabled: true
      }],
      aliMarketplaces: [
        'big-home',
        'big-trendy',
        'big-stationery'
      ],
      showAliMarketplaces: false
    }
  },

  computed: {
    metrics () {
      return this.source === 'promo_onboarding'
        ? 'mmp_onboarding_intro_addshop_marketplace'
        : this.$route.meta?.metricsEvents?.addShop?.selectMarketplace
    }
  },

  methods: {
    ...mapActions(['getClientList']),

    onBeforeOpen (e) {
      this.source = e.params?.source
      this.getClientList()
    },

    selectMarketplace (marketplace) {
      this.$modal.show('connect-modal', { formName: `connect-form-${marketplace.title}`, source: this.source })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .add-marketplace
    padding 30px

    &__text
      margin 20px 0
      font-size: $font-sizes.headline-5
      font-weight: $font-weights.bold
      color: $colors.gray-darkest-2

    &__subtitle
      text-align center
      font-size: $font-sizes.headline-4
      line-height 24px
      margin-bottom 12px

    &__list
      margin-left -10px

      @media (min-width 768px)
        display flex
        flex-wrap wrap
        justify-content space-between

      @media (min-width 1024px)
        margin-top 10px
        justify-content center

      ~ .add-marketplace__text
          margin-top 10px
          margin-bottom 10px
          font-size 16px
          text-align center

          @media (min-width 768px)
            margin-top 20px
            margin-bottom 20px

    &__item
      display flex
      justify-content center
      align-items center
      margin-bottom 10px
      margin-left 10px

      @media (min-width 1024px)
        flex-basis 31%

      &.is-big
        flex-basis 47%

      &.black-and-white {
        filter: grayscale(1);
      }

    &__action
      height 58px

      @media (min-width 1024px)
        height 80px

    &__action_lowheight
      height 52px

    &__icon
      height 18px

      &.svg-icon--ali--full
        width 106px
        height 25px

      &.svg-icon--ozon--full
        width 93px

      &.svg-icon--wildberries--full
        width 118px
        height 33px

      &.svg-icon--beru--full
        width 110px
        height 20px

      &.svg-icon--goods--full
        width 107px
        height 27px

      &.svg-icon--big-home
        width 115px
        height 24px

      &.svg-icon--big-trendy
        width 128px
        height 17px

      &.svg-icon--big-stationery
        width 108px
        height 24px

    &__footer
      margin-top 20px

  .disabled-button-badge {
    position absolute
    top 4px
    right 4px
  }
</style>
