import { WS_STATE_NOT_CONNECTED } from '@/plugins/websocket/const'

export const wsState = {
  namespaced: true,
  state: {
    wsState: WS_STATE_NOT_CONNECTED
  },
  mutations: {
    'SET_WS_STATE': (state, newState) => {
      state.wsState = newState
    }
  },
  actions: {
    setState ({ commit }, newState) {
      commit('SET_WS_STATE', newState)
    }
  }
}
