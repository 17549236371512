import Profile from './profile'
import Marketplaces from './marketplaces'
import Dashboards from './dashboards'
import Promotions from './promotions'
import Products from './products'
import Orders from './orders'
import Exports from './exports'
import PricesAndStocks from './prices-stocks'
import UI from './ui'
import Settings from './settings'
import Report from './reports'

export default {
  Profile,
  Marketplaces,
  Dashboards,
  Promotions,
  Products,
  Orders,
  Exports,
  PricesAndStocks,
  UI,
  Settings,
  Report
}
