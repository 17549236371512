import moment from 'moment'

const UTC = 3

export default (date, format = 'DD.MM.YYYY', options = {}) => {
  if (!date) return '-'
  const {
    utc = UTC,
    locale = 'ru'
  } = options

  return moment(new Date(date)).utcOffset(utc).locale(locale).format(format)
}

export const getDiff = (start, end) => moment.duration(new Date(end) - new Date(start))

export const getDate = (date) => moment(date).utcOffset(UTC).format()

export const isBefore = (date1, date2) => {
  return moment(date1).isBefore(date2)
}

export const isAfter = (date1, date2) => {
  return moment(date1).isAfter(date2)
}
