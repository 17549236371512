import { defaultHttpClient } from '@/api/default/defaultHttpClient'
import ApiRoutes from '@/api/default/routes'

const {
  GetAll,
  GetUpdatableProducts,
  DownloadProducts,
  DownloadErrorProducts,
  DownloadTurnoverReport,
  UploadProducts,
  UpdateProducts,
  UpdateProductsList,
  GetProduct,
  GetProductOffers,
  GetMarketplacesList,
  GetProductBrands,
  GetProductCategories,
  GetProductStatuses,
  UpdatePriceAndStocksFromMarketplace,
  UpdatePriceAndStocksOnMarketplace
} = ApiRoutes.Products

export const getProducts = ({ params }) => defaultHttpClient.post(GetAll, params)

export const updateProductsList = (params) => defaultHttpClient.post(UpdateProductsList, params)

export const getUpdatableProducts = ({ id, ...params }) => defaultHttpClient.get(GetUpdatableProducts(id), { params })

export const downloadProducts = (params) => defaultHttpClient.post(DownloadProducts, params)

export const downloadErrorProducts = (id) => defaultHttpClient.get(DownloadErrorProducts(id))

export const downloadTurnoverReport = (params) => defaultHttpClient.post(DownloadTurnoverReport, params)

export const uploadProducts = (params) => defaultHttpClient.post(UploadProducts, params, { headers: { 'content-type': 'multipart/form-data' } })

export const updateProducts = (id) => defaultHttpClient.get(UpdateProducts(id))

export const getProduct = (id) => defaultHttpClient.get(GetProduct(id))

export const getProductOffers = ({ id, ...params }) => defaultHttpClient.get(GetProductOffers(id), { params })

export const getMarketplacesList = () => defaultHttpClient.get(GetMarketplacesList)

export const getProductBrands = (params) => defaultHttpClient.get(GetProductBrands, { params })

export const getProductCategories = (params) => defaultHttpClient.get(GetProductCategories, { params })

export const getProductStatuses = (params) => defaultHttpClient.get(GetProductStatuses, { params })

export const updatePriceAndStocksFromMarketplace = (payload) => defaultHttpClient.post(UpdatePriceAndStocksFromMarketplace, payload)

export const updatePriceAndStocksOnMarketplace = (payload) => defaultHttpClient.post(UpdatePriceAndStocksOnMarketplace, payload)
