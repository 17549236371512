import { init as initGtm, isInitialized as isGTMInitialized } from './gtm'
import { init as initYM, isInitialized as isYMInitialized } from './yandex'
import { init as initCarrot, isInitialized as isCarrotInitialized } from './carrotquest'

export function initMetrics () {
  initGtm()
  initYM()
  initCarrot()
}

export function isAllMetricsInitialized () {
  return isGTMInitialized() && isYMInitialized() && isCarrotInitialized()
}
