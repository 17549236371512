import marketplaceCodes from '@/constants/marketplace-codes'

export default Object.freeze({
  [marketplaceCodes.ALI]: [
    '#ef3d21',
    '#be260e',
    '#ee2f11',
    '#f04c32',
    '#f15941',
    '#f36d58',
    '#f58270',
    '#f69788',
    '#f8aca0',
    '#fac1b8',
    '#fcd5cf'
  ],
  [marketplaceCodes.OZON]: [
    '#0069ff',
    '#003f99',
    '#0049b2',
    '#0054cc',
    '#005ee5',
    '#1a78ff',
    '#3387ff',
    '#4c96ff',
    '#66a5ff',
    '#b3d2ff',
    '#cce1ff'
  ],
  [marketplaceCodes.SBER]: [
    '#319c30',
    '#37b036',
    '#50c94f',
    '#64cf63',
    '#77d576',
    '#8adb8a',
    '#9ee19d',
    '#b1e7b1',
    '#c5edc4',
    '#d8f3d8'
  ],
  [marketplaceCodes.WILDBERRIES]: [
    '#ee55a5',
    '#d11577',
    '#e81784',
    '#ea2e91',
    '#ec469d',
    '#ef5da9',
    '#f174b5',
    '#f38bc2',
    '#f6a2ce',
    '#f8b9da',
    '#fad1e6'
  ],
  [marketplaceCodes.YANDEX]: [
    '#ffde43',
    '#d6b100',
    '#f0c600',
    '#ffd200',
    '#ffdb33',
    '#ffe04c',
    '#ffe466',
    '#ffe980',
    '#ffed99',
    '#fff2b3',
    '#fff6cc'
  ]
})
