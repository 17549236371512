<template>
  <modal
    :name="modalName"
    width="1020"
    height="auto"
    :scrollable="true"
    @before-close="$emit('hide')"
  >
    <div class="modal-container">
      <div class="content-columns content-columns_content_datepicker">
        <div class="column column_layout_a">
          <radio-buttons-group
            :value="current"
            :items="radiobuttons"
            @change="onChangePeriod"
          />
        </div>

        <div class="column column_layout_b">
          <calendar
            ref="calendar"
            v-model="date"
            :columns="2"
            is-range
            is-expanded
            show-weeknumbers
            :max-date="maxDate"
            :min-date="minDate"
            :locale="{ masks: { weekdays: 'WW' } }"
            @click.native="onCalendarClick"
          />

          <slot
            name="actions"
            :date="date"
          >
            <div class="modal-container__action">
              <reset-button
                v-if="hasResetButton"
                block
                class="modal-container__reset"
                @click="resetDate"
              />
              <x-btn
                block
                class="modal-container__choose"
                @click="onSelectClick"
              >
                Выбрать
              </x-btn>
            </div>
          </slot>
        </div>
      </div>

      <close-button
        class="modal-container__close"
        @click="$modal.hide(modalName)"
      />
    </div>
  </modal>
</template>

<script>
import moment from 'moment'
import CalendarPeriods from '@/constants/calendar-periods'
import calendarQuickFilters from '@/constants/calendar-quick-links'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import CloseButton from '@/components/Interface/CloseButton.vue'
import RadioButtonsGroup from '@/components/Interface/RadioButtonsGroup.vue'
import ResetButton from '@/components/Interface/ResetButton.vue'

const today = new Date()

export default {
  components: {
    Calendar: DatePicker,
    CloseButton,
    RadioButtonsGroup,
    ResetButton
  },

  props: {
    identity: {
      type: String,
      default: ''
    },
    hasResetButton: Boolean,
    maxDate: {
      type: Date,
      default: undefined
    },
    minDate: {
      type: Date,
      default: undefined
    },
    defaultPeriod: {
      type: String,
      default: CalendarPeriods.TODAY
    },
    value: {
      type: Object,
      default: () => ({
        start: today,
        end: today
      })
    }
  },

  data () {
    return {
      date: this.value,
      radiobuttons: calendarQuickFilters,
      current: this.defaultPeriod
    }
  },

  watch: {
    value (newValue) {
      this.date = newValue
    },

    defaultPeriod (value) {
      this.current = value
    },

    current (period) {
      switch (period) {
        case CalendarPeriods.YESTERDAY:
          this.date = {
            start: moment().subtract(1, 'days').toDate(),
            end: moment().subtract(1, 'days').toDate()
          }
          break

        case CalendarPeriods.TODAY:
          this.date = {
            start: new Date(),
            end: new Date()
          }
          break

        case CalendarPeriods.CURRENT_WEEK:
          this.date = {
            start: moment().startOf('isoWeek').toDate(),
            end: this.maxDate || moment().endOf('isoWeek').toDate()
          }
          break

        case CalendarPeriods.LAST_WEEK:
          this.date = {
            start: moment().subtract(1, 'week').startOf('isoWeek').toDate(),
            end: moment().subtract(1, 'week').endOf('isoWeek').toDate()
          }
          break

        case CalendarPeriods.CURRENT_MONTH:
          this.date = {
            start: moment().startOf('month').toDate(),
            end: new Date()
          }
          break

        case CalendarPeriods.LAST_MONTH:
          this.date = {
            start: moment().subtract(1, 'month').startOf('month').toDate(),
            end: moment().subtract(1, 'month').endOf('month').toDate()
          }
          break

        case CalendarPeriods.CURRENT_QUARTER:
          this.date = {
            start: moment().startOf('quarter').toDate(),
            end: new Date()
          }
          break

        case CalendarPeriods.LAST_QUARTER:
          this.date = {
            start: moment().subtract(1, 'Q').startOf('quarter').toDate(),
            end: moment().subtract(1, 'Q').endOf('quarter').toDate()
          }
          break

        case CalendarPeriods.CURRENT_YEAR:
          this.date = {
            start: moment().startOf('year').toDate(),
            end: new Date()
          }
          break

        case CalendarPeriods.LAST_YEAR:
          this.date = {
            start: moment().subtract(1, 'year').startOf('year').toDate(),
            end: moment().subtract(1, 'year').endOf('year').toDate()
          }
          break
      }

      this.calendar.move(this.date.start)
    }
  },

  computed: {
    calendar () {
      return this.$refs.calendar
    },

    modalName () {
      if (this.identity) {
        return `${this.identity}-datepicker-modal`
      }
      return 'datepicker-modal'
    }
  },

  methods: {
    onSelectClick () {
      this.$emit('change', this.date)
      this.$modal.hide(this.modalName)
    },

    onCalendarClick () {
      this.current = CalendarPeriods.OTHER
    },

    resetDate () {
      this.$emit('change', {})
      this.$emit('hide')
    },

    onChangePeriod (period) {
      this.current = period
      this.$emit('change-default-period', this.current)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-container
    padding 40px 20px

    &__action
      margin-top 20px
      display flex

    &__reset,
    &__choose
      flex-shrink 1

    &__close
      top 10px

    >>>.radiolist
      .radiolist__item
        margin-bottom 11px

        &:nth-child(odd)
          margin-top 36px

        &:first-child
          margin-top 0

  .content-columns_content_datepicker
    display flex

    .column
      &_layout_a
        flex-basis 150px
        flex-shrink 0
        margin-right 50px
        padding-top 10px

      &_layout_b
        flex-grow 1

        >>> .vc-container
          font-family $font-family
          border none

          .vc-pane-layout
            display flex
            justify-content space-between
            margin-left -60px

          .vc-pane
            flex-grow 1
            margin-left 60px

          .vc-weeks
            row-gap 20px

          .vc-header
            margin-bottom 50px
            padding-top 0

          .vc-arrows-container
            padding-top 0
            padding-bottom 0

          .vc-title
            font-size 20px
            text-transform capitalize

          .vc-weekday
            font-weight normal
            text-transform capitalize
            color #7b7b85

          .vc-weeknumber-content
            margin-top 0
            margin-right 25px
            font-size 14px
            font-style normal
            background linear-gradient(0deg, rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), linear-gradient(0deg, #5346e4, #5346e4), #3e66fb

            &:empty
              background transparent

          .vc-day-content
            color #3c3c47
            border-radius 4px

            &.is-disabled
              color #7b7b85

          .vc-highlight
            background-color #5b40ff!important
            border-radius 4px!important
            border none!important

            &.vc-highlight-base-middle
            &.vc-highlight-base-start
            &.vc-highlight-base-end
              background-color #d6cfff!important
              border-radius 0!important
</style>
