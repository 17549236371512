import { defaultHttpClient } from '@/api/default/defaultHttpClient'
import ApiRoutes from '@/api/default/routes'

const {
  GetOrders,
  GetOrder,
  GetMarketplaces,
  GetXwayOrderStatuses,
  DownloadOrderList,
  SettingMarkSelfPurchase,
  RemovingMarkSelfPurchase,
  SettingMarkSelfPurchaseByFileOzon
} = ApiRoutes.Orders

export const getOrders = params => defaultHttpClient.get(GetOrders, { params })

export const getOrder = id => defaultHttpClient.get(GetOrder(id))

export const getMatketplacesList = () => defaultHttpClient.get(GetMarketplaces)

export const getXwayOrderStatuses = (params) => defaultHttpClient.get(GetXwayOrderStatuses, { params })

export const downloadOrderList = (params) => defaultHttpClient.get(DownloadOrderList, { params })

export const setMarkSelfPurchase = (params) => defaultHttpClient.post(SettingMarkSelfPurchase, params)

export const removeMarkSelfPurchase = (params) => defaultHttpClient.post(RemovingMarkSelfPurchase, params)

export const setMarkSelfPurchaseByFile = (params) => defaultHttpClient.post(SettingMarkSelfPurchaseByFileOzon, params, { headers: { 'content-type': 'multipart/form-data' } })
