import moment from 'moment'
import formatDate from '@/utils/date-formatter'

export default {
  formatData (that, data, dataProp) {
    const result = {
      dates: [],
      values: []
    }
    if (data && data.length) {
      that[dataProp] = data.reduce((result, currentItem) => {
        const newResult = result
        newResult.values.push({
          ...currentItem.shop,
          data: currentItem[dataProp].map(item => item.value),
          dd: currentItem[dataProp].map(item => {
            const dd = {
              value: (item.value - item.value_dtd).toFixed(2),
              percent: item.value_dtd ? (((item.value - item.value_dtd) / item.value_dtd) * 100).toFixed(2) : item.value ? 100 : 0
            }
            return dd
          }),
          nn: currentItem[dataProp].map(item => {
            const nn = {
              value: (item.value - item.value_wtw).toFixed(2),
              percent: item.value_wtw ? (((item.value - item.value_wtw) / item.value_wtw) * 100).toFixed(2) : item.value ? 100 : 0
            }
            return nn
          })
        })
        newResult.dates = newResult.dates.length
          ? newResult.dates.map(item => item)
          : currentItem[dataProp].map(item => formatDate(item.date))
        return newResult
      }, result)
    } else {
      const start = new Date(that.period.start)
      const end = new Date(that.period.end)
      let daysLag = Math.ceil(Math.abs(end.getTime() - start.getTime()) / (1000 * 3600 * 24))

      result.values = [{ data: [] }]

      if (dataProp === 'is_shop_active' && daysLag > 30) {
        daysLag = 30
      }
      for (let i = 0; i <= daysLag; i++) {
        result.dates.unshift(formatDate(moment(end).subtract(i, 'days').toDate(), 'DD.MM.YYYY'))
        result.values[0].data.push(null)
      }

      that[dataProp] = result
    }
  }
}
