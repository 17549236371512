import { API_BASE, defaultHttpClient } from '@/api/default/defaultHttpClient'
import ApiRoutes from '@/api/default/routes'

const {
  GetUserBalance,
  GetUserTariff,
  GetUserTransactions,
  GetTransactionsTypes,
  GetTransactionsStatuses,
  GetPaymentAccounts,
  UpdatePaymentAccount,
  DownloadPaymentDoc,
  GetTariffsList,
  GetCurrentTariff,
  ChangeTariff,
  CalculateTariffPrice
} = ApiRoutes.Settings

export const getUserBalance = () => defaultHttpClient.get(GetUserBalance)

/* deprecated. not used */
export const getUserTariff = () => defaultHttpClient.get(GetUserTariff)

export const getUserTransactions = (params) => defaultHttpClient.get(GetUserTransactions, { params })

export const getTransactionsTypes = () => defaultHttpClient.get(GetTransactionsTypes)

export const getTransactionsStatuses = () => defaultHttpClient.get(GetTransactionsStatuses)

export const getPaymentAccounts = (params) => defaultHttpClient.get(GetPaymentAccounts, { params })

export const getPaymentAccountByClient = (params) => defaultHttpClient.get(`${API_BASE}/payment-accounts/`, { params })

export const createPaymentAccount = (payload) => defaultHttpClient.post(GetPaymentAccounts, payload)

export const updatePaymentAccount = (id, payload) => defaultHttpClient.put(`${API_BASE}/payment-accounts/${id}/`, payload)

export const deletePaymentAccount = (id) => defaultHttpClient.delete(UpdatePaymentAccount(id))

export const downloadPaymentDoc = (payload) => defaultHttpClient.post(DownloadPaymentDoc, payload, { responseType: 'blob' })

export const getTariffsList = (terms) => defaultHttpClient.post(GetTariffsList, { terms })

export const getCurrentTariff = () => defaultHttpClient.get(GetCurrentTariff)

export const changeTariff = (payload) => defaultHttpClient.post(ChangeTariff, payload)

export const calculateTariffPrice = (tariffIds, terms) => defaultHttpClient.post(CalculateTariffPrice, { tariff_ids: tariffIds, terms })
