<template>
  <modal
    :name="modalName"
    :max-width="680"
    :min-width="280"
    width="90%"
    height="auto"
    :adaptive="true"
  >
    <div class="modal-container shops-error">
      <x-icon
        class="shops-error__icon"
        name="no-data"
        color="gray"
      />
      <p class="shops-error__text">
        Нет подключенных магазинов
      </p>
      <p class="shops-error__subtitle">
        Перейдите в «Мои магазины» и включите сбор данных для аналитической отчётности в настройках магазина
      </p>
      <footer class="shops-error__footer">
        <x-btn
          class="shops-error__footer-button"
          color="gray"
          @click="$modal.hide(modalName)"
        >
          Назад
        </x-btn>
        <x-btn
          class="shops-error__footer-button"
          @click="$router.push('/marketplaces')"
        >
          К моим магазинам
        </x-btn>
      </footer>
      <close-button
        @click="$modal.hide(modalName)"
      />
    </div>
  </modal>
</template>

<script>
import CloseButton from '@/components/Interface/CloseButton.vue'

export default {
  components: {
    CloseButton
  },

  data () {
    return {
      modalName: 'connect-shops-error-modal'
    }
  }
}
</script>

<style lang="stylus" scoped>
  .shops-error
    padding 30px
    text-align center

    &__icon
      width 72px
      height 72px

    &__text
      margin 12px 0
      font-size: $font-sizes.headline-5
      font-weight: $font-weights.bold
      color: $colors.gray-darkest-2

    &__subtitle
      font-size: 14px
      line-height 24px
      margin-bottom 12px

    &__footer
      display flex
      margin-top 20px
      &-button
        width 50%
</style>
