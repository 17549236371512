<template>
  <x-layout
    v-bind="layoutProps"
    :order-recommendations="orderRecommendations"
    :repeat-payment="repeatPayment"
    :show-all-products-link="!isManager"
    :show-add-shop-link="!isManager"
    :show-tariff="!isManager"
    :show-nav-global="!isManager"
    @logout="onLogoutClick"
  >
    <slot />
    <template
      v-if="!isManager"
      #header-append
    >
      <demo-toggle class="mx-3" />
    </template>
  </x-layout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import DemoToggle from '@/components/Common/DemoToggle'
import { defaultHttpClient } from '@/api/default/defaultHttpClient'
import { initCardPayment } from '@/api/default/modules/payment'

const { mapActions, mapState } = createNamespacedHelpers('ui')
const {
  mapState: mapProfileState,
  mapGetters: mapProfileGetters,
  mapActions: mapProfileActions
} = createNamespacedHelpers('profile')

const { mapActions: mapSettingsFinancesActions } = createNamespacedHelpers('settings/finances')

export default {
  components: {
    DemoToggle
  },
  computed: {
    ...mapState(['menuItems']),
    ...mapProfileState([
      'isProfileLoading',
      'user'
    ]),
    ...mapProfileGetters(['currentTariff', 'futureTariff', 'nextBill']),

    layoutProps () {
      const tariff = this.currentTariff && {
        name: this.currentTariff.tariff.name,
        expiresAt: this.currentTariff.expires_at
      }

      return {
        userName: this.user?.name,
        userEmail: this.user?.email,
        tariff,
        loading: !this.user && this.isProfileLoading,
        menuItems: this.menuItems,
        showTariffExpiredBanner: this.user?.has_due_bill,
        showRecommendationsBanner: false
      }
    },

    isManager () {
      return this.user.service_model && this.user.service_model === 'xway-service'
    }
  },

  created () {
    this.getMenuItems()
  },

  methods: {
    ...mapActions(['getMenuItems']),
    ...mapSettingsFinancesActions(['changeTariff']),
    ...mapProfileActions(['logout']),

    onLogoutClick () {
      this.logout()
      this.$router.push({ name: 'sign.in' })
    },

    async repeatPayment () {
      const res = await initCardPayment({
        amount: this.nextBill.amount,
        tariff_id: this.futureTariff.id,
        term: this.futureTariff?.term,
        is_recurrent: this.futureTariff?.is_recurrent
      })
      const paymentUrl = res?.data?.data?.payment_url
      if (paymentUrl) {
        window.open(paymentUrl)
      }
    },

    orderRecommendations () {
      return defaultHttpClient.post('/seller_cabinet/v1/sme_click_order', {
        page: 'Топбар'
      })
    }
  }
}
</script>
