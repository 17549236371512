import marketplaceCodes from '@/constants/marketplace-codes'

export function prioritizeByMP (shops) {
  return shops.sort((a, b) => {
    if (Number(a.marketplace_code === marketplaceCodes.OZON) > Number(b.marketplace_code === marketplaceCodes.OZON)) return -1
    if (Number(a.marketplace_code === marketplaceCodes.OZON) < Number(b.marketplace_code === marketplaceCodes.OZON)) return 1
    return 0
  }).sort((a, b) => {
    if (Number(a.marketplace_code === marketplaceCodes.WILDBERRIES) > Number(b.marketplace_code === marketplaceCodes.WILDBERRIES)) return -1
    if (Number(a.marketplace_code === marketplaceCodes.WILDBERRIES) < Number(b.marketplace_code === marketplaceCodes.WILDBERRIES)) return 1
    return 0
  }).sort((a, b) => {
    if (Number(a.is_paywalled_in_lkp) > Number(b.is_paywalled_in_lkp)) return 1
    if (Number(a.is_paywalled_in_lkp) < Number(b.is_paywalled_in_lkp)) return -1
    return 0
  })
}
