import finances from './finances'
import permissions from './permissions'

export default {
  namespaced: true,
  modules: {
    finances,
    permissions
  }
}
