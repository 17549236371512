export default {
  createStoresStatisticSeries (showBy, point, measure, heading, legend) {
    const newPoint = {
      id: point.id,
      name: point.name,
      color: '',
      colorIndex: null,
      data: point.data.map((value, index) => {
        const filteringPoints = point.data.filter(_ => _ !== null)
        const newData = {
          y: value,
          name: heading,
          measure: measure,
          show_day: showBy.show_day,
          show_week: showBy.show_week,
          description: JSON.stringify(point),
          marker: {
            enabled: index === filteringPoints.length - 1,
            symbol: 'circle'
          }
          /* dataLabels: {
            enabled: legend && index === filteringPoints.length - 1
          } */
        }
        return newData
      })
    }
    return newPoint
  },

  createMetricStatisticSeries (showBy, point, measure, heading, checkedMetricsIds, freeColorList, index) {
    const newPoint = {
      name: point.name,
      yAxis: index,
      checkedMetricsIds,
      freeColorList,
      selected: checkedMetricsIds.includes(point.name),
      visible: checkedMetricsIds.includes(point.name),
      color: point.color,
      colorIndex: point.colorIndex,
      data: point.data.map((value, index) => {
        const filteringPoints = point.data.filter(_ => _ !== null)
        const newData = {
          y: Number.parseFloat(value),
          name: heading,
          measure: measure,
          show_day: showBy.show_day,
          show_week: showBy.show_week,
          description: JSON.stringify(point),
          marker: {
            enabled: index === filteringPoints.length - 1,
            symbol: 'circle'
          }
        }
        return newData
      })
    }
    return newPoint
  }
}
