import { render, staticRenderFns } from "./NewDashboardsFilters.vue?vue&type=template&id=936bb0c8&scoped=true&"
import script from "./NewDashboardsFilters.vue?vue&type=script&lang=js&"
export * from "./NewDashboardsFilters.vue?vue&type=script&lang=js&"
import style0 from "./NewDashboardsFilters.vue?vue&type=style&index=0&id=936bb0c8&lang=stylus&scoped=true&"
import style1 from "./NewDashboardsFilters.vue?vue&type=style&index=1&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "936bb0c8",
  null
  
)

export default component.exports