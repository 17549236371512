import { render, staticRenderFns } from "./ConnectModal.vue?vue&type=template&id=6aff349a&scoped=true&"
import script from "./ConnectModal.vue?vue&type=script&lang=js&"
export * from "./ConnectModal.vue?vue&type=script&lang=js&"
import style0 from "./ConnectModal.vue?vue&type=style&index=0&id=6aff349a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aff349a",
  null
  
)

export default component.exports