<template>
  <div>
    <ul
      :class="radioListClasses"
    >
      <li
        v-for="item in items"
        :key="`${name}_${item.value}`"
        class="radiolist__item"
      >
        <x-radio
          v-model="checked"
          :name="name"
          :value="item.value"
          @change="$emit('change', checked)"
        >
          <template slot>
            <span v-html="item.label" />
          </template>
        </x-radio>
      </li>
    </ul>

    <form-error
      v-if="errors"
      :text="errors[0]"
    />
  </div>
</template>

<script>
import FormError from './FormError.vue'

export default {
  model: {
    prop: 'checked',
    event: 'change'
  },

  components: {
    FormError
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'radio'
    },
    value: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    errors: {
      type: Array,
      default: undefined
    },
    horizontal: Boolean
  },

  data () {
    return {
      checked: this.value
    }
  },

  watch: {
    value () {
      this.checked = this.value
    }
  },

  computed: {
    radioListClasses () {
      return [
        'radiolist',
        {
          'radiolist_viewtype_horizontal': this.horizontal
        }
      ]
    }
  }
}
</script>

<style lang="stylus" scoped>
  .radiolist
    margin 0
    padding 0
    list-style none

    &_viewtype_horizontal
      display flex

      .radiolist__item
        margin-right 20px

    &__item
      margin-bottom 7px

      &:last-child
        margin-bottom 0
</style>
