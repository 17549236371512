import {
  addProductsToExportList,
  deleteProductsFromExportList,
  addFilteredProductsToExportList,
  deleteFilteredProductsFromExportList,
  createImportTask,
  getExportTaskData,
  fetchProductList,
  getExportPrice,
  startExport,
  getCountByType
} from '@/api/default/modules/ozon2ali'

import { cloneDeep } from 'lodash'

import STATUS from '@/constants/status_api'
import PRODUCT_STATUS from '@/constants/status_mp-to-mp'

import router from '@/router'
import replaceRouteParams from '@/utils/replaceRouteParams'

import toastNotification from '@/utils/toast-notification'

const defaultTaskState = {
  fromShop: null,
  toShop: null,
  currentStep: 1,
  totalSteps: 4,

  productList: [],
  productListStatus: STATUS.INITIAL,

  taskId: '',
  taskData: {},

  priceDetails: {},

  exportActionStatus: STATUS.INITIAL,

  countByType: {
    [PRODUCT_STATUS.REJECTED]: 0,
    [PRODUCT_STATUS.DRAFT]: 0,
    [PRODUCT_STATUS.FOR_EXPORT]: 0,
    [PRODUCT_STATUS.DONE]: 0,
    [PRODUCT_STATUS.FAILURE]: 0,
    [PRODUCT_STATUS.IN_PROGRESS]: 0
  }
}

export default {
  namespaced: true,
  state: cloneDeep(defaultTaskState),

  getters: {
    productListDraftIds (state) {
      const draftProducts = state.productList.filter(product => product.status === PRODUCT_STATUS.DRAFT && !product.validation_error?.length, [])
      return draftProducts.map(product => product.id)
    },

    productListForExportIds (state) {
      const forExportProducts = state.productList.filter(product => product.status === PRODUCT_STATUS.FOR_EXPORT)
      return forExportProducts.map(product => product.id)
    },

    taskLoadingStatus (state) {
      return {
        isProductsLoaded: state.taskData.is_products_loaded,
        importedAmount: state.taskData.linked_import_list?.imported_amount,
        productsAmount: state.taskData.linked_import_list?.products_amount,
        errorCount: state.taskData.validation_error_count,
        /* @type IMPORT_STATUS */
        importStatus: state.taskData.linked_import_list?.status,
        /* @type EXPORT_STATUS */
        exportStatus: state.taskData.status
      }
    },

    productCategories (state) {
      return state.taskData?.categories
    },

    metricsPayload (state) {
      return {
        shop_from: state.fromShop?.id,
        shop_to: state.toShop?.id,
        marketplace_id: state.fromShop?.marketplace_code, // marketplace_id_from
        marketplace_id_to: state.toShop?.marketplace_code
      }
    },

    payMetricsPayload (state, getters) {
      return {
        ...getters.metricsPayload,
        ozon2ali_export_list_id: state.taskData.id
      }
    }
  },

  mutations: {
    SET_FROM_SHOP: (state, value) => {
      state.fromShop = value
    },
    SET_TO_SHOP: (state, value) => {
      state.toShop = value
    },

    SET_PRODUCT_LIST: (state, productList) => {
      state.productList = productList
    },

    SET_PRODUCT_LIST_STATUS: (state, status) => {
      state.productListStatus = status
    },

    SET_CURRENT_STEP: (state, value) => {
      state.currentStep = value
    },

    SET_TASK_DATA: (state, data) => {
      state.taskData = data
    },

    SET_PRICE_DETAILS: (state, data) => {
      state.priceDetails = data
    },

    SET_TASK_ID: (state, id) => {
      state.taskId = id
    },

    SET_EXPORT_ACTION_STATUS: (state, status) => {
      state.exportActionStatus = status
    },

    /* status: draft | for_export | error | inprogress | done */
    MARK_PRODUCTS_IN_LIST: (state, { productIds, status }) => {
      productIds.forEach(productId => {
        const product = state.productList.find(el => el.id === productId)
        product.status = status
      })
    },

    REMOVE_ITEMS_FROM_PRODUCT_LIST_BI_IDS: (state, productIds = []) => {
      state.productList = state.productList.filter(product => {
        return !productIds.includes(product.id)
      })
    },

    SET_COUNT_BY_TYPE: (state, data) => {
      Object.keys(state.countByType).map(type => {
        if (data[type]) {
          state.countByType[type] = data[type]
        } else {
          state.countByType[type] = 0
        }
      })
    },

    RESET_TASK_STATE: (state) => {
      Object.keys(state).forEach(key => {
        state[key] = defaultTaskState[key]
      })
    },

    REPLACE_PRODUCT: (state, product) => {
      state.productList = state.productList.map(el => {
        if (el.id === product.id) {
          return product
        }
        return el
      })
    }
  },
  actions: {
    selectAllProductsOnPage ({ state, commit, getters }) {
      addProductsToExportList(state.taskId, getters.productListDraftIds)
      commit('MARK_PRODUCTS_IN_LIST', { productIds: getters.productListDraftIds, status: PRODUCT_STATUS.FOR_EXPORT })
    },

    selectAllImportedProductsByFilter ({ state, commit, getters }, filter = {}) {
      addFilteredProductsToExportList(state.taskId, { ...filter })
      commit('MARK_PRODUCTS_IN_LIST', { productIds: getters.productListDraftIds, status: PRODUCT_STATUS.FOR_EXPORT })
    },

    async removeAllImportedProductsByFilter ({ state, commit, getters }, { filter, removeLocalItem }) {
      if (removeLocalItem) {
        commit('REMOVE_ITEMS_FROM_PRODUCT_LIST_BI_IDS', getters.productListForExportIds)
      } else {
        commit('MARK_PRODUCTS_IN_LIST', { productIds: getters.productListForExportIds, status: PRODUCT_STATUS.DRAFT })
      }

      try {
        await deleteFilteredProductsFromExportList(state.taskId, { ...filter })
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
        /* todo добавить элементы обратно в массив */
        toastNotification.error('не удалось удалить элементы')
      }
    },

    async removeExportItems ({ commit, state }, { productIds, removeLocalItem }) {
      if (removeLocalItem) {
        commit('REMOVE_ITEMS_FROM_PRODUCT_LIST_BI_IDS', productIds)
      } else {
        commit('MARK_PRODUCTS_IN_LIST', { productIds: productIds, status: PRODUCT_STATUS.DRAFT })
      }
      try {
        await deleteProductsFromExportList(state.taskId, productIds)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
        /* todo добавить элементы обратно в массив */
        toastNotification.error('не удалось удалить элемент')
      }
    },

    setFromShop ({ commit }, value) {
      commit('SET_FROM_SHOP', value)
    },

    setToShop ({ commit }, value) {
      commit('SET_TO_SHOP', value)
    },

    resetCurrentStep ({ commit }) {
      commit('RESET_TASK_STATE')
    },

    async setItemsToExport ({ state, commit }, productIds) {
      addProductsToExportList(state.taskId, productIds)

      commit('MARK_PRODUCTS_IN_LIST', { productIds, status: PRODUCT_STATUS.FOR_EXPORT })
    },

    async fetchProductListAction ({ state, commit }, params) {
      if (!state.taskId) return

      commit('SET_PRODUCT_LIST_STATUS', STATUS.LOADING)

      try {
        const response = await fetchProductList(state.taskId, { ...params })

        commit('SET_PRODUCT_LIST', response.data.results)
        commit('SET_PRODUCT_LIST_STATUS', STATUS.SUCCESS)

        return response.data.count
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
        commit('SET_PRODUCT_LIST_STATUS', STATUS.ERROR)
      }
    },

    async fetchPriceDetails ({ state, commit }, params) {
      try {
        const { data } = await getExportPrice(state.taskId, params)
        commit('SET_PRICE_DETAILS', data)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },

    async startExportAction ({ state, commit }, params) {
      commit('SET_EXPORT_ACTION_STATUS', STATUS.LOADING)
      try {
        await startExport(state.taskId, params)
        commit('SET_EXPORT_ACTION_STATUS', STATUS.SUCCESS)
      } catch (e) {
        commit('SET_EXPORT_ACTION_STATUS', STATUS.ERROR)
        toastNotification.error(e.response?.data?.error)
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },

    async setCurrentStep ({ commit, dispatch }, value) {
      commit('SET_CURRENT_STEP', value)
    },

    async createTask ({ state, commit }) {
      try {
        const { data: { id } } = await createImportTask(state.fromShop.pk, state.toShop.pk)
        commit('SET_TASK_ID', id)
        replaceRouteParams(router, { taskId: state.taskId })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },

    async fetchTaskData ({ state, commit }) {
      try {
        const { data } = await getExportTaskData(state.taskId)
        commit('SET_TASK_DATA', data)
        commit('SET_TO_SHOP', data.shop)
        commit('SET_FROM_SHOP', data.shop_from)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },

    async getExportCountByType ({ state, commit }) {
      try {
        const { data: message } = await getCountByType(state.taskId)
        commit('SET_COUNT_BY_TYPE', message.message)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }
  }
}
