import { defaultHttpClient } from '@/api/default/defaultHttpClient'
import ApiRoutes from '@/api/default/routes'

const {
  Me,
  CreateClient,
  SendPhoneVerificationCode,
  VerifyPhone,
  SendServiceForm,
  SetUserReferral,
  DemoToken
} = ApiRoutes.Profile

export const getProfile = () => defaultHttpClient.get(Me)

export const createClient = payload => defaultHttpClient.post(CreateClient, payload)

export const sendPhoneVerificationCode = payload => defaultHttpClient.post(SendPhoneVerificationCode, payload)

export const verifyPhone = payload => defaultHttpClient.post(VerifyPhone, payload)

export const sendServiceForm = payload => defaultHttpClient.post(SendServiceForm, payload)

export const setUserReferral = payload => defaultHttpClient.put(SetUserReferral, payload)

export const getDemoToken = () => defaultHttpClient.get(DemoToken)
