<template>
  <component
    :is="tag"
    class="heading"
    :class="levelClass"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'h1'
    },
    level: {
      type: String,
      default: ''
    }
  },

  computed: {
    levelClass () {
      if (!this.level) return null

      return `heading_level_${this.level}`
    }
  }
}
</script>

<style lang="stylus" scoped>
  .heading
    margin-bottom 23px
    font-weight $font-weights.bold
    font-size 24px
    line-height 1.23
    color #474852

    @media (min-width 1200px)
      font-size 30px

    &_level_2
      margin-bottom 30px
      font-size 24px

    &_level_3
      margin-bottom 10px
      font-size 20px

    &_level_4
      font-size 16px
</style>
