function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"vObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('the-form',{staticClass:"form_content_connect-marketplace",on:{"submit":function($event){return handleSubmit(_vm.onSubmit)}}},[_c('fieldset',{staticClass:"form-fields-group"},[_c('legend',{staticClass:"form-fields-group__title"},[_c('span',{staticClass:"hidden-on-mobile"},[_vm._v("Добавление магазина на")]),_vm._v(" Wildberries ")]),_c('ul',{staticClass:"form-fields-list"},[_c('li',{staticClass:"form-fields-list__item form-fields-list__item_content_desc"},[_vm._v(" Чтобы добавить ваш магазин на Wildberries, заполните поля формы и нажмите на кнопку “Подключить магазин” ")]),_c('li',{staticClass:"form-fields-list__item"},[_c('x-form-item',{attrs:{"rules":"required","name":"Наименование","label":"Введите наименование магазина"},scopedSlots:_vm._u([{key:"default",fn:function(validationData){return [_c('x-input',_vm._b({model:{value:(_vm.form.params.name),callback:function ($$v) {_vm.$set(_vm.form.params, "name", $$v)},expression:"form.params.name"}},'x-input',validationData,false))]}}],null,true)})],1),_c('li',{staticClass:"form-fields-list__item"},[_c('x-form-item',{attrs:{"rules":"required|apiWbKey","name":"Токен для работы с API","vid":"wildberries_token","label":"Введите токен \"Маркетплейс\", \"Контент\" и \"Аналитика\""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var rest = objectWithoutProperties( ref, ["classes"] );
var validationData = rest;
return [_c('x-input',_vm._b({model:{value:(_vm.form.params.wildberries_token),callback:function ($$v) {_vm.$set(_vm.form.params, "wildberries_token", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.params.wildberries_token"}},'x-input',Object.assign({}, _vm.$attrs, validationData),false))]}}],null,true)})],1),_c('li',{staticClass:"form-fields-list__item"},[_c('x-form-item',{attrs:{"rules":"required|apiWbKey64","name":"Ключ для работы с API статистики x64","vid":"wildberries_stats_token","label":"Токен \"Статистика\", \"Вопросы и отзывы\", \"Цены и скидки\""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var rest = objectWithoutProperties( ref, ["classes"] );
var validationData = rest;
return [_c('x-input',_vm._b({model:{value:(_vm.form.params.wildberries_stats_token),callback:function ($$v) {_vm.$set(_vm.form.params, "wildberries_stats_token", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.params.wildberries_stats_token"}},'x-input',Object.assign({}, _vm.$attrs, validationData),false))]}}],null,true)})],1),_c('li',{staticClass:"form-fields-list__item"},[_c('x-form-item',{attrs:{"rules":"required|apiWbKeyAdv","name":"Токен для работы с API ADV","vid":"wildberries_adv_token","label":"Введите токен \"Продвижение\""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var rest = objectWithoutProperties( ref, ["classes"] );
var validationData = rest;
return [_c('x-input',_vm._b({model:{value:(_vm.form.params.wildberries_adv_token),callback:function ($$v) {_vm.$set(_vm.form.params, "wildberries_adv_token", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.params.wildberries_adv_token"}},'x-input',Object.assign({}, _vm.$attrs, validationData),false))]}}],null,true)})],1),_c('li',{staticClass:"form-fields-list__item"},[_c('client-select',{attrs:{"rules":"required","value":_vm.selectedClient,"options":_vm.clientList,"clearable":false},on:{"input":_vm.onClientSelect}})],1),_c('li',{staticClass:"form-fields-list__item form-fields-list__item_content_submit"},[_c('x-btn',{attrs:{"type":"submit","block":"","loading":_vm.isLoading,"disabled":invalid}},[_vm._v(" Подключить магазин ")])],1)])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }