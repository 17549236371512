const Base = '/seller_cabinet/v1'
const ProductsBase = `${Base}/products`

export default {
  GetAll: `${ProductsBase}`,
  GetUpdatableProducts: id => `${ProductsBase}/update-lists/${id}/items`,
  GetMarketplacesList: `${ProductsBase}/marketplaces`,
  DownloadProducts: `${ProductsBase}/feed`,
  DownloadErrorProducts: id => `${ProductsBase}/update-lists/${id}/download-error-offers`,
  DownloadTurnoverReport: `${Base}/reports/turnover`,
  UploadProducts: `${ProductsBase}/upload_prices_and_stocks/`,
  UpdateProducts: id => `${ProductsBase}/update-lists/${id}/process`,
  UpdateProductsList: `${ProductsBase}/import`,
  GetProduct: id => `${ProductsBase}/${id}`,
  GetProductOffers: id => `${ProductsBase}/${id}/offers`,
  GetProductBrands: `${ProductsBase}/brands`,
  GetProductCategories: `${ProductsBase}/categories`,
  GetProductStatuses: `${ProductsBase}/statuses`,
  UpdatePriceAndStocksFromMarketplace: `${ProductsBase}/update-price-and-stocks-from-marketplace/`,
  UpdatePriceAndStocksOnMarketplace: `${ProductsBase}/update-price-and-stocks-on-marketplace/`
}
