export default {
  products: [],
  updatableProducts: [],
  updatableProductsErrorsCount: 0,
  productTabs: [],
  currentProduct: {},
  productOffers: [],
  productBrands: [],
  productCategories: [],
  productStatuses: []
}
