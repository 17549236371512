<template>
  <v-popover
    :popover-class="popoverClass()"
    :placement="placement"
    :trigger="trigger"
    :popper-options="popperOptions"
    boundaries-element="window"
  >
    <slot>
      <svg-icon
        v-if="icon"
        :name="icon"
        class="tooltip-primary__icon"
      />
    </slot>

    <template #popover>
      <div class="tooltip-primary__content">
        <slot name="popover" />
      </div>
    </template>
  </v-popover>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    trigger: {
      type: String,
      default: 'hover'
    },
    placement: {
      type: String,
      default: 'top-start'
    },
    flip: Boolean,
    noArrow: Boolean,
    arrowCenter: Boolean
  },

  data () {
    return {
      popperOptions: {
        modifiers: {
          flip: {
            enabled: this.flip
          }
        }
      }
    }
  },

  methods: {
    popoverClass () {
      const classes = []

      if (this.$scopedSlots.popover) {
        classes.push('tooltip-primary')
      }

      if (this.noArrow) {
        classes.push('tooltip-primary_no-arrow')
      }

      if (this.arrowCenter) {
        classes.push('tooltip-primary_arrow-center')
      }

      return classes
    }
  }
}
</script>

<style lang="stylus" scoped>
  .tooltip-primary

    &__icon
      width 16px
      height 16px

    &__content
      max-width 260px
</style>
