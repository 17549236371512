import * as Types from './mutation-types'

export default {
  [Types.SET_ORDERS] (state, payload) {
    state.orders = payload
  },

  [Types.SET_ORDERS_COUNT] (state, payload) {
    state.ordersCount = payload
  },

  [Types.SET_ORDERS_TOTAL_AMOUNT] (state, payload) {
    state.ordersAmount = payload
  },

  [Types.SET_ORDER] (state, payload) {
    state.order = payload
  },

  [Types.SET_ORDERS_BY_MARKETPLACE] (state, payload) {
    state.ordersTabs = payload
  },

  [Types.SET_XWAY_ORDER_STATUSES] (state, payload) {
    state.xwayOrderStatuses = payload
  }
}
