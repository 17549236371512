<template>
  <x-form-item
    name="Магазин"
    label="Магазин"
    label-size="s"
  >
    <x-select
      v-model="shopsValue"
      v-bind="$attrs"
      track-by="id"
      label="label"
      :options="computedShops"
      :searchable="false"
      multiple
      :loading="isLoading"
      :option-prepend-icon="getShopIcon"
      placeholder="Магазин"
      class="shop-filter"
      @close="() => $emit('change:shop', shopsValue)"
    />
  </x-form-item>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapState } = createNamespacedHelpers('marketplaces')
const { mapState: mapProfileState } = createNamespacedHelpers('profile')

const mappedIcons = {
  beru: 'yandex-market',
  goods: 'sber-mega-market',
  ali: 'aliexpress'
}

export default {
  inheritAttrs: false,

  props: {
    shop: {
      type: Array,
      default: () => []
    },
    params: {
      type: Object,
      default: undefined
    }
  },

  data () {
    return {
      isLoading: false,
      shopsValue: []
    }
  },

  watch: {
    shopsValue (value) {
      if (!value.length) {
        this.$emit('change:shop', value)
      }
    }
  },

  computed: {
    ...mapProfileState(['user']),
    ...mapState(['shops']),

    computedShops () {
      const shopsOptions = this.shops.map(m => ({
        id: m.id,
        label: m.shop.name,
        marketplace_code: m.marketplace_code,
        $isDisabled: false
      }))

      return shopsOptions
    }
  },

  beforeDestroy () {
    this.clearShopList()
  },

  mounted () {
    this.fetchShops()
  },

  methods: {
    ...mapActions(['getShopListCombo', 'clearShopList']),

    getShopIcon (option) {
      if (option.id === 'ALL') return 'bag'
      if (mappedIcons[option.marketplace_code]) {
        return mappedIcons[option.marketplace_code]
      }
      return this.shops.find(s => s.id === option.id)?.marketplace_code
    },

    async fetchShops () {
      if (this.shops.length) {
        this.shopsValue = JSON.parse(localStorage.getItem(`${this.user.id}_shops`))
        this.$emit('change:shop', this.shopsValue)
        return
      }
      try {
        this.isLoading = true

        await this.getShopListCombo(this.params)

        const storage = localStorage.getItem(`${this.user.id}_shops`)
        if (storage) {
          this.shopsValue = JSON.parse(storage)
        } else {
          this.shopsValue = this.shops.slice(0, 5)
          this.shopsValue = this.shopsValue.map(shop => ({
            ...shop,
            label: shop.name
          }))
        }
        this.$emit('change:shop', this.shopsValue)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
