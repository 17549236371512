import * as Types from './mutation-types'

export default {
  [Types.SET_PROMOTIONS] (state, payload) {
    state.promotions = payload
  },

  [Types.SET_PROMOTION_DETAILS] (state, payload) {
    state.details = payload
  },

  [Types.SET_PROMOTION] (state, payload) {
    state.promotion = payload
  },

  [Types.SET_AVAILABLE_PRODUCTS] (state, payload) {
    state.availableProducts = payload
  },

  [Types.SET_AVAILABLE_PRODUCTS_COUNT] (state, payload) {
    state.availableProductsCount = payload
  },

  [Types.SET_PROMOTION_PRODUCTS] (state, payload) {
    state.products = payload.map(p => ({
      ...p,
      selected: []
    }))
  },

  [Types.SET_PROMOTION_PRODUCTS_WITH_ERRORS] (state, payload) {
    state.productsWithErrors = payload.map(p => ({
      ...p,
      selected: []
    }))
  },

  [Types.SET_PROMOTION_ERRORS_COUNT] (state, payload) {
    state.productsWithErrorsCount = payload
  },

  [Types.SET_PROMOTION_PRODUCT] (state, payload) {
    const product = state.products.find(p => p.id === payload.id)

    if (product) {
      Object.assign(product, payload)
    }
  },

  [Types.SET_PROMOTION_OFFER] (state, payload) {
    const offer = state.products.map(p => p.offers).find(o => o.id === payload.id)

    if (offer) {
      Object.assign(offer, payload)
    }
  },

  [Types.SET_PROMOTION_PRODUCTS_COUNT] (state, payload) {
    state.productsCount = payload
  },

  [Types.SET_PROMOTION_ERRORS] (state, payload) {
    state.errors = payload
  },

  [Types.SET_REGISTRATION_ERRORS] (state, payload) {
    state.productRegistrationErrors = payload
  }
}
