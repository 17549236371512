import * as Types from './mutation-types'
import * as OrdersApi from '@/api/default/modules/orders'
import marketplaceNameFormatter from '@/utils/marketplace-formatter'

export default {
  async getOrders ({ commit }, payload) {
    const { data: { data, pagination, total } } = await OrdersApi.getOrders(payload)

    commit(Types.SET_ORDERS, data)
    commit(Types.SET_ORDERS_COUNT, pagination?.count)
    commit(Types.SET_ORDERS_TOTAL_AMOUNT, total)
  },

  async getMatketplacesList ({ commit }, payload) {
    let { data: { data } } = await OrdersApi.getMatketplacesList()
    data = data.map(m => ({
      ...m,
      title: marketplaceNameFormatter(m.name),
      path: m.id,
      counter: m.orders_count
    }))

    data.unshift({
      title: 'Все',
      id: 'ALL',
      path: 'ALL',
      counter: data.reduce((acc, m) => {
        acc += m.counter
        return acc
      }, 0)
    })
    commit(Types.SET_ORDERS_BY_MARKETPLACE, data)
  },

  async getXwayOrderStatuses ({ commit }, payload) {
    const { data: { data } } = await OrdersApi.getXwayOrderStatuses(payload)
    commit(Types.SET_XWAY_ORDER_STATUSES, data)
  },

  async getOrder ({ commit }, id) {
    try {
      const { data: { data } } = await OrdersApi.getOrder(id)
      commit(Types.SET_ORDER, data)
    } catch (err) {
      /* eslint-disable no-console */
      console.log(err)
      commit(Types.SET_ORDER, {})
    }
  },

  async downloadOrderList (context, payload) {
    const { data: { isSuccess } } = await OrdersApi.downloadOrderList(payload)
    return isSuccess
  },

  async setMarkSelfPurchase (context, payload) {
    try {
      await OrdersApi.setMarkSelfPurchase(payload)
    } catch (err) {
      console.log(err)
    }
  },

  async removeMarkSelfPurchase (context, payload) {
    try {
      await OrdersApi.removeMarkSelfPurchase(payload)
    } catch (err) {
      console.log(err)
    }
  },

  async setMarkSelfPurchaseByFile (context, payload) {
    try {
      await OrdersApi.setMarkSelfPurchaseByFile(payload)
    } catch (err) {
      console.log(err)
    }
  }
}
