import CabinetTypes from '@/constants/cabinet-types'

export default {
  isPromoUser (state) {
    return state.user?.cabinet_type === CabinetTypes.PROMO
  },

  isCustomTariffConfigured (state) {
    return state.user?.is_custom_tariff_configured
  },

  hasAliAuthCompleted (state) {
    return state.user?.is_enable_ali_access_data
  },

  isPromoEnabled (state) {
    if (!state?.user) return
    return !state.user.hide_lkp_promos
  },

  isUserAccessedToSection: (state, getters) => ({ section, shopId }) => {
    const permissionStoreId = `seller_cabinet.${section}`
    return state.user.permissions[permissionStoreId].includes(shopId) || getters.isUserAdmin
  },

  isUserAdmin (state) {
    return state.user.is_partner_admin
  },

  isDemoAccount (state) {
    return !!state.user?.is_demo_account
  },

  isXwayEmployee (state) {
    return !!state.user?.is_xway_employee
  },

  permissionKeys (state) {
    return state.user?.active_subscription?.permission_keys || []
  },

  currentTariff (state) {
    return state.user?.active_subscription
  },

  futureTariff (state) {
    return state.user?.future_tariff
  },

  nextBill (state) {
    return state.user?.next_bill || {}
  }
}
