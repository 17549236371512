import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import ui from './modules/ui'
import profile from './modules/profile'
import marketplaces from './modules/marketplaces'
import dashboards from './modules/dashboards'
import promotions from './modules/promotions'
import products from './modules/products'
import orders from './modules/orders'
import exports from './modules/exports'
import stocks from './modules/stocks'
import settings from './modules/settings'
import { wsState } from '@/store/modules/wsState'
import { ozon2ali } from '@/store/modules/ozon2ali'
import { tracking } from '@/store/modules/tracking'
import { reports } from '@/store/modules/reports'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    ui,
    profile,
    marketplaces,
    dashboards,
    promotions,
    products,
    orders,
    exports,
    stocks,
    settings,
    wsState,
    ozon2ali,
    tracking,
    reports
  },
  plugins: [createPersistedState({
    paths: ['profile']
  })]
})
