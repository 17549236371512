import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/plugins/vee-validate'
import '@/plugins/vue-js-modal'
import '@/plugins/v-tooltip'
import '@/plugins/vue-toastification'
import '@/plugins/v-mask'
import '@/plugins/vue-clipboard'
import '@/plugins/ui'
import User from '@/plugins/user'
import Default from '@/layouts/Default'
import SvgIcon from '@/components/Common/SvgIcon.vue'
import { isMobile } from '@/utils/deviceDetect'
import { webSocketPlugin } from '@/plugins/websocket'
import { notifyPlugin } from '@/plugins/notify'
import { XMetricsPlugin } from '@xway-team/metrics'
import { applyTheme, detectThemeByQuery, detectThemeByCookie, getCompanyDetails } from '@xway-team/theme-detector'
import { setCompanyDetails } from '@/plugins/companyDetails'
import { setCurrentThemeName } from '@/plugins/currentTheme'

const themeName = detectThemeByQuery() || detectThemeByCookie()

if (themeName) {
  setCurrentThemeName(themeName)
  applyTheme(themeName)
  setCompanyDetails(getCompanyDetails(themeName))
}

Vue.config.productionTip = false

Vue.prototype.$currentDevice = { isMobile }

Vue.component('default-layout', Default)
Vue.component('svg-icon', SvgIcon)

Vue.use(User)
Vue.use(webSocketPlugin)
Vue.use(notifyPlugin)

Vue.use(XMetricsPlugin, {
  product: 'MMP',
  router,
  useProd: process.env.VUE_APP_DEPLOYMENT_ENV === 'production'
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
