import { defaultHttpClient } from '@/api/default/defaultHttpClient'
import ApiRoutes from '@/api/default/routes'

const {
  GetAll,
  GetDetails,
  EnableAutopromotion,
  GetPromotion,
  StartPromoRegistration,
  GetProductTemplate,
  UploadProductTemplate,
  GetAvailableProducts,
  UpdateAvailableProducts,
  GetPromotionProducts,
  DeletePromotionProducts,
  GetPromotionProductsErrors,
  SavePromotionProduct,
  SavePromotionOffer,
  ProductsRegistration
} = ApiRoutes.Promotions

export const getPromotions = ({ params }) => defaultHttpClient.get(GetAll, { params })

export const getDetails = id => defaultHttpClient.get(GetDetails(id))

export const enableAutopromotion = () => defaultHttpClient.post(EnableAutopromotion)

export const getPromotion = id => defaultHttpClient.get(GetPromotion(id))

export const startPromoRegistration = id => defaultHttpClient.post(StartPromoRegistration(id))

export const getProductTemplate = (id) => defaultHttpClient.get(GetProductTemplate(id), { responseType: 'blob' })

export const uploadProductTemplate = (id, data) => {
  return defaultHttpClient.post(UploadProductTemplate(id), data, { headers: { 'content-type': 'multipart/form-data' } })
}

export const getAvailableProducts = ({ id, params }) => defaultHttpClient.get(GetAvailableProducts(id), { params })

export const updateAvailableProducts = ({ id, ...params }) => defaultHttpClient.post(UpdateAvailableProducts(id), params)

export const getPromotionProducts = ({ id, params }) => defaultHttpClient.get(GetPromotionProducts(id), { params })

export const deleteProducts = ({ promoId, ...data }) => defaultHttpClient.delete(DeletePromotionProducts(promoId), { data })

export const getPromotionProductsErrors = id => defaultHttpClient.get(GetPromotionProductsErrors(id))

export const savePromotionProduct = ({ promoId, id, ...options }) => defaultHttpClient.patch(SavePromotionProduct({ promoId, id }), options)

export const savePromotionOffer = ({ promoId, id, ...options }) => defaultHttpClient.patch(SavePromotionOffer({ promoId, id }), options)

export const registerProductsOnPromo = id => defaultHttpClient.post(ProductsRegistration(id))
