export const ErrorCode = {
  UNKNOWN: 'UNKNOWN',
  BASE_ERROR_CODE: 'BASE_ERROR_CODE',
  ITEM_NOT_FOUND: 'ITEM_NOT_FOUND',
  ITEM_IS_BLOCKED: 'ITEM_IS_BLOCKED',
  BUSINESS_LOGIC: 'BUSINESS_LOGIC',
  DATABASE_ERROR: 'DATABASE_ERROR',
  PERMISSION_DENIED: 'PERMISSION_DENIED',
  SERVER_ERROR: 'SERVER_ERROR',
  CONNECTION_ERROR: 'CONNECTION_ERROR',
  NOT_VALID_DATA: 'NOT_VALID_DATA',
  UNAUTHORIZED: 'UNAUTHORIZED',
  DUPLICATE: 'DUPLICATE',
  EMPTY_HEADER: 'EMPTY_HEADER',
  INVALID_HEADER: 'INVALID_HEADER',
  NOT_PRESENT_ARTICLE: 'NOT_PRESENT_ARTICLE',
  TARIFF_EXPIRED: 'TARIFF_EXPIRED',
  MP_API_KEY_ERROR: 'MP_API_KEY_ERROR',
  MARKETPLACE_ERROR: 'MARKETPLACE_ERROR',
  MARKETPLACE_TIMEOUT_ERROR: 'MARKETPLACE_TIMEOUT_ERROR',
  SHOP_MARKETPLACE_ERROR: 'SHOP_MARKETPLACE_ERROR'
}

export const ErrorType = {
  UNEXPECTED: 0,
  WORKS: 1,
  NETWORK: 2,
  NOT_FOUND: 3
}
