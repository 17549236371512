import * as Types from './mutation-types'

export default {
  [Types.SET_PRODUCTS] (state, payload) {
    state.products = payload
  },
  [Types.SET_UPDATABLE_PRODUCTS] (state, payload) {
    state.updatableProducts = payload
  },
  [Types.SET_UPDATABLE_PRODUCTS_ERRORS_COUNT] (state, payload) {
    state.updatableProductsErrorsCount = payload
  },
  [Types.SET_CURRENT_PRODUCT] (state, payload) {
    state.currentProduct = payload
  },
  [Types.SET_PRODUCT_OFFERS] (state, payload) {
    state.productOffers = payload
  },
  [Types.SET_PRODUCTS_BY_MARKETPLACE] (state, payload) {
    state.productTabs = payload
  },
  [Types.SET_PRODUCT_BRANDS] (state, payload) {
    state.productBrands = payload
  },
  [Types.SET_PRODUCT_CATEGORIES] (state, payload) {
    state.productCategories = payload
  },
  [Types.SET_PRODUCT_STATUSES] (state, payload) {
    state.productStatuses = payload
  }
}
