import { FinancesApi } from '@/api/default/modules/settings'
import * as Types from './mutation-types'
import downloadFile from '@/utils/download-file'

export default {
  async getUserBalance ({ commit }) {
    const { data: { data } } = await FinancesApi.getUserBalance()
    commit(Types.SET_USER_BALANCE, data)
    return data
  },

  async getFinanceInfo ({ dispatch }) {
    const [balanceInfo, tariffInfo] = await Promise.all([
      dispatch('getUserBalance')
    ])

    return {
      balanceInfo,
      tariffInfo
    }
  },

  async getUserTransactions ({ commit }, payload) {
    const { data } = await FinancesApi.getUserTransactions(payload)
    commit(Types.SET_TRANSACTIONS, data.data)
    return data.pagination?.count
  },

  async getTransactionsTypes ({ commit }) {
    const { data: { data } } = await FinancesApi.getTransactionsTypes()
    commit(Types.SET_TRANSACTION_TYPES, data)
    return data
  },

  async getTransactionsStatuses ({ commit }) {
    const { data: { data } } = await FinancesApi.getTransactionsStatuses()
    commit(Types.SET_TRANSACTION_STATUSES, data)
    return data
  },

  async getPaymentAccounts ({ commit }, payload) {
    const { data } = await FinancesApi.getPaymentAccounts(payload)
    commit(Types.SET_PAYMENT_ACCOUNTS, data.data)
    return data.pagination?.count
  },

  createPaymentAccount ({ commit }, payload) {
    return FinancesApi.createPaymentAccount(payload)
  },

  deletePaymentAccount ({ commit }, payload) {
    return FinancesApi.deletePaymentAccount(payload)
  },

  async downloadPaymentDoc ({ commit }, payload) {
    const { data: url } = await FinancesApi.downloadPaymentDoc(payload)
    if (!url) return
    downloadFile({
      url,
      fileName: 'Платежный документ',
      type: 'application/pdf'
    })
  },

  async getTariffsList ({ commit }, terms) {
    const { data: { data } } = await FinancesApi.getTariffsList(terms)
    commit(Types.SET_TARIFFS_LIST, data.tariffs)
    commit(Types.SET_TARIFFS_PRICE_LIST, data.tariff_options)
  },

  changeTariff ({ commit }, payload) {
    return FinancesApi.changeTariff(payload)
  },

  async calculateTariffPrice ({ commit }, { tariffIds, terms }) {
    const { data: { data } } = await FinancesApi.calculateTariffPrice(tariffIds, terms)
    return data
  }
}
