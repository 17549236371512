<template>
  <div class="app">
    <component :is="layout">
      <router-view />
    </component>

    <manager-info-modal />
  </div>
</template>

<script>
import ManagerInfoModal from '@/components/Modals/ManagerInfoModal.vue'
import { referralKey } from '@/constants/settings'
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions } = createNamespacedHelpers('profile')

const defaultLayout = 'default'

export default {
  name: 'App',

  components: {
    ManagerInfoModal
  },

  watch: {
    '$user.data.isLogged': {
      handler (logged) {
        if (!logged) return

        const { [referralKey]: referral } = this.$route.query
        if (referral) {
          this.setUserReferral({ referral })
        }

        if (!this.user.phone && process.env.NODE_ENV !== 'production') {
          // this.$modal.show('manager-info-modal')
        }
      },
      immediate: true
    }
  },

  computed: {
    ...mapState(['user']),

    layout () {
      return `${this.$route.meta.layout || defaultLayout}-layout`
    }
  },

  methods: {
    ...mapActions(['setUserReferral'])
  }
}
</script>

<style lang="stylus" src="./styles/main.styl" />
