import * as Types from './mutation-types'
import { PermissionsApi } from '@/api/default/modules/settings'

export default {
  async getClientList ({ commit }) {
    const { data: { results } } = await PermissionsApi.getClientList()
    commit(Types.SET_CLIENT_LIST, results)
  },

  async getClient ({ commit }, payload) {
    const { data } = await PermissionsApi.getClient(payload)
    return data
  },

  updateClient ({ commit }, payload) {
    return PermissionsApi.updateClient(payload)
  },

  createClient ({ commit }, payload) {
    return PermissionsApi.createClient(payload)
  },

  deleteClient ({ commit }, payload) {
    return PermissionsApi.deleteClient(payload)
  },

  async addClientsUser ({ commit }, payload) {
    const { data } = await PermissionsApi.addClientsUser(payload)
    if (data) {
      commit(Types.UPDATE_CLIENT, data)
    }
  },

  async removeClientsUser ({ commit }, payload) {
    const { data } = await PermissionsApi.removeClientsUser(payload)
    if (data) {
      commit(Types.UPDATE_CLIENT, data)
    }
  },

  async getRolesList ({ commit }) {
    const { data: { results } } = await PermissionsApi.getRolesList()
    commit(Types.SET_ROLES_LIST, results)
  },

  async getRole ({ commit }, payload) {
    const { data } = await PermissionsApi.getRole(payload)
    return data
  },

  updateRole ({ commit }, payload) {
    return PermissionsApi.updateRole(payload)
  },

  createRole ({ commit }, payload) {
    return PermissionsApi.createRole(payload)
  },

  deleteRole ({ commit }, payload) {
    return PermissionsApi.deleteRole(payload)
  },

  async getPermissionsList ({ commit }) {
    const { data: { results } } = await PermissionsApi.getPermissionsList()
    commit(Types.SET_PERMISSIONS_LIST, results)
  },

  async getUsersList ({ commit }) {
    const { data: { results } } = await PermissionsApi.getUsersList()
    commit(Types.SET_USERS_LIST, results)
  },

  async getUser ({ commit }, payload) {
    const { data } = await PermissionsApi.getUser(payload)
    return data
  },

  updateUser ({ commit }, payload) {
    return PermissionsApi.updateUser(payload)
  },

  createUser ({ commit }, payload) {
    return PermissionsApi.createUser(payload)
  },

  deleteUser ({ commit }, payload) {
    return PermissionsApi.deleteUser(payload)
  }
}
