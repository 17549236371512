const Base = 'seller_cabinet/v1'
const SettingsBase = `${Base}/partner-settings`
const DemoBase = `${Base}/demo-account`

export default {
  Me: `${Base}/me/`,
  CreateClient: `${Base}/profile/client`,
  SendPhoneVerificationCode: `${Base}/phone_verification/`,
  VerifyPhone: `${Base}/phone_verification/verify`,
  SendServiceForm: `${Base}/bitrix/form/service-order`,
  SetUserReferral: `${SettingsBase}/referral`,
  DemoToken: `${DemoBase}/token/`
}
