<template>
  <x-card class="tile">
    <header class="tile__header">
      <heading
        tag="h2"
        class="tile__heading"
      >
        <slot name="heading" />
      </heading>
    </header>

    <span
      v-if="measure"
      class="tile__measure"
    >
      {{ measure }}
    </span>

    <div class="tile__body">
      <slot />
    </div>
  </x-card>
</template>

<script>
import Heading from '@/components/Common/Heading.vue'

export default {
  components: {
    Heading
  },

  props: {
    measure: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus" scoped>
  .tile
    display flex
    flex-direction column
    justify-content space-between
    padding-bottom 20px
    height 100%

    &__heading
      margin-bottom 5px
      font-size 18px
      color #54555f

    &__measure
      display block
      padding-bottom 10px
      font-weight 600
      color rgba(#2c2c37, 0.5)

    .tile__body
      flex-grow 1
      margin-left -10px
</style>
