import { defaultHttpClient } from '@/api/default/defaultHttpClient'
import ApiRoutes from '@/api/default/routes'

const {
  GetAll,
  GetList,
  GetAllCombo,
  GetById,
  GetByIdV2,
  Connect,
  ConnectV2,
  Login,
  CreateUpdateOzonCredentials,
  Logout,
  Consulting,
  Request2FACode,
  Send2FACode,
  GetAliTokenUpdateUrl,
  RequestWildberries2FACode,
  SendWildberries2FACode,
  SendWildberriesAuthByTfaCode,
  GetWildberriesShopList,
  AttachWildberriesShop,
  DetachWildberriesShop,
  RequestOzon2FACode,
  SendOzon2FACode,
  GetOzonShopList,
  AttachOzonShop,
  DetachOzonShop,
  GetReportTypes
} = ApiRoutes.Marketplaces

export const getShopList = params => defaultHttpClient.get(GetAll, { params })

export const getShopListV2 = params => defaultHttpClient.get(GetList, { params })

export const getShopListCombo = params => defaultHttpClient.get(GetAllCombo, { params })

export const getReportTypes = () => defaultHttpClient.get(GetReportTypes)

export const updateShop = ({ id, ...data }) => defaultHttpClient.put(GetById(id), data)

export const updateShopV2 = ({ id, ...data }) => defaultHttpClient.put(GetByIdV2(id), data)

export const loginShop = ({ id, ...data }) => defaultHttpClient.post(Login(id), data)

export const createUpdateOzonCredentials = ({ id, ...data }) => defaultHttpClient.post(CreateUpdateOzonCredentials(id), data)

export const connectMarketplace = (payload) => defaultHttpClient.post(Connect, payload)

export const connectMarketplaceV2 = (payload) => defaultHttpClient.post(ConnectV2, payload)

export const logoutMarketplace = id => defaultHttpClient.post(Logout(id))

export const requestConsultation = payload => defaultHttpClient.post(Consulting, payload)

export const request2FACode = id => defaultHttpClient.post(Request2FACode(id))

export const send2FACode = ({ id, ...payload }) => defaultHttpClient.post(Send2FACode(id), payload)

export const getAliTokenUpdateUrl = ({ id, ...params }) => defaultHttpClient.get(GetAliTokenUpdateUrl(id), { params })

export const requestWildberries2FACode = ({ id, ...payload }) => defaultHttpClient.post(RequestWildberries2FACode(id), payload)

export const sendWildberries2FACode = ({ id, ...payload }) => defaultHttpClient.post(SendWildberries2FACode(id), payload)

export const sendWildberriesAuthByTfaCode = ({ id, ...payload }) => defaultHttpClient.post(SendWildberriesAuthByTfaCode(id), payload)

export const getWildberriesShopList = payload => defaultHttpClient.post(GetWildberriesShopList, payload)

export const attachWildberriesShop = ({ id, ...payload }) => defaultHttpClient.post(AttachWildberriesShop(id), payload)

export const detachWildberriesShop = id => defaultHttpClient.post(DetachWildberriesShop(id))

export const requestOzon2FACode = ({ id, ...payload }) => defaultHttpClient.post(RequestOzon2FACode(id), payload)

export const sendOzon2FACode = ({ id, ...payload }) => defaultHttpClient.post(SendOzon2FACode(id), payload)

export const getOzonShopList = payload => defaultHttpClient.post(GetOzonShopList, payload)

export const attachOzonShop = ({ id, ...payload }) => defaultHttpClient.post(AttachOzonShop(id), payload)

export const detachOzonShop = id => defaultHttpClient.post(DetachOzonShop(id))
