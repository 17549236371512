import Store from '@/store'
import { getApiToken, redirectOnPassport } from '@/utils/system'

const hasToken = getApiToken

const unauthorized = (to, from, next) => {
  if (!hasToken()) {
    next()
    return
  }
  next({ name: 'home' })
}

const authorized = (to, from, next) => {
  if (hasToken()) {
    next()
    return
  }

  redirectOnPassport({
    query: {
      cabinet_type: to.query.cabinet_type
    }
  })
}

const promoEnabled = (to, from, next) => {
  const isPromoEnabled = Store.getters['profile/isPromoEnabled']

  if (!isPromoEnabled) {
    next({ name: 'home' })
    return
  }
  authorized(to, from, next)
}

export {
  hasToken,
  unauthorized,
  authorized,
  promoEnabled
}
