import addMsToCurrentTime from '@/utils/addMsToCurrentTime'
import { WEEK_IN_MS } from '@/constants/timings'

import {
  getCookie,
  setCookie,
  removeCookie
} from '@/utils/cookies'

import { appendQueryToUrl } from '@/utils/url-handler'

function detectDomainForToken () {
  if (process.env.NODE_ENV === 'development') {
    return 'localhost'
  }

  return process.env.VUE_APP_CURRENT_ROOT_DOMAIN
}

export const isProd = process.env.NODE_ENV === 'production'

const API_TOKEN_ID = 'xw_token'
const API_TOKEN_BACKUP_ID = 'xw_token_backup'

export const getApiToken = () => getCookie(API_TOKEN_ID)

export const setApiToken = (token) => {
  setCookie(API_TOKEN_ID, token, {
    domain: detectDomainForToken(),
    path: '/',
    expires: addMsToCurrentTime(WEEK_IN_MS)
  })
}

export const removeApiToken = () => {
  removeCookie(API_TOKEN_ID, {
    domain: detectDomainForToken(),
    path: '/',
    expires: '-1'
  })
}

export const turnOnDemo = (demoToken) => {
  const params = {
    domain: detectDomainForToken(),
    path: '/',
    expires: addMsToCurrentTime(WEEK_IN_MS)
  }

  setCookie(API_TOKEN_BACKUP_ID, getApiToken(), params)
  setCookie(API_TOKEN_ID, demoToken, params)
}

export const turnOffDemo = () => {
  const userToken = getCookie(API_TOKEN_BACKUP_ID)

  clearDemoData()

  setApiToken(userToken)
}

export const clearDemoData = () => {
  removeCookie(API_TOKEN_BACKUP_ID, {
    domain: detectDomainForToken(),
    path: '/',
    expires: '-1'
  })
}

const passportUrl = process.env.VUE_APP_PASSPORT_URL

export const redirectOnPassport = (params = {}) => {
  const appendPath = params.path || ''
  let redirectUrl = passportUrl + appendPath

  redirectUrl = appendQueryToUrl(redirectUrl, {
    ...params.query,
    return_url: location.href
  })

  if (params.target) {
    window.open(redirectUrl, params.target)
    return
  }
  window.location = redirectUrl
}
