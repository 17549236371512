import Vue from 'vue'

import axios from 'axios'
import responseCodes from '@/constants/response-codes'
import {
  getApiToken,
  removeApiToken,
  redirectOnPassport
} from '@/utils/system'
import ROUTES from '@/router/ROUTES.js'

function isNetworkError (err) {
  return !!err.isAxiosError && !err.response
}

export const createHttpClient = (baseUrl) => {
  const axiosInstance = axios.create({
    baseURL: baseUrl
  })

  axiosInstance.interceptors.request.use(
    function (config) {
      const token = getApiToken()

      if (token) {
        config.headers['X-Xway-Auth'] = token
      }

      return config
    },

    function (error) {
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(
    function (response) {
      return response
    },

    function (error) {
      if (isNetworkError(error)) {
        Vue.$toast.error('Ошибка сети')
        return
      }

      if (error.response) {
        if (error.response.status === responseCodes.unauthorized) {
          removeApiToken()
          redirectOnPassport()
          return
        }

        const err = error.response?.data
        const errorMessage = err?.detail ? err.detail : (err.errorMessage || err.message)
        if (errorMessage && typeof errorMessage === 'string') {
          Vue.$toast.error(errorMessage)
        }

        if (error.response.status === responseCodes.paymentRequired) {
          window.location.replace(`${ROUTES.TARIFFS.path}?unpaid=true`)
        }
      }

      return Promise.reject(error)
    }
  )

  return axiosInstance
}

export const createHttpClientDashboard = (baseUrl) => {
  const axiosInstance = axios.create({
    baseURL: baseUrl
  })

  axiosInstance.interceptors.request.use(
    function (config) {
      const token = getApiToken()

      if (token) {
        config.headers['X-Xway-Auth'] = token
      }

      return config
    },

    function (error) {
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(
    function (response) {
      return response
    },

    function (error) {
      if (isNetworkError(error)) {
        Vue.$toast.error('Ошибка сети')
        return
      }

      if (error.response) {
        if (error.response.status === responseCodes.unauthorized) {
          removeApiToken()
          redirectOnPassport()
          return
        }

        const err = error.response?.data
        const errorMessage = err?.detail ? err.detail : (err.errorMessage || err.message)
        if (errorMessage && typeof errorMessage === 'string') {
          Vue.$toast.error(errorMessage)
        }

        if (error.response.status === responseCodes.paymentRequired) {
          window.location.replace(`${ROUTES.TARIFFS.path}?unpaid=true`)
        }
      }

      return Promise.reject(error)
    }
  )

  return axiosInstance
}
