const Base = '/seller_cabinet/v1'
const SettingsBase = `${Base}/partner-settings`
const ApiBase = '/api/v1'

export default {
  // Permissions
  GetClientList: `${SettingsBase}/clients`,
  GetClient: id => `${SettingsBase}/clients/${id}`,
  UpdateClient: id => `${SettingsBase}/clients/${id}`,
  CreateClient: `${SettingsBase}/clients`,
  DeleteClient: id => `${SettingsBase}/clients/${id}`,
  AddClientsUser: id => `${SettingsBase}/clients/${id}/add-user`,
  RemoveClientsUser: id => `${SettingsBase}/clients/${id}/remove-user`,
  GetRolesList: `${SettingsBase}/roles`,
  GetRole: id => `${SettingsBase}/roles/${id}`,
  UpdateRole: id => `${SettingsBase}/roles/${id}`,
  CreateRole: `${SettingsBase}/roles`,
  DeleteRole: id => `${SettingsBase}/roles/${id}`,
  GetPermissionsList: `${SettingsBase}/permissions`,
  GetUsersList: `${SettingsBase}/users`,
  GetUser: id => `${SettingsBase}/users/${id}`,
  UpdateUser: id => `${SettingsBase}/users/${id}`,
  CreateUser: `${SettingsBase}/users`,
  DeleteUser: id => `${SettingsBase}/users/${id}`,

  // Finances
  GetUserBalance: `${ApiBase}/balance/`,
  /* deprecated. not used */
  GetUserTariff: `${ApiBase}/tariff/`,
  GetUserTransactions: `${ApiBase}/payment-transactions/`,
  GetTransactionsTypes: `${ApiBase}/payment-transactions/types/`,
  GetTransactionsStatuses: `${ApiBase}/payment-transactions/statuses/`,
  GetPaymentAccounts: `${ApiBase}/payment-accounts/`,
  UpdatePaymentAccount: id => `${ApiBase}/payment-accounts/${id}/`,
  DownloadPaymentDoc: `${ApiBase}/payment-invoices/`,
  GetTariffsList: `${ApiBase}/tariff/all/`,
  GetCurrentTariff: `${ApiBase}/tariff/`,
  ChangeTariff: `${ApiBase}/tariff/change/`,
  CalculateTariffPrice: `${ApiBase}/tariff/change/calculate`
}
