import marketplaceCodes from '@/constants/marketplace-codes'

export const getMarketplaceIcon = (marketplace) => {
  switch (marketplace) {
    case marketplaceCodes.OZON:
      return 'ozon'
    case marketplaceCodes.ALI:
      return 'aliexpress'
    case marketplaceCodes.WILDBERRIES:
      return 'wildberries'
    case marketplaceCodes.BERU:
      return 'yandex-market'
    case marketplaceCodes.YANDEX:
      return 'yandex-market'
    default:
      return null
  }
}
