import { defaultHttpClient } from '@/api/default/defaultHttpClient'
import ApiRoutes from '@/api/default/routes'

const {
  GetReportTypes,
  PostReport,
  RestartById,
  StopById
} = ApiRoutes.Report

export const getReportList = () => defaultHttpClient.get(GetReportTypes())

export const postReport = payload => defaultHttpClient.post(PostReport(), payload)

export const restartReport = (id) => defaultHttpClient.get(RestartById(id))

export const stopReport = (id) => defaultHttpClient.get(StopById(id))
