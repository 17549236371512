import marketplaceCode from '@/constants/marketplace-codes'
import { prioritizeByMP } from '@/utils/prioritizeByMP'

export default {
  lastAliShop (state) {
    const reverseShops = state.shops.slice().reverse()
    return reverseShops.find(s => s.marketplace_code === marketplaceCode.ALI)
  },

  getShopById: state => id => state.shops.find(s => s.id === id),

  filteredShopList: state => prioritizeByMP(state.shops),

  hasShops: state => state.shops?.length
}
