<template>
  <validation-observer
    v-slot="{ handleSubmit }"
  >
    <the-form
      class="form_content_connect-marketplace"
      @submit="handleSubmit(onSubmit)"
    >
      <fieldset class="form-fields-group">
        <legend class="form-fields-group__title">
          <span class="hidden-on-mobile">Добавление магазина на</span> СберМегаМаркет
        </legend>

        <ul class="form-fields-list">
          <li class="form-fields-list__item form-fields-list__item_content_desc">
            Чтобы добавить ваш магазин на СберМегаМаркет, заполните поля формы и нажмите на кнопку “Подключить магазин”
            <x-divider class="mt-2 mb-3" />
            <div class="d-flex">
              <x-link
                primary
                class="video"
                href=""
              >
                Видеоинструкция
              </x-link>
              <x-link
                primary
                href="https://x-way.atlassian.net/wiki/external/105513007/ZWM0NGQ3NGM3NDA0NDQ0NGFhNjE0YWY4YmRjNjQwM2M"
                class="ml-auto"
              >
                Текстовая инструкция
              </x-link>
            </div>
            <x-divider class="mt-3" />
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="validationData"
              rules="required"
              name="Наименование"
              label="Введите наименование магазина"
            >
              <x-input
                v-model="form.params.name"
                v-bind="validationData"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="validationData"
              rules="required"
              name="Код магазина"
              label="Введите код магазина в СберМегаМаркет"
            >
              <x-input
                v-model="form.params.yandex_key"
                v-bind="validationData"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <client-select
              rules="required"
              :value="selectedClient"
              :options="clientList"
              :clearable="false"
              @input="onClientSelect"
            />
          </li>

          <li class="form-fields-list__item form-fields-list__item_content_submit">
            <x-btn
              type="submit"
              block
              :loading="isLoading"
            >
              Подключить магазин
            </x-btn>
          </li>
        </ul>
      </fieldset>
    </the-form>
  </validation-observer>
</template>

<script>
import marketplaceCode from '@/constants/marketplace-codes'
import TheForm from '@/components/Interface/Form.vue'
import ClientSelect from '@/components/Settings/Permissions/ClientSelect.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('settings/permissions')

export default {
  components: {
    TheForm,
    ClientSelect
  },

  props: {
    source: String,
    isLoading: Boolean
  },

  data () {
    return {
      form: {
        params: {
          name: '',
          client_id: null,
          yandex_key: ''
        },
        marketplace: marketplaceCode.SBER
      }
    }
  },

  computed: {
    ...mapState(['clientList']),
    selectedClient () {
      return this.clientList.find(c => c.id === this.form.params.client_id) || null
    }
  },

  methods: {
    onClientSelect (client) {
      this.form.params.client_id = +client.id
    },

    onSubmit () {
      this.$emit('submit', this.form)
    }
  }
}
</script>
