import * as Types from './mutation-types'
import * as PromotionsApi from '@/api/default/modules/promotions'
import downloadFile from '@/utils/download-file'
import setDefaultPaginationParams from '@/utils//setDefaultPaginationParams'
import convertToFormData from '@/utils/form-data'
import { XLSX } from '@/constants/fileTypes'

export default {
  async getPromotions ({ commit }, { store = true, ...payload }) {
    const { data: { data, pagination } } = await PromotionsApi.getPromotions(payload)
    if (store) {
      commit(Types.SET_PROMOTIONS, data)
    }
    return pagination.count
  },

  async getPromotionDetails ({ commit }, id) {
    const { data: { data } } = await PromotionsApi.getDetails(id)
    commit(Types.SET_PROMOTION_DETAILS, data)
  },

  async enableAutopromotion (context) {
    const { data: { isSuccess } } = await PromotionsApi.enableAutopromotion()
    return isSuccess
  },

  async getPromotion ({ commit }, id) {
    const { data } = await PromotionsApi.getPromotion(id)
    commit(Types.SET_PROMOTION, data)
  },

  async startPromoRegistration (context, id) {
    await PromotionsApi.startPromoRegistration(id)
  },

  async downloadProductsTemplate (context, id) {
    const { data: blob } = await PromotionsApi.getProductTemplate(id)
    downloadFile({
      url: blob,
      fileName: 'Шаблон товара',
      type: XLSX
    })
  },

  async uploadProductsTemplate ({ state }, { id, file }) {
    const promoId = id || state.promotion.id
    const data = convertToFormData({ file })

    const { data: isSuccess } = await PromotionsApi.uploadProductTemplate(promoId, data)

    return isSuccess
  },

  async getAvailableProducts ({ commit }, payload) {
    const { data: { data, pagination } } = await PromotionsApi.getAvailableProducts(setDefaultPaginationParams(payload))
    commit(Types.SET_AVAILABLE_PRODUCTS, data)
    commit(Types.SET_AVAILABLE_PRODUCTS_COUNT, pagination.count)
  },

  updateAvailableProducts ({ commit }, payload) {
    return PromotionsApi.updateAvailableProducts(payload)
  },

  async getPromotionProducts ({ commit }, payload) {
    const { data: { data, pagination } } = await PromotionsApi.getPromotionProducts(setDefaultPaginationParams(payload))
    if (payload.params?.only_errors) {
      commit(Types.SET_PROMOTION_PRODUCTS_WITH_ERRORS, data)
      commit(Types.SET_PROMOTION_ERRORS_COUNT, pagination.count)
    } else {
      commit(Types.SET_PROMOTION_PRODUCTS, data)
      commit(Types.SET_PROMOTION_PRODUCTS_COUNT, pagination.count)
    }
  },

  async deleteProducts ({ dispatch }, payload) {
    const { data: { isSuccess, message } } = await PromotionsApi.deleteProducts(payload)

    if (isSuccess) {
      this._vm.$toast.success(message)
    }

    await dispatch('getPromotionProductsErrors', payload.promoId)

    return isSuccess
  },

  async getPromotionProductsErrors ({ commit }, id) {
    const { data: { data } } = await PromotionsApi.getPromotionProductsErrors(id)
    // eslint-disable-next-line camelcase
    const { registration_errors, upload_errors } = data

    commit(Types.SET_REGISTRATION_ERRORS, registration_errors)
    commit(Types.SET_PROMOTION_ERRORS, upload_errors)
  },

  async savePromotionProduct ({ commit }, payload) {
    const { data: { data } } = await PromotionsApi.savePromotionProduct(payload)
    commit(Types.SET_PROMOTION_PRODUCT, data)
  },

  async savePromotionOffer ({ commit }, payload) {
    const { data: { data } } = await PromotionsApi.savePromotionOffer(payload)
    commit(Types.SET_PROMOTION_OFFER, data)
  },

  async registerProductsOnPromo ({ commit }, payload) {
    await PromotionsApi.registerProductsOnPromo(payload)
  }
}
