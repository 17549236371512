<template>
  <x-form-item
    v-slot="validationData"
    label="Клиент"
    name="Клиент"
    :rules="rules"
  >
    <x-select
      v-bind="{ ...$attrs, ...validationData }"
      :value="value"
      label="name"
      track-by="id"
      placeholder="Выберите Клиента"
      :options="options"
      :preselect-first="false"
      @input="$emit('input', $event)"
    />
  </x-form-item>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: null,
      default: () => ({})
    },
    rules: {
      type: String,
      default: ''
    }
  }
}
</script>
