export const appendQueryToUrl = (url, query) => {
  if (!query) return url

  if (Object.keys(query).length) {
    url += '?'
  }

  return Object.keys(query).reduce((res, key, idx) => {
    if (query[key] === undefined || query[key] === null) {
      return res
    }

    res += `&${key}=${query[key]}`
    return res
  }, url)
}
