export const notifyPlugin = {
  install (Vue) {
    const vueProto = Vue.prototype

    const showNotify = (notifyData) => {
      vueProto.$toast.info(notifyData.message)
    }

    vueProto.$ws.on('notification_created', showNotify)

    vueProto.beforeDestroy = function () {
      vueProto.$ws.off('notification_created', showNotify)
    }
  }
}
