import { defaultHttpClient } from '@/api/default/defaultHttpClient'

const OZON2ALI_BASE = 'ozon2ali'

/* метод так же есть в модуле marketplaces */
export const fetchShops = () => defaultHttpClient.get(`${OZON2ALI_BASE}/shops/`)

export const createShop = (marketplace, payload) => defaultHttpClient.post(`${OZON2ALI_BASE}/shops/${marketplace}/`, payload)

export const fetchOzon2AliTaskList = (params) => defaultHttpClient.get(`${OZON2ALI_BASE}/export_lists/`, { params })

export const fetchProductList = (listId, params) => defaultHttpClient.get(`${OZON2ALI_BASE}/export_lists/${listId}/items/`, { params })

export const createImportTask = (shopIdFrom, shopIdTo) => defaultHttpClient.post(
  `${OZON2ALI_BASE}/export_lists/`,
  { shop_from: shopIdFrom, shop: shopIdTo }
)

export const getExportTaskData = (id) => defaultHttpClient.get(`${OZON2ALI_BASE}/export_lists/${id}/`)

export const addProductsToExportList = (id, productIds, params) => defaultHttpClient.post(`${OZON2ALI_BASE}/export_lists/${id}/items/export/`, { item_ids: productIds }, { params })

export const deleteProductsFromExportList = (id, productIds) => defaultHttpClient.delete(`${OZON2ALI_BASE}/export_lists/${id}/items/export/`, { data: { item_ids: productIds } })

export const addFilteredProductsToExportList = (id, filters) => defaultHttpClient.post(`${OZON2ALI_BASE}/export_lists/${id}/items/mass-export/`, null, { params: { ...filters } })

export const deleteFilteredProductsFromExportList = (id, filters) => defaultHttpClient.delete(`${OZON2ALI_BASE}/export_lists/${id}/items/mass-export/`, { params: filters })

export const getExportPrice = (id, params) => defaultHttpClient.post(`${OZON2ALI_BASE}/export_lists/${id}/get-export-price/`, {
  export_params: params
})

export const startExport = (id, params) => defaultHttpClient.post(`${OZON2ALI_BASE}/export_lists/${id}/export/`, {
  export_params: params
})

export const getCountByType = (taskId) => defaultHttpClient.get(`${OZON2ALI_BASE}/export_lists/${taskId}/items/counts-by-type/`)

export const changeProductProp = (taskId, productId, data) => defaultHttpClient.post(`${OZON2ALI_BASE}/export_lists/${taskId}/items/${productId}/`, data)
