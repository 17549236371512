<template>
  <validation-observer
    ref="vObserver"
    v-slot="{ handleSubmit }"
  >
    <the-form
      class="form_content_connect-marketplace"
      @submit="handleSubmit(onSubmit)"
    >
      <fieldset class="form-fields-group">
        <legend class="form-fields-group__title">
          <slot name="heading">
            <span class="hidden-on-mobile">Добавление магазина на</span> AliExpress
          </slot>
        </legend>

        <ul class="form-fields-list">
          <li class="form-fields-list__item form-fields-list__item_content_desc">
            <slot name="text">
              Чтобы добавить ваш магазин на AliExpress, заполните форму и нажмите на кнопку “Подключить магазин”
            </slot>
            <x-divider class="mt-2 mb-3" />
            <div class="d-flex">
              <x-link
                primary
                href="https://rutube.ru/video/bbb9bb098813e8ba3253db568ec616d2/"
                @click="onVideoInstruction"
              >
                Видеоинструкция
              </x-link>
              <x-link
                primary
                href="https://brassy-countess-52e.notion.site/AliExpress-79ce51721dcf441e938cc302439cc4e6"
                class="ml-auto"
                @click="onTextInstruction"
              >
                Текстовая инструкция
              </x-link>
            </div>
            <x-divider class="mt-3" />
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="validationData"
              name="shop-name"
              label="Введите наименование магазина"
              rules="required"
            >
              <x-input
                id="shop-name"
                v-model="form.params.name"
                name="shop-name"
                v-bind="validationData"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="validationData"
              rules="required"
              name="ID магазина на AliExpress"
              vid="ali_shop_id"
              label="Введите Ваш ID на AliExpress"
            >
              <x-input
                id="shop-id"
                v-model="form.params.ali_shop_id"
                name="shop-id"
                v-bind="validationData"
              />
              <p class="form-fields-list__description mt-1">
                Находится в разделе
                <x-link
                  href="https://gsp.aliexpress.com/apps/account/authentication"
                  primary
                >
                  Настройки аккаунта на AliExpress
                </x-link>
              </p>
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <client-select
              rules="required"
              :value="selectedClient"
              :options="clientList"
              :clearable="false"
              @input="onClientSelect"
            />
          </li>

          <li class="form-fields-list__item form-fields-list__item_content_submit">
            <x-btn
              type="submit"
              block
              :loading="isLoading"
            >
              Подключить магазин
            </x-btn>
          </li>
        </ul>
      </fieldset>
    </the-form>
  </validation-observer>
</template>

<script>
import marketplaceCode from '@/constants/marketplace-codes'
import TheForm from '@/components/Interface/Form.vue'
import ClientSelect from '@/components/Settings/Permissions/ClientSelect.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('settings/permissions')

export default {
  components: {
    TheForm,
    ClientSelect
  },

  props: {
    source: String,
    isLoading: Boolean,
    invalidMessage: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data () {
    return {
      form: {
        params: {
          name: '',
          client_id: null,
          ali_shop_id: ''
        },
        marketplace: marketplaceCode.ALI
      }
    }
  },

  watch: {
    'invalidMessage': {
      handler (err) {
        if (!err) return
        // eslint-disable-next-line
        err.keys.forEach( fieldKey => {
          this.$refs.vObserver.setErrors({
            [fieldKey]: err.message || 'Указан неверный токен'
          })
        })
      }
    }
  },

  computed: {
    ...mapState(['clientList']),

    addShopMetrics () {
      return this.source === 'promo_onboarding'
        ? {
          video: 'mmp_onboarding_intro_addshop_video',
          text: 'mmp_onboarding_intro_addshop_text'
        }
        : (this.$route.meta?.metricsEvents?.addShop || {})
    },

    selectedClient () {
      return this.clientList.find(c => c.id === this.form.params.client_id) || null
    }
  },

  methods: {
    onClientSelect (client) {
      this.form.params.client_id = +client.id
    },

    onSubmit () {
      this.$emit('submit', this.form)
    },
    onVideoInstruction () {
      this.$metrics.hit(this.addShopMetrics.video, { marketplace_code: this.form.marketplace })
    },
    onTextInstruction () {
      this.$metrics.hit(this.addShopMetrics.text, { marketplace_code: this.form.marketplace })
    }
  }
}
</script>

<style lang="stylus">
  .npl.x-btn--ghost
    .x-btn__content
      padding-left 0

</style>
