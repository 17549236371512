export const SET_PROMOTIONS = 'SET_PROMOTIONS'
export const SET_PROMOTION_DETAILS = 'SET_PROMOTION_DETAILS'
export const SET_PROMOTION = 'SET_PROMOTION'
export const SET_AVAILABLE_PRODUCTS = 'SET_AVAILABLE_PRODUCTS'
export const SET_AVAILABLE_PRODUCTS_COUNT = 'SET_AVAILABLE_PRODUCTS_COUNT'
export const SET_PROMOTION_PRODUCTS = 'SET_PROMOTION_PRODUCTS'
export const SET_PROMOTION_PRODUCTS_COUNT = 'SET_PROMOTION_PRODUCTS_COUNT'
export const SET_PROMOTION_PRODUCTS_WITH_ERRORS = 'SET_PROMOTION_PRODUCTS_WITH_ERRORS'
export const SET_PROMOTION_ERRORS = 'SET_PROMOTION_ERRORS'
export const SET_REGISTRATION_ERRORS = 'SET_REGISTRATION_ERRORS'
export const SET_PROMOTION_ERRORS_COUNT = 'SET_PROMOTION_ERRORS_COUNT'
export const SET_PROMOTION_PRODUCT = 'SET_PROMOTION_PRODUCT'
export const SET_PROMOTION_OFFER = 'SET_PROMOTION_OFFER'
