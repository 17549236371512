const ROUTES = {
  DASHBOARDS: {
    name: 'new_dashboards',
    path: '/new_dashboards'
  },
  MARKETPLACES: {
    name: 'marketplaces',
    path: '/marketplaces'
  },
  TARIFFS: {
    name: 'tariffs',
    path: '/tariffs'
  },
  REPORTS: {
    name: 'reports',
    path: '/reports'
  },

  PRODUCT: {
    name: 'product',
    path: '/product'
  },

  PRODUCT_LIST: {
    name: 'product.list',
    path: '/product/list'
  }
}

export default ROUTES
