export const PRODUCT_STATUS = {
  DRAFT: 'draft',
  FOR_EXPORT: 'for_export',
  IN_PROGRESS: 'inprogress',
  REJECTED: 'rejected',
  FAILURE: 'failure',
  DONE: 'done'
}

export const IMPORT_STATUS = {
  IN_PROGRESS: 'inprogress',
  FAILURE: 'failure',
  DONE: 'done'
}

export const EXPORT_STATUS = {
  IN_PROGRESS: 'inprogress',
  DRAFT: 'draft',
  DONE: 'done'
}

export default PRODUCT_STATUS
