import '@xway-team/ui/dist/index.css'
import Vue from 'vue'
import UI, { registerComponents, dialogPlugin } from '@xway-team/ui'
import companyDetails from '@/plugins/companyDetails'
import { isCustomError } from '@/utils/isCustomError'
import { ErrorCode, ErrorType } from '@/api/analytics/Errors'

Vue.use(UI, {
  breadcrumbsBase: {
    to: '/',
    text: 'Кабинет продавца'
  },
  companyDetails,
  errorTypeResolver (err, defaultResolver) {
    if (isCustomError(err)) {
      const code = err.data.error.code

      if (code) {
        switch (code) {
          case ErrorCode.DATABASE_ERROR:
          case ErrorCode.CONNECTION_ERROR:
            return ErrorType.WORKS
          case ErrorCode.ITEM_NOT_FOUND:
            return ErrorType.NOT_FOUND
          default:
            return ErrorType.UNEXPECTED
        }
      }
    }

    return defaultResolver(err)
  }
})

registerComponents(Vue)

Vue.use(dialogPlugin)
