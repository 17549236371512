<template>
  <div class="wrong">
    <svg-icon
      name="error"
      class="wrong__icon"
    />

    <h2 class="wrong__heading">
      <slot name="title">
        Что-то пошло не так :(
      </slot>
    </h2>

    <p class="wrong__text">
      Свяжитесь со службой поддержки по&nbsp;почте <strong><a
        class="link"
        href="mailto:seller@xway.ru"
      >seller@xway.ru</a></strong>,
      мы решим вашу проблему
    </p>

    <x-btn
      type="button"
      block
      @click="onClick"
    >
      Написать на почту
    </x-btn>
  </div>
</template>

<script>
export default {
  methods: {
    onClick () {
      window.open('mailto:seller@xway.ru')
    }
  }
}
</script>

<style lang="stylus" scoped>
  .wrong
    max-width 350px
    margin 0 auto
    padding-top 50px
    text-align center

    &__icon
      width 64px
      height 64px
      margin-bottom 10px

    &__heading
      margin-bottom 10px
      font-weight $font-weights.bold
      font-size 24px

    &__text
      margin-bottom 20px
      padding 0 15px
      font-size 13px
      color #cacad3
</style>
