import marketplaceCodes from '@/constants/marketplace-codes'
import marketplaceColors from '@/constants/marketplace-colors'

// returns pie series item
// with different shades for each shop in one marketplace,
// based on the main color of it's marketplace
export default ({ data, y }) => {
  const shopsInMarketplaceCounter = {
    [marketplaceCodes.ALI]: 0,
    [marketplaceCodes.OZON]: 0,
    [marketplaceCodes.YANDEX]: 0,
    [marketplaceCodes.WILDBERRIES]: 0,
    [marketplaceCodes.SBER]: 0
  }

  return data.map(item => {
    const colorIdx = shopsInMarketplaceCounter[item.code]
    let color

    if (colorIdx !== undefined) {
      color = marketplaceColors[item.code][colorIdx]
      shopsInMarketplaceCounter[item.code]++
    }

    return {
      name: item.name,
      y: item[y],
      color
    }
  })
}
