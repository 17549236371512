<template>
  <validation-observer
    ref="vObserver"
    v-slot="{ handleSubmit, invalid }"
  >
    <the-form
      class="form_content_connect-marketplace"
      @submit="handleSubmit(onSubmit)"
    >
      <fieldset class="form-fields-group">
        <legend class="form-fields-group__title">
          <span class="hidden-on-mobile">Добавление магазина на</span> Wildberries
        </legend>

        <ul class="form-fields-list">
          <li class="form-fields-list__item form-fields-list__item_content_desc">
            Чтобы добавить ваш магазин на Wildberries, заполните поля формы и нажмите на кнопку “Подключить магазин”
            <!-- <x-divider class="mt-2 mb-3" />
            <div class="d-flex">
              <x-link
                primary
                href="https://rutube.ru/video/c737fe06f6218390a7bb6e0b2e3a40d7/"
                @click="onVideoInstruction"
              >
                Видеоинструкция
              </x-link>
              <x-link
                primary
                href="https://brassy-countess-52e.notion.site/Wildberries-ed9611d041984f65861314e24416b8e5"
                class="ml-auto"
                @click="onTextInstruction"
              >
                Текстовая инструкция
              </x-link>
            </div>
            <x-divider class="mt-3" /> -->
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="validationData"
              rules="required"
              name="Наименование"
              label="Введите наименование магазина"
            >
              <x-input
                v-model="form.params.name"
                v-bind="validationData"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="{ classes, ...validationData }"
              rules="required|apiWbKey"
              name="Токен для работы с API"
              vid="wildberries_token"
              label='Введите токен "Маркетплейс", "Контент" и "Аналитика"'
            >
              <x-input
                v-model.trim="form.params.wildberries_token"
                v-bind="{ ...$attrs, ...validationData }"
              />
              <!-- <div class="form-fields-list__description mt-1">
                Находится в разделе
                <x-link
                  href="https://seller.wildberries.ru/supplier-settings/access-to-new-api"
                  primary
                >
                  Доступ к новому API на сайте Wildberries
                </x-link>
              </div> -->
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="{ classes, ...validationData }"
              rules="required|apiWbKey64"
              name="Ключ для работы с API статистики x64"
              vid="wildberries_stats_token"
              label='Токен "Статистика", "Вопросы и отзывы", "Цены и скидки"'
            >
              <x-input
                v-model.trim="form.params.wildberries_stats_token"
                v-bind="{ ...$attrs, ...validationData }"
              />
              <!-- <div class="form-fields-list__description mt-1">
                Находится в разделе
                <x-link
                  href="https://seller.wildberries.ru/supplier-settings/access-to-api"
                  primary
                >
                  Доступ к API на сайте Wildberries
                </x-link>
              </div> -->
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <x-form-item
              v-slot="{ classes, ...validationData }"
              rules="required|apiWbKeyAdv"
              name="Токен для работы с API ADV"
              vid="wildberries_adv_token"
              label='Введите токен "Продвижение"'
            >
              <x-input
                v-model.trim="form.params.wildberries_adv_token"
                v-bind="{ ...$attrs, ...validationData }"
              />
            </x-form-item>
          </li>

          <li class="form-fields-list__item">
            <client-select
              rules="required"
              :value="selectedClient"
              :options="clientList"
              :clearable="false"
              @input="onClientSelect"
            />
          </li>

          <li class="form-fields-list__item form-fields-list__item_content_submit">
            <x-btn
              type="submit"
              block
              :loading="isLoading"
              :disabled="invalid"
            >
              Подключить магазин
            </x-btn>
          </li>
        </ul>
      </fieldset>
    </the-form>
  </validation-observer>
</template>

<script>
import marketplaceCode from '@/constants/marketplace-codes'
import TheForm from '@/components/Interface/Form.vue'
import ClientSelect from '@/components/Settings/Permissions/ClientSelect.vue'
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('settings/permissions')

export default {
  components: {
    TheForm,
    ClientSelect
  },

  props: {
    source: String,
    isLoading: Boolean,
    invalidMessage: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data () {
    return {
      form: {
        params: {
          name: '',
          client_id: null,
          wildberries_token: '',
          wildberries_stats_token: '',
          wildberries_adv_token: ''
        },
        marketplace: marketplaceCode.WILDBERRIES
      }
    }
  },

  watch: {
    'invalidMessage': {
      handler (err) {
        if (!err) return
        // eslint-disable-next-line
        err.keys.forEach( fieldKey => {
          this.$refs.vObserver.setErrors({
            [fieldKey]: err.message || 'Указан неверный токен'
          })
        })
      }
    }
  },

  computed: {
    ...mapState(['clientList']),

    selectedClient () {
      return this.clientList.find(c => c.id === this.form.params.client_id) || null
    },

    addShopMetrics () {
      return this.source === 'promo_onboarding'
        ? {
          video: 'mmp_onboarding_intro_addshop_video',
          text: 'mmp_onboarding_intro_addshop_text'
        }
        : (this.$route.meta?.metricsEvents?.addShop || {})
    }
  },

  methods: {
    onClientSelect (client) {
      this.form.params.client_id = +client.id
    },

    onSubmit () {
      this.$emit('submit', this.form)
    },
    onVideoInstruction () {
      this.$metrics.hit(this.addShopMetrics.video, { marketplace_code: this.form.marketplace })
    },
    onTextInstruction () {
      this.$metrics.hit(this.addShopMetrics.text, { marketplace_code: this.form.marketplace })
    }
  }
}
</script>
