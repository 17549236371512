import * as Types from './mutation-types'

export default {
  [Types.SET_USER] (state, user) {
    state.user = user
  },

  [Types.TOGGLE_PROFILE_LOADING] (state) {
    state.isProfileLoading = !state.isProfileLoading
  },

  [Types.SET_IS_DEMO_ACCOUNT] (state, isDemo) {
    state.user.is_demo_account = isDemo
  }
}
