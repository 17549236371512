<template>
  <tile :measure="measure">
    <template #heading>
      <div class="d-flex justify-between">
        <div class="d-flex align-center semibold">
          {{ GraficsName[heading] }}
          <tooltip>
            <svg-icon
              class="info__icon ml-1 mt-1"
              name="info-fill"
            />
            <template #popover>
              <span>День когда магазин был доступен (не отключен) и по нему осуществлялся сбор метрик</span>
            </template>
          </tooltip>
        </div>
      </div>
    </template>
    <div
      v-if="loadind"
      class="dashboard__loader"
    >
      <loader />
    </div>
    <div
      v-else
      class="chart-container"
    >
      <chart
        :options="chartOptions"
      />
    </div>
  </tile>
</template>

<script>
import { Chart } from 'highcharts-vue'
import formatter from '@/utils/formatterTooltip'
import Tile from '@/components/Common/Tile.vue'
import Loader from '@/components/Common/Loader.vue'
import Measures from '@/constants/measure-name'
import GraficsName from '@/constants/dashboards_grafics_name'
import Tooltip from '@/components/Common/Tooltip.vue'
import '@/styles/grafics.styl'

export default {

  components: {
    Chart,
    Tile,
    Loader,
    Tooltip
  },

  props: {
    heading: {
      type: String,
      default: ''
    },
    measure: {
      type: String,
      default: ''
    },
    points: {
      type: Object,
      required: true
    },
    loadind: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      Measures,
      GraficsName,
      chartOptions: {
        credits: {
          enabled: false
        },
        chart: {
          type: 'scatter',
          height: 0,
          style: {
            fontFamily: 'proxima_nova',
            fontWeight: 'normal'
          },
          backgroundColor: 'transparent',
          inverted: false
        },
        title: {
          text: null
        },
        legend: {
          enabled: false
        },
        tooltip: {
          backgroundColor: '#151522',
          borderRadius: 4,
          borderWidth: 0,
          shadow: false,
          formatter: formatter.formatterScattersTooltip
        },
        plotOptions: {
          scatter: {
            states: {
              hover: {
                enabled: false
              }
            },
            marker: {
              symbol: 'circle'
            }
          },
          series: {
            shadow: false
          }
        },
        xAxis: {
          categories: [],
          gridLineWidth: 1,
          lineWidth: 0,
          labels: {
            style: {
              color: '#7b7b85',
              whiteSpace: 'nowrap',
              textOverflow: 'none'
            }
          }
        },
        yAxis: {
          categories: [],
          gridLineWidth: 0,
          lineWidth: 0,
          title: null,
          labels: {
            align: 'left',
            reserveSpace: true,
            formatter: (value) => this.points.values ? this.points.values[value.value - 1].name : '',
            style: {
              color: '#7b7b85',
              fontSize: 14
            }
          }
        },
        series: []
      }
    }
  },

  watch: {
    points: {
      handler (value) {
        this.chartOptions.chart.height = value.values && value.values.length === 1 ? value.values.length * 110 : value.values ? value.values.length * 90 : 90
        this.chartOptions.xAxis.categories = value.dates
        this.chartOptions.yAxis.plotBands = value.values ? value.values.map((value, index) => ({
          color: index % 2 ? '#ffffff' : '#F7F7F8',
          from: index + 0.5,
          to: index + 2
        })) : []
        this.chartOptions.series = value.values ? value.values.map((value, index) => {
          return {
            data: value.data.map(_ => ({
              y: index + 1,
              marker: {
                symbol: _ ? 'url(https://cdn.xway.ru/mmp/icon%20check.svg)' : _ !== null ? 'url(https://cdn.xway.ru/mmp/icon%20x.svg)' : 'url(https://cdn.xway.ru/mmp/icon%20minus.svg)',
                radius: 10
              }
            }))
          }
        }) : []
      },
      deep: true
    }
  }
}
</script>

<style lang="stylus" scoped>
  .info__icon
    opacity 0.5

  .semibold
    font-weight 600

  .chart-container
    padding-left: 10px
</style>
