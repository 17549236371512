export default Object.freeze({
  ORDERS_COUNT: 'Заказано товаров, шт.',
  ORDERS_MONEY: 'Заказано на сумму, руб.',
  PRODUCTS_WITH_ERRORS: 'Карточки с ошибками',
  EXPIRED_FEEDBACKS_COUNT: 'Просроченные отзывы',
  DRR: 'ДРР, %',
  CTR: 'CTR, %',
  PAID_VIEWS_COUNT: 'Платные показы (APV), шт.',
  ADV_CLICKS: 'Клики с РК',
  PRODUCTS_IN_SELLING_FBO: 'FBO',
  PRODUCTS_IN_SELLING_FBS: 'FBS',
  PRODUCTS_IN_SELLING: 'Товары в продаже (FBO + FBS)',
  PRODUCTS_WITH_ZERO_STOCK_FBO: 'FBO',
  PRODUCTS_0_STOCK_FBO: 'FBO',
  PRODUCTS_1_STOCK_FBO: 'Кол-во товаров со стоком = 1 FB0',
  PRODUCTS_2_5_STOCK_FBO: 'Кол-во товаров со стоком от 2 до 5 FB0',
  PRODUCTS_WITH_ZERO_STOCK_FBS: 'FBS',
  PRODUCTS_0_STOCK_FBS: 'FBS',
  PRODUCTS_1_STOCK_FBS: 'Кол-во товаров со стоком = 1 FBS',
  PRODUCTS_2_5_STOCK_FBS: 'Кол-во товаров со стоком от 2 до 5 FBS',
  DISPUTED_ORDERS: 'Спорные заказы FBS',
  FBS_DELIVERING_TODAY: 'Заказы на сборке с сегодняшней отгрузкой',
  // CANCELLED_ORDERS_BY_SELLER: 'Отмененные продавцом заказы FBS',
  CANCELLED_ORDERS_BY_SELLER: 'Кол-во отмен продавца',
  CANCELLED_ORDERS_RATING: 'Процент отмен по FBS',
  CANCELLATIONS: 'Отмены, шт',
  CANCELLATIONS_MONEY: 'Отмены, руб',
  PRODUCTS_CANCELLED: 'Отменено товаров',
  PRODUCTS_RETURNED: 'Возвращено товаров',
  CANCELLED_ORDERS_BY_SELLER_RATING: 'Стоимость отмен продавца',
  VIEWS_COUNT: 'Показы, шт.',
  ORGANIC_TRAFFIC: 'Органический трафик (OPV), шт.',
  OZON_EXPENSE_TEMPLATE_ADV_MONEY: 'Трафареты',
  OZON_EXPENSE_SEARCH_ADV_MONEY: 'Продвижение в поиске',
  OZON_EXPENSE_BRAND_ADV_MONEY: 'Брендовая полка',
  ADV_ORDERS_COUNT: 'Заказы с РК, шт.',
  ADV_ORDERS_MONEY: 'Заказы с РК, руб.',
  ADVERT_ORDERS_SHARE: 'Доля заказов с РК от общих продаж, %',
  PRODUCTS_WITH_ZERO_STOCK: 'Готовы к продаже (FBO + FBS)',
  SHIPMENT_DELAY_RATING: 'Процент просроченных отгрузок',
  POSITIVE_INDEX: 'Выгодный индекс',
  NEGATIVE_INDEX: 'Невыгодный индекс',
  NEUTRAL_INDEX: 'Умеренный индекс',
  QUESTIONS_UNANSWERED: 'Неотвеченные вопросы',
  PRODUCTS_WITH_BAD_DESCRIPTION: 'Товары с плохим описанием',
  PRODUCTS_IN_QUARANTINE: 'Товары в карантине цен',
  EXPENSE_ADV_MONEY: 'Расход на РК всего, руб.',
  EXPIRED_FBS_ORDERS: 'Заказы на сборке более 36 часов',
  WB_EXPENSE_PRODUCT_ADV_MONEY: 'Карточка товара',
  WB_EXPENSE_SEARCH_ADV_MONEY: 'Поиск',
  WB_EXPENSE_CATALOG_ADV_MONEY: 'Каталог',
  WB_COMMISSION: 'Комиссия за доставку заказов, %',
  WB_MERCHANT_RATING: 'Рейтинг продавца, %',
  WB_AVG_DELIVERY_TIME: 'Среднее время доставки заказов, ч.',

  ADDED_TO_CART: 'Добавления в корзину',
  GO_TO_CARD: 'Переходы в карточку товара',
  CONVERSION_ADDED_TO_CART: 'Конверсия в корзину',
  CONVERSION_ADDED_TO_CART_FROM_ADV: 'Конверсия в корзину с рекламы',
  SHARE_ADDED_TO_CART_FROM_ADV: 'Доля добавлений в корзину с РК',
  SHARE_CLICKS: 'Доля рекламных кликов к органическим',
  CONVERSION_TO_ORDER: 'Конверсия в заказ',
  CONVERSION_VIEWS_TO_ORDER: 'Конверсия из показов в заказ',
  CONVERSION_PRODUCT_TO_CART: 'Конверсия из показов в карточке в корзину',
  CONVERSION_VIEWS_TO_PRODUCT: 'Конверсия из показов в карточку товара',
  CONVERSION_CART_TO_ORDER: 'Конверсия из добавления в корзину с карточки в заказ',
  CONVERSION_TO_ORDER_FROM_ADV: 'Конверсия в заказ с рекламы',
  EXPENSE_ADV_MONEY__SEARCH_CATALOG: 'Поиск + каталог',
  EXPENSE_ADV_MONEY__SEARCH: 'Поиск',
  EXPENSE_ADV_MONEY__CATALOG: 'Каталог',
  EXPENSE_ADV_MONEY__PRODUCT: 'Карточка товара',
  EXPENSE_ADV_MONEY__AUTOMATIC: 'Автоматическая',
  REDEMPTION_PERCENTAGE: 'Процент выкупа',

  ADDED_TO_CART_FROM_PRODUCT: 'Добавления в корзину с карточки товара',

  // Groups name

  metrics: 'Метрики',
  products: 'Товары',
  product_and_prices: 'Товары и цены',
  other: 'Другое',
  advertising: 'Реклама',
  stock_fbo: 'Стоки FBO',
  orders_fbs: 'Заказы FBS'
})
