import store from '@/store'
import { isAllMetricsInitialized, initMetrics } from '@/metrics'
import { clearDemoData } from '@/utils/system'
import { auth as carrotAuth } from '@/metrics/carrotquest'

class User {
  data = {
    isLogged: null,
    permissionKeys: [],
    currentTariff: {},
    futureTariff: {}
  }

  isSetupFinished = false

  isPermitByTariff (permissionKey) {
    return this.data.permissionKeys.includes(permissionKey)
  }

  getShopsLimit () {
    return this.data.currentTariff?.tariff?.shops_limit
  }

  getCurrentTariffName () {
    return this.data.currentTariff?.tariff?.name
  }

  logout () {
    return store.dispatch('profile/logout')
  }

  async getProfile () {
    try {
      await store.dispatch('profile/getProfile')

      this.data.isLogged = true

      this.data.permissionKeys = store.getters['profile/permissionKeys']
      this.data.currentTariff = store.getters['profile/currentTariff']
      this.data.futureTariff = store.getters['profile/futureTariff']

      /* если залогиниться в паспорте или через query параметры - при том, что вход уже выполнен - нужно очистить бэкап токена в куках */
      if (!store.getters['profile/isDemoAccount']) {
        clearDemoData()
      }

      if (
        process.env.VUE_APP_DEPLOYMENT_ENV === 'production' &&
        !isAllMetricsInitialized() &&
        !store.getters['profile/isXwayEmployee'] &&
        !store.getters['profile/isDemoAccount']
      ) {
        initMetrics()
        setTimeout(() => {
          carrotAuth(store.state.profile?.user?.guid, store.state.profile?.user?.carrot_quest_user_hash)
        }, 500)
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      this.data.isLogged = false
    }
  }

  setup () {
    this.isSetupFinished = true
    return this.getProfile()
  }
}

const _user = new User()

export default {
  install (Vue) {
    _user.setup()
    Vue.prototype.$user = Vue.observable(_user)
  }
}
