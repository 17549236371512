import mapPieData from '@/utils/pie-data-mapper'

export default {
  isDashboardEmpty (state) {
    return !Object.keys(state.dashboards).length && state.isDashboardLoaded
  },

  summary (state) {
    const result = state.dashboards.indicators.period.slice()
    const diffs = state.dashboards.indicators.diff

    return result.map(r => {
      const item = diffs.find(d => d.indicator_type === r.indicator_type)
      if (item) {
        return {
          ...r,
          diff: item.diff,
          diff_value: item.indicator_value_prev
        }
      }
      return r
    })
  },

  salesDynamic (state) {
    return state.dashboards.graph_dynamic?.selling_amount
  },

  orderCount (state) {
    return state.dashboards.graph_dynamic?.order_count
  },

  averageCheck (state) {
    return state.dashboards.graph_dynamic?.avg_check
  },

  createdOrdersDynamic (state) {
    return state.dashboards.graph_created_orders
  },

  orders (state) {
    return state.dashboards.graph_orders
  },

  ordersByShops (state) {
    if (!state.dashboards.pie_order_count_shops) return []

    return mapPieData({
      data: state.dashboards.pie_order_count_shops,
      y: 'order_count'
    })
  },

  ordersByMarketplaces (state) {
    if (!state.dashboards.pie_order_count_marketplaces) return []

    return mapPieData({
      data: state.dashboards.pie_order_count_marketplaces,
      y: 'order_count'
    })
  },

  salesByShops (state) {
    if (!state.dashboards.pie_selling_amount_shops) return []

    return mapPieData({
      data: state.dashboards.pie_selling_amount_shops,
      y: 'selling_amount'
    })
  },

  salesByMarketplaces (state) {
    if (!state.dashboards.pie_selling_amount_marketplaces) return []

    return mapPieData({
      data: state.dashboards.pie_selling_amount_marketplaces,
      y: 'selling_amount'
    })
  },

  topProducts (state) {
    return state.dashboards.top10_selling_products
  },

  topExpenciveOrders (state) {
    return state.dashboards.top10_expensive_orders
  },

  lastOrders (state) {
    return state.dashboards.last_orders
  }
}
