<template>
  <ul class="filters">
    <li class="filters__item filters__item_content_period">
      <x-form-item
        name="Период"
        label="Период"
        label-size="s"
      >
        <datepicker
          :max-date="new Date()"
          :default-period="currentPeriod"
          :value="period"
          @change="period => $emit('change:period', period)"
          @change-default-period="p => currentPeriod = p"
        />
      </x-form-item>
    </li>

    <li class="filters__item">
      <shop-filter
        shadow
        :shop="shop"
        :shops="accessedShops"
        @change:shop="option => $emit('change:shop', option)"
      />
    </li>

    <li class="filters__item">
      <span class="x-form-item">
        <div class="x-label x-label--size-xs" />
        <div class="checkboxs">
          <x-checkbox
            v-model="show_day"
            label="Д/Д"
            @change="value => $emit('change:show-by', 'show_day', value)"
          />
          <tooltip>
            <svg-icon
              class="info__icon mt-1"
              name="info-fill"
            />
            <template #popover>
              <span>Отношение текущего дня к предыдущему дню в %</span>
            </template>
          </tooltip>
          <x-checkbox
            v-model="show_week"
            label="Н/Н"
            @change="value => $emit('change:show-by', 'show_week', value)"
          />
          <tooltip>
            <svg-icon
              class="info__icon mt-1"
              name="info-fill"
            />
            <template #popover>
              <span>Отношение текущего дня на этой неделе к аналогичному дню на прошлой неделе в %, например среда к среде или вторник к вторнику</span>
            </template>
          </tooltip>
        </div>
      </span>
    </li>
  </ul>
</template>

<script>
import { showByOptions } from '@/constants/dashboards-filters'
import CalendarPeriods from '@/constants/calendar-periods'
import Datepicker from '@/components/Interface/Datepicker.vue'
import ShopFilter from '@/components/Interface/MultipleShopFilter.vue'
import Tooltip from '@/components/Common/Tooltip.vue'
import { getMarketplaceIcon } from '@/utils/getMarketplaceIcon'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('marketplaces')
const { mapGetters } = createNamespacedHelpers('profile')

export default {
  inheritAttrs: false,

  components: {
    Datepicker,
    ShopFilter,
    Tooltip
  },

  props: {
    shop: {
      type: Array,
      default: () => []
    },
    period: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      showByOptions,
      currentPeriod: CalendarPeriods.CURRENT_WEEK,
      show_day: true,
      show_week: true
    }
  },

  computed: {
    ...mapState(['shops']),
    ...mapGetters(['isUserAccessedToSection']),

    accessedShops () {
      // TODO: remove shops list
      return this.shop.map(item => ({
        ...item,
        marketplace_code: this.getMarketplaceName(item.marketplace_id)
      }))
    }
  },

  methods: {
    getMarketplaceName (id) {
      let result = ''
      switch (id) {
        case 1:
          result = 'ali'
          break
        case 2:
          result = 'ozon'
          break
        case 4:
          result = 'beru'
          break
        case 9:
          result = 'wildberries'
          break
        default:
          result = ''
      }
      getMarketplaceIcon(result)
      return result
    }
  }
}
</script>

<style lang="stylus" scoped>
  .filters
    display flex
    margin 0
    padding 0
    list-style none
    margin-left -10px

    @media (max-width 768px)
      display block
      margin-left 0

    .x-checkbox--size-xs
      .x-checkbox__label
        margin-left 4px

    .info__icon
      margin-right 5px

    &__item
      flex-basis 160px
      margin-left 10px

      @media (max-width 768px)
        margin-left 0
        margin-bottom 15px

      &_content_period
        flex-basis 200px

      &:last-child
        @media (max-width 768px)
          margin-bottom 0

      .checkboxs
        display flex
        justify-content space-around
        margin-top 5px
        padding 7px 9px 5px 9px
        background-color #ffffff
        border-radius 5px
        .info__icon
          opacity 0.5

    &__option
      display flex
      align-items center

    &__option-icon
      width 14px
      margin-right 4px
</style>
<style lang="stylus">
.dashboard
  .filters
    .datepicker
      &:hover
        box-shadow 0 6px 16px rgba(5,2,55,.12)
</style>
