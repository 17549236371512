import { defaultHttpClient, defaultHttpClientDashboard } from '@/api/default/defaultHttpClient'
import ApiRoutes from '@/api/default/routes'

export const getAll = (payload) => defaultHttpClient.get(ApiRoutes.Dashboards.GetAll, { params: payload })

export const getShopsActivity = (payload) => defaultHttpClientDashboard.post(ApiRoutes.Dashboards.getShopsActivity, payload)

export const getShopActivity = ({ id, ...data }) => defaultHttpClientDashboard.post(ApiRoutes.Dashboards.getShopActivity(id), data)

export const getShopOrderFull = ({ id, ...data }) => defaultHttpClientDashboard.post(ApiRoutes.Dashboards.getShopOrderFull(id), data)

export const getOrdersCount = (payload) => defaultHttpClientDashboard.post(ApiRoutes.Dashboards.getOrdersCount, payload)

export const getDrrHistory = (payload) => defaultHttpClientDashboard.post(ApiRoutes.Dashboards.getDrrHistory, payload)

export const getExpenseHistory = (payload) => defaultHttpClientDashboard.post(ApiRoutes.Dashboards.getExpenseHistory, payload)

export const getExpiredFeedbacksCount = (payload) => defaultHttpClientDashboard.post(ApiRoutes.Dashboards.getExpiredFeedbacksCount, payload)

export const getProductHistory = (payload) => defaultHttpClientDashboard.post(ApiRoutes.Dashboards.getProductHistory, payload)

export const getOrderFull = (payload) => defaultHttpClientDashboard.post(ApiRoutes.Dashboards.getOrderFull, payload)

export const getMetricsList = ({ id, ...data }) => defaultHttpClientDashboard.post(ApiRoutes.Dashboards.getMetricsList(id), data)

export const getMetricsStatistic = ({ id, ...data }) => defaultHttpClientDashboard.post(ApiRoutes.Dashboards.getMetricsStatistic(id), data)

export const getTop5Product = ({ id, ...data }) => defaultHttpClientDashboard.post(ApiRoutes.Dashboards.getTop5Product(id), data)
