import * as Types from './mutation-types'

export default {
  [Types.SET_CLIENT_LIST] (state, payload) {
    state.clientList = payload
  },
  [Types.SET_ROLES_LIST] (state, payload) {
    state.rolesList = payload
  },
  [Types.SET_PERMISSIONS_LIST] (state, payload) {
    state.permissionsList = payload
  },
  [Types.SET_USERS_LIST] (state, payload) {
    state.usersList = payload
  },
  [Types.UPDATE_CLIENT] (state, payload) {
    const client = state.clientList.find(c => c.id === payload.id)

    if (client) {
      Object.assign(client, payload)
    }
  }
}
