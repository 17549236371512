function insertYM (m, e, t, r, i, k, a) {
  m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments) }
  // eslint-disable-next-line no-unused-expressions,no-sequences
  m[i].l = 1 * new Date(); k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
}

export function init () {
  insertYM(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym')
  window.ym(87747759, 'init', {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
  })
}

export function isInitialized () {
  return Boolean(window.ym)
}
