export default Object.freeze({
  notifications_from_MP: 'Наличие уведомлений от МП',
  products_in_selling: 'Количество товаров в продаже',
  products_in_selling_FBO_FBS: 'Товары в продаже FBO/FBS, кол-во',
  products_with_zero_stock: 'Товары с 0 стоком FBO+FBS+DBS',
  products_with_0_stocks_FBO: 'Товары с 0 стоком FBO',
  products_with_0_stocks_FBS: 'Товары с 0 стоком FBS',
  stocks_FBW: 'Стоки FBW, шт',
  stocks_FBS: 'Стоки FBS, шт',
  cpo: 'CPO, ₽',
  cpc: 'CPC, ₽',
  products_with_0_stocks_FBO_FBS: 'Товары c 0 стоком FBO/FBS, кол-во',
  expired_feedbacks_count: 'Просроченные отзывы',
  expense_adv_money: 'Расход на РК',
  expense_adv_money_type: 'Расход по типам РК, ₽',
  orders_from_RK: 'Заказы с РК',
  revenue_from_RK: 'Выручка с РК',
  share_of_orders_from_RK: 'Доля заказов с РК от общих продаж',
  drr: 'ДРР',
  ctr: 'CTR, %',
  content_and_prices: 'Контент и цены',
  details_of_FBS_orders: 'Детали заказов FBS',
  cancellations_and_returns: 'Отмены и возвраты',
  cancellations: 'Отмены, шт',
  cancellations_money: 'Отмены, ₽',
  FBS_cancellation_percentage: 'Процент отмен FBS',
  impressions: 'Показы',
  orders_count: 'Заказано товара',
  orders_money: 'Заказано на сумму',
  is_shop_active: 'Доступность магазинов',
  products_with_errors: 'Товары с ошибками',
  apv: 'Платные показы (APV)',
  with_error: 'Карточки с ошибками',
  adding_and_routing: 'Добавление и переходы, кол-во',
  adding_and_views: 'Добавления и органические показы, кол-во',
  conversions: 'Конверсии, %',
  adv_conversions: 'Конверсии с РК, %',
  drr_ctr: 'ДРР и CTR, %',
  working_with_content: 'Работа с контентом, кол-во',
  views_clicks_count: 'Показы и клики, кол-во',
  adv_ordered_money: 'Заказано на сумму с РК, ₽',
  adv_ordered_quantity: 'Заказано товаров с РК, шт',
  adv_product_ordered_quantity: 'Заказов с РК, шт',
  adv_added_to_cart: 'Добавления в корзину с рекламы, шт',
  price_index: 'Индекс цен, ₽',
  expense_loyalty_money: 'Расход на лояльность, ₽',
  top_5: 'Топ 5 товаров по обороту, ₽',
  user_responses: 'Ответы пользователей, кол-во',
  summary: 'Сводка'
})
