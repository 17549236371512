const Base = '/seller_cabinet/v1/shops/'
const BaseV2 = '/seller_cabinet/v2/shops/'
const List = '/seller_cabinet/v2/shops/list'

export default {
  /* метод так же есть в модуле ozon2ali */
  GetAll: Base,
  GetAllV2: BaseV2,
  GetAllCombo: `${BaseV2}combo`,
  GetList: List,
  GetById: id => `${Base}${id}/`,
  GetByIdV2: id => `${BaseV2}${id}/`,
  Connect: Base,
  ConnectV2: BaseV2,
  GetReportTypes: `${Base}reports`,
  Login: id => `${Base}${id}/login/`,
  CreateUpdateOzonCredentials: id => `${Base}${id}/ozon/update/credentials/`,
  Logout: id => `${Base}${id}/logout/`,
  Request2FACode: id => `${Base}${id}/send-2fa-token`,
  Send2FACode: id => `${Base}${id}/verify-2fa-token`,
  Consulting: `${Base}consulting/`,
  GetAliTokenUpdateUrl: id => `${Base}${id}/get-update-ali-token-link`,
  RequestWildberries2FACode: id => `${Base}${id}/wildberries_phone_auth`,
  SendWildberries2FACode: id => `${Base}${id}/wildberries_auth_by_sms`,
  SendWildberriesAuthByTfaCode: id => `${Base}${id}/wildberries_auth_by_tfa`,
  GetWildberriesShopList: `${Base}wildberries_phone_shop_list`,
  AttachWildberriesShop: id => `${Base}${id}/attach_wildberries`,
  DetachWildberriesShop: id => `${Base}${id}/detach_wildberries`,

  RequestOzon2FACode: id => `${Base}${id}/ozon_init_auth`,
  SendOzon2FACode: id => `${Base}${id}/ozon_sms_auth`,
  GetOzonShopList: `${Base}ozon_company_list`,
  AttachOzonShop: id => `${Base}${id}/ozon_send_company`,
  DetachOzonShop: id => `${Base}${id}/ozon_detach`
}
