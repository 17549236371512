import { defaultHttpClient } from '@/api/default/defaultHttpClient'
import ApiRoutes from '@/api/default/routes'

const {
  GetTasks
} = ApiRoutes.PricesAndStocks

export const getTasks = (params) => defaultHttpClient.get(GetTasks, { params })

export const downloadStock = (taskId) => defaultHttpClient.get(`${GetTasks}/${taskId}/xlsx`, { responseType: 'blob' })
