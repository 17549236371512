import { defaultHttpClient } from '@/api/default/defaultHttpClient'
import ApiRoutes from '@/api/default/routes'

const {
  GetClientList,
  GetClient,
  CreateClient,
  UpdateClient,
  DeleteClient,
  AddClientsUser,
  RemoveClientsUser,
  GetRolesList,
  GetRole,
  UpdateRole,
  CreateRole,
  DeleteRole,
  GetPermissionsList,
  GetUsersList,
  GetUser,
  UpdateUser,
  CreateUser,
  DeleteUser
} = ApiRoutes.Settings

export const getClientList = () => defaultHttpClient.get(GetClientList)

export const getClient = (id) => defaultHttpClient.get(GetClient(id))

export const updateClient = ({ id, ...payload }) => defaultHttpClient.patch(UpdateClient(id), payload)

export const createClient = (payload) => defaultHttpClient.post(CreateClient, payload)

export const deleteClient = (id) => defaultHttpClient.delete(DeleteClient(id))

export const addClientsUser = ({ id, ...payload }) => defaultHttpClient.post(AddClientsUser(id), payload)

export const removeClientsUser = ({ id, ...payload }) => defaultHttpClient.post(RemoveClientsUser(id), payload)

export const getRolesList = () => defaultHttpClient.get(GetRolesList)

export const getRole = (id) => defaultHttpClient.get(GetRole(id))

export const updateRole = ({ id, ...payload }) => defaultHttpClient.patch(UpdateRole(id), payload)

export const createRole = (payload) => defaultHttpClient.post(CreateRole, payload)

export const deleteRole = (id) => defaultHttpClient.delete(DeleteRole(id))

export const getPermissionsList = () => defaultHttpClient.get(GetPermissionsList)

export const getUsersList = () => defaultHttpClient.get(GetUsersList)

export const getUser = (id) => defaultHttpClient.get(GetUser(id))

export const updateUser = ({ id, ...payload }) => defaultHttpClient.patch(UpdateUser(id), payload)

export const createUser = (payload) => defaultHttpClient.post(CreateUser, payload)

export const deleteUser = (id) => defaultHttpClient.delete(DeleteUser(id))
