function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"vObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('the-form',{staticClass:"form_content_connect-marketplace",on:{"submit":function($event){return handleSubmit(_vm.onSubmit)}}},[_c('fieldset',{staticClass:"form-fields-group"},[_c('legend',{staticClass:"form-fields-group__title"},[_c('span',{staticClass:"hidden-on-mobile"},[_vm._v("Добавление магазина на Ozon")])]),_c('ul',{staticClass:"form-fields-list"},[_c('li',{staticClass:"form-fields-list__item form-fields-list__item_content_desc"},[_vm._v(" Чтобы добавить ваш магазин на Ozon, заполните поля формы и нажмите на кнопку “Подключить магазин” "),_c('x-divider',{staticClass:"mt-2 mb-3"}),_c('div',{staticClass:"d-flex"},[_c('x-link',{attrs:{"primary":"","href":"https://rutube.ru/video/92b631146d91149d07f30ba6b4052574/"},on:{"click":_vm.onVideoInstruction}},[_vm._v(" Видеоинструкция ")]),_c('x-link',{staticClass:"ml-auto",attrs:{"primary":"","href":"https://brassy-countess-52e.notion.site/Ozon-f78293d91ce94cb186d0de57b3b9395a"},on:{"click":_vm.onTextInstruction}},[_vm._v(" Текстовая инструкция ")])],1),_c('x-divider',{staticClass:"mt-3"})],1),_c('li',{staticClass:"form-fields-list__item"},[_c('x-form-item',{attrs:{"rules":"required","name":"Наименование","label":"Введите наименование магазина"},scopedSlots:_vm._u([{key:"default",fn:function(validationData){return [_c('x-input',_vm._b({model:{value:(_vm.form.params.name),callback:function ($$v) {_vm.$set(_vm.form.params, "name", $$v)},expression:"form.params.name"}},'x-input',validationData,false))]}}],null,true)})],1),_c('li',{staticClass:"form-fields-list__item"},[_c('x-form-item',{attrs:{"rules":"required|ozonClientId","name":"ID клиента","vid":"ozon_client_id","label":"Введите Ozon Client ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var rest = objectWithoutProperties( ref, ["classes"] );
var validationData = rest;
return [_c('x-input',_vm._b({model:{value:(_vm.form.params.ozon_client_id),callback:function ($$v) {_vm.$set(_vm.form.params, "ozon_client_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.params.ozon_client_id"}},'x-input',validationData,false))]}}],null,true)})],1),_c('li',{staticClass:"form-fields-list__item"},[_c('x-form-item',{attrs:{"rules":"required|apiOzonKey","name":"Ozon API key","vid":"ozon_key","label":" Введите Ozon API key Администратора"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var rest = objectWithoutProperties( ref, ["classes"] );
var validationData = rest;
return [_c('x-input',_vm._b({model:{value:(_vm.form.params.ozon_key),callback:function ($$v) {_vm.$set(_vm.form.params, "ozon_key", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.params.ozon_key"}},'x-input',validationData,false)),_c('p',{staticClass:"form-fields-list__description mt-1"},[_vm._v(" Находится в разделе "),_c('x-link',{attrs:{"href":"https://seller.ozon.ru/app/settings/api-keys","primary":""}},[_vm._v(" API-ключи на Ozon ")])],1)]}}],null,true)})],1),_c('li',{staticClass:"form-fields-list__item"},[_c('client-select',{attrs:{"rules":"required","value":_vm.selectedClient,"options":_vm.clientList,"clearable":false},on:{"input":_vm.onClientSelect}})],1),_c('li',{staticClass:"form-fields-list__item form-fields-list__item_content_submit"},[_c('x-btn',{attrs:{"type":"submit","block":"","loading":_vm.isLoading,"disabled":invalid}},[_vm._v(" Подключить магазин ")])],1)])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }