<template>
  <checkbox
    :model-value="isDemoAccount"
    rounded
    class="demo-toggle"
    :class="classes"
    @change="toggleDemo"
  >
    Демо-режим
  </checkbox>
</template>

<script>
import Checkbox from '@/components/Interface/Checkbox'
import { getDemoToken } from '@/api/default/modules/profile'
import { turnOnDemo, turnOffDemo } from '@/utils/system'
import { createNamespacedHelpers } from 'vuex'
import { SET_IS_DEMO_ACCOUNT } from '@/store/modules/profile/mutation-types'

const { mapGetters, mapMutations } = createNamespacedHelpers('profile')

export default {
  name: 'DemoToggle',

  components: { Checkbox },

  data: () => ({
    isToggleBlocked: false
  }),

  computed: {
    ...mapGetters(['isDemoAccount']),

    classes () {
      return {
        'demo-toggle--disabled': this.isToggleBlocked
      }
    }
  },

  methods: {
    ...mapMutations([SET_IS_DEMO_ACCOUNT]),

    redirectToParent () {
      if (this.$route.meta?.redirectTo?.name) {
        this.$router.push({ name: this.$route.meta?.redirectTo.name })
      }
    },

    clearRoute () {
      this.$router.replace({
        ...this.$route,
        query: {}
      })
    },

    async toggleDemo () {
      const eventName = this.isDemoAccount
        ? 'mmp_demo-acc_off'
        : 'mmp_demo-acc_on'

      this.$metrics.hit(eventName)

      if (this.isDemoAccount) {
        this.isToggleBlocked = true
        this[SET_IS_DEMO_ACCOUNT](false)

        turnOffDemo()

        this.clearRoute()
        this.redirectToParent()

        document.location.reload()
      } else {
        try {
          this.isToggleBlocked = true
          this[SET_IS_DEMO_ACCOUNT](true)

          const { data } = await getDemoToken()
          turnOnDemo(data.data.access_token)

          this.clearRoute()
          this.redirectToParent()

          document.location.reload()
        } catch (e) {
          this.$toast('Ошибка')
          this.isToggleBlocked = false
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.demo-toggle

  &--disabled
    pointer-events none
    opacity 0.3
</style>
